import React from 'react';
import { Form } from 'semantic-ui-react';

const ClusterTemplateCloudForm = (props) => {

  const { cluster, handleChange, computeInstances, regionList } = props;
  const { id, region, workerVmTypeId, workerVmCount, mgmtVmTypeId, mgmtVmCount } = cluster;

  return (
    <Form>
      <Form.Field>
        <label>Region </label>
        <Form.Dropdown
          placeholder='Select Region'
          fluid
          selection
          className='icon'
          name="region"
          value={region}
          options={regionList}
          onChange={handleChange}
          disabled={id}
        />
      </Form.Field>

      <Form.Group>
        <Form.Field width="12">
          <label>Worker Instance Type </label>
          <Form.Dropdown
            placeholder='Select Instance Type'
            fluid
            selection
            className='icon'
            name="workerVmTypeId"
            value={workerVmTypeId}
            options={computeInstances}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field width="4">
          <label>Worker Instance Count </label>
          <Form.Input type="number" name="workerVmCount" value={workerVmCount} onChange={handleChange} placeholder='Instance Count' />
        </Form.Field>
      </Form.Group>


      <Form.Group>
        <Form.Field width="12">
          <label>Mgmt Instance Type </label>
          <Form.Dropdown
            placeholder='Select Instance Type'
            fluid
            selection
            className='icon'
            name="mgmtVmTypeId"
            value={mgmtVmTypeId}
            options={computeInstances}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field width="4">
          <label>Mgmt Instance Count </label>
          <Form.Input type="number" name="mgmtVmCount" value={mgmtVmCount} onChange={handleChange} disabled placeholder='Instance Count' />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default ClusterTemplateCloudForm;