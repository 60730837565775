import React, { useState, useEffect } from 'react';
import { Form, Button, Icon, Message } from 'semantic-ui-react';
import ClusterTemplateCloudForm from './ClusterTemplateCloudForm';
import { fetchCloudAccounts, fetchImageBuilds } from '../../../api/apiCalls';
import ClusterTemplateOmPremFrom from './ClusterTemplateOnPremFrom';
import { CloudProviderTypes } from '../../cloud-accounts/consts';
import { useSelector } from 'react-redux';
import { checkLength, checkIp4Pattern } from '../../../shared/validators';

export const defaultInfo = {
  name: '',
  privateIp: '',
  publicIp: ''
};

export const validateNodeInfo = (node, setFunc) => {
  const { name, publicIp, privateIp } = node;
  let error = {};
  if (!checkLength(name, 3, 64)) {
    error.name = 'Length must be between 3 and 64'
  };
  const puIpValid = checkIp4Pattern(publicIp);
  const prIpValid = checkIp4Pattern(privateIp);
  if (!puIpValid) {
    error.publicIp = 'Not valid ip address'
  }
  if (!prIpValid) {
    error.privateIp = 'Not valid ip address'
  }
  setFunc(error);
  return Object.keys(error).length === 0;
};

const defaultOnPremTemplate = {
  workerVirtualMachines: [],
  controlVirtualMachines: [{ ...defaultInfo }],
  monitorVirtualMachines: [{ ...defaultInfo }],
  sshInfo: {
    username: '',
    ip: ''
  }
};

const defaultTemplate = {
  cloudAccountId: '',
  name: '',
  description: '',
  region: '',
  workerVmTypeId: '',
  workerVmCount: 0,
  mgmtVmTypeId: '',
  mgmtVmCount: 1
};

const ClusterTemplateForm = (props) => {

  const [cloudAccountList, setCloudAccountList] = useState([]);
  const [clusterTemplate, setClusterTemplate] = useState({...defaultTemplate});
  const [regionList, setRegionList] = useState([]);

  const [err, setErr] = useState();
  const { cluster, saveAction, inProgress, error } = props;

  useEffect(() => {
    if (cluster) {
      setClusterTemplate(cluster);
      getImageBuilds(cluster.cloudAccount.id, cluster.cloudAccount.providerId);
    } else {
      getCloudAccounts();
      setClusterTemplate({...defaultTemplate});
    }
    
    return () => {
      setClusterTemplate({...defaultTemplate});
    };
  }, [cluster]);

  useEffect(() => {
    setErr(error)
  }, [error]);

  useEffect(() => {
    setErr()
  }, [clusterTemplate]);


  const validateAllForm = () => {

  }

  const getCloudAccounts = async () => {
    try {
      const response = await fetchCloudAccounts();
      const accList = response.data.result.map(acc => ({ key: acc.id, text: acc.name, value: acc.id, providerId: acc.providerId })) || [];
      setCloudAccountList(accList);
    } catch (err) { }
  };

  const getImageBuilds = async (cloudAccId, provId) => {
    try {
      const response = await fetchImageBuilds(cloudAccId, provId);
      const imgList = response.data.result.regionStatuses.filter(ima => ima.imageBuildStatus === 2).map(imageB => ({
        key: imageB.cloudProviderRegion.id,
        text: imageB.cloudProviderRegion.friendlyName + ' -- ' + imageB.cloudProviderRegion.name,
        value: imageB.cloudProviderRegion.name
      })) || [];
      setRegionList(imgList);
    } catch (err) { }
  };

  const { id, name, description, cloudAccountId, cloudAccount } = clusterTemplate;

  let provId = null;
  if (cloudAccount) {
    provId = cloudAccount.providerId;
  } else if (cloudAccountId !== '') {
    provId = cloudAccountList.find(item => item.key === cloudAccountId).providerId;
  }
  const cloudInfo = useSelector(store => store.CloudReducer.cloudInventories.find(provider => provider.cloudProvider.id === provId));

  const computeInstances = cloudInfo ? cloudInfo.computeInstances : [];
  const computeInstancesSpec = computeInstances.map(ins => ({ key: ins.id, text: `${ins.name} -- ${ins.core} Core, ${ins.ram} GB`, value: ins.id }));

  useEffect(() => {
    if (cloudAccountId && provId !== CloudProviderTypes.ONPREM) {
      getImageBuilds(cloudAccountId, provId);
    }
  }, [cloudAccountId]);

  useEffect(() => {
    if (cluster) {
      setClusterTemplate(cluster);
    }
  }, [computeInstances]);

  const handleChange = (e, { name, value }) => {
    setClusterTemplate(prevCluster => ({
      ...prevCluster,
      [name]: value
    }));
  };

  const handleAccountChange = (e, { name, value }) => {

    let newCluster = { ...clusterTemplate };
    newCluster = {
      ...newCluster,
      [name]: value,
      region: '',
      workerVmTypeId: '',
      workerVmCount: 0,
      mgmtVmTypeId: '',
      mgmtVmCount: 1
    }
    delete newCluster.vmInfraExtraInfo;

    const prId = cloudAccountList.find(item => item.key === value).providerId;
    if (prId === CloudProviderTypes.ONPREM) {
      newCluster.vmInfraExtraInfo = {...defaultOnPremTemplate};
      delete newCluster.workerVmTypeId;
      delete newCluster.mgmtVmTypeId;
    }

    setClusterTemplate(newCluster);
  };

  const handleControlNodeChange = (e, { name, value }) => {
    const newControlNode = [...clusterTemplate.vmInfraExtraInfo.controlVirtualMachines];
    newControlNode[0][name] = value;
    setClusterTemplate(prevCluster => ({
      ...prevCluster,
      vmInfraExtraInfo: {
        ...prevCluster.vmInfraExtraInfo,
        controlVirtualMachines: newControlNode
      }
    }));
  };

  const handleMonitorNodeChange = (e, { name, value }) => {
    const newMonNode = [...clusterTemplate.vmInfraExtraInfo.monitorVirtualMachines];
    newMonNode[0][name] = value;
    setClusterTemplate(prevCluster => ({
      ...prevCluster,
      vmInfraExtraInfo: {
        ...prevCluster.vmInfraExtraInfo,
        monitorVirtualMachines: newMonNode
      }
    }));
  };

  const handleSshChange = (e, { name, value }) => {
    setClusterTemplate(prevCluster => ({
      ...prevCluster,
      vmInfraExtraInfo: {
        ...prevCluster.vmInfraExtraInfo,
        sshInfo: {
          ...prevCluster.vmInfraExtraInfo.sshInfo,
          [name]: value
        }
      }
    }));
  };

  const handleAddWorkerButton = (newWorker) => {
    setClusterTemplate(prevCluster => ({
      ...prevCluster,
      vmInfraExtraInfo: {
        ...prevCluster.vmInfraExtraInfo,
        workerVirtualMachines: [...prevCluster.vmInfraExtraInfo.workerVirtualMachines, newWorker]
      }
    }));
  };

  const handleDeleteButton = (e, node) => {
    e.preventDefault();
    const newList = [...clusterTemplate.vmInfraExtraInfo.workerVirtualMachines].filter(obj => JSON.stringify(obj) !== JSON.stringify(node));
    setClusterTemplate(prevCluster => ({
      ...prevCluster,
      vmInfraExtraInfo: {
        ...prevCluster.vmInfraExtraInfo,
        workerVirtualMachines: newList
      }
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    saveAction(clusterTemplate);
  };

  return (
    <Form>
      {!id && <Form.Field>
        <label>Cloud Account </label>
        <Form.Dropdown
          placeholder='Select Account'
          fluid
          selection
          className='icon'
          name="cloudAccountId"
          value={cloudAccountId}
          options={cloudAccountList}
          onChange={handleAccountChange}
        />
      </Form.Field>}

      <Form.Field>
        <label>Name: </label>
        <Form.Input name="name" value={name} onChange={handleChange} placeholder='Name' />
      </Form.Field>

      <Form.Field>
        <label>Description: </label>
        <Form.Input name="description" value={description} onChange={handleChange} placeholder='Description' />
      </Form.Field>

      {(cloudAccountId !== '' || cloudAccount) &&
        <>
          {provId !== CloudProviderTypes.ONPREM ?
            <ClusterTemplateCloudForm
              regionList={regionList}
              computeInstances={computeInstancesSpec}
              cluster={clusterTemplate}
              handleChange={handleChange}
            />
            :
            <ClusterTemplateOmPremFrom
              cluster={clusterTemplate}
              handleControlNodeChange={handleControlNodeChange}
              handleMonitorNodeChange={handleMonitorNodeChange}
              handleSshChange={handleSshChange}
              handleAddWorkerButton={handleAddWorkerButton}
              handleDeleteButton={handleDeleteButton}
            />
          }
        </>
      }

      {err &&
        <Message negative>
          <Message.Header>{err.message}</Message.Header>
          <p>{err.details}</p>
        </Message>
      }
      {!id && provId && (provId !== CloudProviderTypes.ONPREM && !regionList.length) &&
        <Message negative>
          <Message.Header>Warning!</Message.Header>
          <p>Please build the image for this account first</p>
        </Message>
      }
      <div style={{ marginTop: 22, marginBottom: 22, float: "right" }} >
        <Button color='teal' onClick={handleSubmit} disabled={inProgress} loading={inProgress} >
          <Icon name='checkmark' /> Save
        </Button>
      </div>
    </Form>
  );
};

export default ClusterTemplateForm;