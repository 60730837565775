export const creditCalculation = (timePassed, noOfUsers) => {
  var totalValue = timePassed * noOfUsers;
  var totalExcess = timePassed * noOfUsers % 3600;
  if (totalExcess != 0) {

    totalValue -= totalValue % 3600;
    // %10 excess tolerance is applied for a credit spent(x,1 or less credit is rolled to x, o.w. x+1)
    if (totalExcess > 360) {
      totalValue += 3600;
    }

  }
  return Math.floor((parseInt(totalValue, 10) / 3600));
};

export const saveByteArray = (reportName, byte, fileType) => {
  const blob = new Blob([byte], { type: fileType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const fileName = reportName;
  link.download = fileName;
  link.click();
};

export const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};