import React, { useState, useEffect } from 'react';
import { Table, Button } from 'semantic-ui-react';
import { getErroredRequestSummary } from '../../../api/apiCalls';
import { useApiProgress } from '../../../api/ApiProgressHook';
import LoadingComponent from '../../../components/LoadingComponent';
import NoDataFound from '../../../components/NoDataFound';

const ErroredRequestSummary = ({ testRunId, handleErrorSummaryDetail }) => {

  const [erroredRequestSummaryList, setErroredRequestSummaryList] = useState([]);
  const [pendingApiCall, setPendingApiCall] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    fetchErroredRequestSummary();
  }, [testRunId]);

  const fetchErroredRequestSummary = async () => {
    setPendingApiCall(true);
    try {
      const response = await getErroredRequestSummary(testRunId);
      setErroredRequestSummaryList(response.data.result);
    } catch (error) {
      setError(error.response && error.response.data.result);
    }
    setPendingApiCall(false);
  };

  return (
    <div>
      {(pendingApiCall) ?
        <LoadingComponent msg="Test Errors Loading" />
        :
        <>
          {error || !erroredRequestSummaryList.length ?
            <NoDataFound msg={error ? error.message : 'There is no error here!'} />
            :
            <>
              <Table celled size="small" selectable sortable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Scenario
                    </Table.HeaderCell>
                    <Table.HeaderCell> Request
                    </Table.HeaderCell>
                    <Table.HeaderCell>Response Code</Table.HeaderCell>
                    <Table.HeaderCell>Error Count</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {erroredRequestSummaryList.map((error) => (
                    <Table.Row>
                      <Table.Cell>{error.scenarioName}</Table.Cell>
                      <Table.Cell>{error.requestName}</Table.Cell>
                      <Table.Cell>{error.responseCode}</Table.Cell>
                      <Table.Cell>{error.errorCount}</Table.Cell>
                      <Table.Cell textAlign='center' width={1} >
                        <Button color='blue'
                          onClick={() => handleErrorSummaryDetail({ scenarioName: error.scenarioName, requestName: error.requestName, responseCode: error.responseCode })}>
                          Details
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          }
        </>
      }
    </div >
  );
};

export default ErroredRequestSummary;