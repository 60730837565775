import React, { useState, useEffect } from 'react';
import { Modal, Button, Icon, Header, Form } from 'semantic-ui-react';
import { checkIp4Pattern } from '../../../shared/validators';

const defaultVariable = { key: '', value: '' };
const defaultDomain = { domainName: '', ip: '' };

const VariablesAndDomainsModal = (props) => {

  const [newVariables, setNewVariables] = useState([]);
  const [newDomains, setNewDomains] = useState([]);
  const [newVar, setNewVar] = useState({ ...defaultVariable });
  const [newDns, setNewDns] = useState({ ...defaultDomain });
  const { visible, handleClose, handleSubmit, variables, domains, inProgress } = props;

  useEffect(() => {
    if (visible) {
      setNewDomains(domains);
      setNewVariables(variables);
    }

    return function unmount() {
      setNewDomains([]);
      setNewVariables([]);
    };
  }, [visible]);

  const handleVariableChange = (e, { name, value }) => {
    setNewVar(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDomainChange = (e, { name, value }) => {
    setNewDns(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDeleteButton = (index, data, setFunction) => {
    let newData = [...data.slice(0, index), ...data.slice(index + 1)];
    setFunction(newData);
  };

  const handleVariableCreateButton = () => {
    setNewVariables(prev => [...prev, newVar]);
    setNewVar({ ...defaultVariable });
  };

  const handleDomainCreateButton = () => {
    setNewDomains(prev => [...prev, newDns]);
    setNewDns({ ...defaultDomain });
  };

  const { key, value } = newVar;
  const { domainName, ip } = newDns;

  return (
    <Modal size="small" open={visible} onClose={handleClose} closeIcon>
      <Modal.Header><Icon name="edit" /> Edit Variables and Domains</Modal.Header>
      <Modal.Content>
        <Header as='h4'>
          Variables
          <Header.Subheader>
            Specify JMeter properties to parameterize the test.
          </Header.Subheader>
        </Header>
        <Form>
          {newVariables.map((variable, index) => <Form.Group key={variable} widths='equal'>
            <Form.Input name='key' placeholder='Variable Name' value={variable.key} />
            <Form.Input name='value' placeholder='Variable Value' value={variable.value} />
            <Button negative icon='delete' onClick={() => handleDeleteButton(index, newVariables, setNewVariables)} />
          </Form.Group>
          )}
          <Form.Group widths='equal'>
            <Form.Input name='key' placeholder='Variable Name' value={key} onChange={handleVariableChange} />
            <Form.Input name='value' placeholder='Variable Value' value={value} onChange={handleVariableChange} />
            <Button primary icon='plus' disabled={newVar.key === '' || newVar.value === ''} onClick={handleVariableCreateButton} />
          </Form.Group>
        </Form>
        <Header as='h4'>
          Dns Override
          <Header.Subheader>
            Use this form to specify alternative IP addresses for hostnames used in your test scenario.
          </Header.Subheader>
        </Header>
        <Form>
          {newDomains.map((domain, index) => <Form.Group key={domain} widths='equal'>
            <Form.Input name='domainName' placeholder='Domain Name' value={domain.domainName} />
            <Form.Input name='ip' placeholder='Variable Value' value={domain.ip} />
            <Button negative icon='delete' onClick={() => handleDeleteButton(index, newDomains, setNewDomains)} />
          </Form.Group>
          )}
          <Form.Group widths='equal'>
            <Form.Input name='domainName' placeholder='Domain Name' value={domainName} onChange={handleDomainChange} />
            <Form.Input name='ip' placeholder='127.0.0.1' value={ip} onChange={handleDomainChange} />
            <Button primary icon='plus' disabled={newDns.domainName === '' || !checkIp4Pattern(newDns.ip)} onClick={handleDomainCreateButton} />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={handleClose}>Back</Button>
        <Button
          positive
          icon='save'
          labelPosition='right'
          content='Save'
          onClick={() => handleSubmit(newVariables, newDomains)}
          loading={inProgress}
          disabled={inProgress}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default VariablesAndDomainsModal;