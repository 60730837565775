import React, { useState } from 'react';
import { Modal, Header } from 'semantic-ui-react';
import ClusterTemplateForm from './ClusterTemplateForm';
import { createCluster } from '../../../api/apiCalls';
import { useApiProgress } from '../../../api/ApiProgressHook';
import { useDispatch } from 'react-redux';
import { fetchClusterListAgain } from '../../../redux/actions/CloudActions';

const ClusterCreateModal = (props) => {

  const [err, setErr] = useState();
  const { visible, handleClose } = props;

  const pendingApiCall = useApiProgress('post', '/api/VmInfraDetails');
  const dispatch = useDispatch();

  const handleSaveAction = async (body) => {
    setErr();
    try {
      await createCluster(body);
      handleClose();
      dispatch(fetchClusterListAgain(true));
    } catch (error) {
      setErr(error.response.data.error);
    }
  };

  return (
    <Modal
      open={visible}
      onClose={handleClose}
      size='large'
      closeIcon
    >
      <Header icon='plus' content='Add Cluster' />
      <Modal.Content>
        <ClusterTemplateForm saveAction={handleSaveAction} inProgress={pendingApiCall} error={err} />
      </Modal.Content>
    </Modal>
  );
};

export default ClusterCreateModal;