import React, { useEffect, useState } from 'react';
import { Grid, Icon, Header, Table, Button } from 'semantic-ui-react';
import { getTestSuite } from '../../api/apiCalls';
import moment from 'moment';

const TestSuiteDetailPage = (props) => {

  const suiteId = props.match.params.id;

  const [suite, setSuite] = useState({});

  useEffect(() => {
    fetchTestSuite();
  }, []);

  const fetchTestSuite = async () => {
    if (suiteId) {
      try {
        const response = await getTestSuite(suiteId);
        setSuite(response.data.result);
      } catch (err) { }
    }
  };

  const { name, description, noOfTotalUsers, testDurationInSeconds, lastModificationTime, scenarios } = suite;

  return (
    <Grid>
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name='list' />
              </div>
              <Header.Content>
                Suite: {name}
                <Header.Subheader>Your awesome suite !</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div className="page-actions">
            <Button onClick={() => props.history.push(`/edit-suite/${suiteId}`)}>
              Edit Suite
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className='page-content'>
        <Grid.Column>
          <div className='segment-shadow'>
            <Table basic='very' celled >
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={4}>
                    <Header as='h4' image>
                      <Header.Content>
                        Name
                        <Header.Subheader>{name}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell width={4}>
                    <Header as='h4' image>
                      <Header.Content>
                        Modified
                        <Header.Subheader>{moment(lastModificationTime).format('DD.MM.YYYY')}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>
                    <Header as='h4' image>
                      <Header.Content>
                        # of Users
                        <Header.Subheader>{noOfTotalUsers}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell width={4}>
                    <Header as='h4' image>
                      <Header.Content>
                        Duration (sec)
                        <Header.Subheader>{testDurationInSeconds}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='3'>
                    <Header as='h4' image>
                      <Header.Content>
                        Description
                        <Header.Subheader>{description}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                </Table.Row>

              </Table.Body>
            </Table>


            <Header block>
              <div className='space-center'>
                Scenarios
                  <Button primary >
                  Add Scenario
                </Button>
              </div>
            </Header>
            <Table >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Scenario Name</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {scenarios && scenarios.map(scenario => (
                  <Table.Row>
                    <Table.Cell>
                      {scenario.name}<br />
                      <small>
                        {scenario.description}
                      </small>
                    </Table.Cell>
                    <Table.Cell textAlign={"right"}>
                      <Button>
                        Edit
                      </Button>
                      <Button>
                        Delete
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default TestSuiteDetailPage;