import React, { useState, useEffect } from 'react';
import { Segment, Button, Header, Icon, Form, Input, Radio, Divider, Grid, Message, Dimmer, Loader } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { getUser, updateUser, createUser, getTenants } from '../../api/apiCalls';
import { useApiProgress } from '../../api/ApiProgressHook';
import PasswordChange from './components/PasswordChange';
import { useSelector } from 'react-redux';

const defaultUser = {
  emailAddress: '',
  password: '',
  name: '',
  surname: '',
  username: '',
  isActive: true
};

const UserCrudPage = (props) => {

  const userId = props.match.params.id;

  const [user, setUser] = useState(defaultUser);
  const [tenantList, setTenantList] = useState([]);
  const [error, setError] = useState();
  const [inProgress, setInProgress] = useState(false);

  const pendingApiCall = useApiProgress('get', '/api/Users/' + userId);

  const { userRole } = useSelector(store => store.LoginReducer);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (userRole && userRole === "SystemAdmin") {
      fetchTenantList();
    }
  }, [userRole]);

  useEffect(() => {
    setError();
  }, [user]);

  const fetchUser = async () => {
    if (userId) {
      try {
        const response = await getUser(userId);
        setUser(response.data.result);
      } catch (err) { }
    }
  };

  const fetchTenantList = async () => {
    try {
      const response = await getTenants();
      setTenantList(response.data.result.items.map(item => ({ key: item.id, text: item.tenancyName, value: item.id })));
    } catch (err) { }
  };

  const handleChange = (e, { name, value }) => {
    setUser(prev => ({
      ...prev,
      [name]: value
    }));
  }

  const { emailAddress, isActive, name, surname, userName, password, tenantId } = user;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError();
    setInProgress(true);
    const body = { emailAddress, isActive, name, surname, userName };
    if (userRole && userRole === 'SystemAdmin') {
      body['tenantId'] = tenantId;
    }
    try {
      let response;
      if (userId) {
        response = await updateUser(userId, body);
      } else {
        body['password'] = password;
        response = await createUser(body);
      }
      props.history.push(`/users`);
    } catch (err) {
      setError(err.response && err.response.data.error);
    }
    setInProgress(false);
  };

  const title = `${userId ? 'Edit ' : ' Create new'} User`;

  return (
    <Grid >
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name={userId ? 'edit' : 'plus'} />
              </div>
              <Header.Content>
                {title}
                <Header.Subheader>{title} or change password</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div className="page-actions">
            <Button as={Link} to="/users">Back </Button>
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>

          <Segment className="shadow">
            {pendingApiCall &&
              <Dimmer active inverted>
                <Loader size='large'>Loading</Loader>
              </Dimmer>
            }
            <Form onSubmit={handleSubmit}>
              <Form.Field required>
                <label>User Name</label>
                <Input name="userName" onChange={handleChange} placeholder='User Name' defaultValue={userName} />
              </Form.Field>

              <Form.Field required>
                <label>Name</label>
                <Input name="name" onChange={handleChange} placeholder='Name' defaultValue={name} />
              </Form.Field>

              <Form.Field required>
                <label>Surname</label>
                <Input name="surname" onChange={handleChange} placeholder='Surname' defaultValue={surname} />
              </Form.Field>

              <Form.Field required>
                <label>Email</label>
                <Input type="email" name="emailAddress" onChange={handleChange} placeholder='Email' defaultValue={emailAddress} />
              </Form.Field>

              {((userRole && userRole === "SystemAdmin") && tenantId !== 0) &&
                <Form.Field required>
                  <label>Select Tenant</label>
                  <Form.Dropdown
                    placeholder='Select Tenant'
                    fluid
                    selection
                    className='icon'
                    name="tenantId"
                    value={tenantId}
                    options={tenantList}
                    onChange={handleChange}
                  />
                </Form.Field>
              }

              {!userId &&
                <Form.Field required>
                  <label>Password</label>
                  <Input type="password" name="password" onChange={handleChange} placeholder='Password' defaultValue={password} />
                </Form.Field>
              }

              <Form.Field>
                <label>User Status</label>
                <Radio checked={isActive} toggle
                  label={`User is ${isActive ? 'active' : 'inactive'}`}
                  onChange={() => setUser(prev => ({ ...prev, isActive: !prev.isActive }))}
                />
              </Form.Field>

              <Divider />
              {error &&
                <Message negative>
                  <Message.Header>{error.message}</Message.Header>
                  <p>{error.details}</p>
                </Message>
              }
              <Grid.Row>
                <Grid.Column>
                  <Button disabled={inProgress} loading={inProgress} fluid size="large" type="submit" primary >
                    {userId ? 'Update User' : 'Create User'}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>

      {userId &&
        <PasswordChange userId={userId} />
      }
    </Grid>
  )
};

export default UserCrudPage;