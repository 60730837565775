import React, { useEffect, useState, useRef } from 'react';
import { Grid, Header, Icon, Button, Table, Dropdown, Popup, Input } from 'semantic-ui-react';
import { getTestSuites, deleteTestSuite } from '../../api/apiCalls';
import { useApiProgress } from '../../api/ApiProgressHook';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { openConfirmModal, closeConfirmModal } from '../../redux/actions/ModalActions';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import NoDataFound from '../../components/NoDataFound';
import LoadingComponent from '../../components/LoadingComponent';

const TestSuiteListPage = (props) => {

  const [testSuiteList, setTestSuiteList] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [error, setError] = useState();
  const [selectedSuiteId, setSelectedSuiteId] = useState();
  const [sort, setSort] = useState('desc');
  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const searchRef = useRef();

  const dispatch = useDispatch();
  const pendingApiCall = useApiProgress('get', '/api/TestSuites');

  useEffect(() => {
    fetchTestSuites();
  }, []);

  useEffect(() => {
    let sortedResult = _.orderBy(testSuiteList, ['lastModificationTime'], [sort]);
    let sortedSearchedList = _.orderBy(searchedList, ['lastModificationTime'], [sort]);
    setTestSuiteList(sortedResult);
    setSearchedList(sortedSearchedList);
  }, [sort]);

  const fetchTestSuites = async () => {
    try {
      const response = await getTestSuites();
      let sortedResult = _.orderBy(response.data.result, ['lastModificationTime'], [sort]);
      setTestSuiteList(sortedResult);
    } catch (err) {
      setError(err.response.data.error);
    }
  };

  const handleDeleteButton = () => {
    dispatch(openConfirmModal(deleteSuite, "Delete Test Suite", `Are you sure you want to delete test suite?`));
  };

  const deleteSuite = async () => {
    try {
      await deleteTestSuite(selectedSuiteId);
      fetchTestSuites();
    } catch (err) {
      toast.error(err.response.data.error.message);
    }
    dispatch(closeConfirmModal());
  };

  useEffect(() => {
    setTimeout(() => {
      if (!search || (search && search.length < 1)) {
        setSearchedList([]);
        setIsLoading(false);
      } else {
        const re = new RegExp(_.escapeRegExp(search), 'i');
        const isMatch = (result) => re.test(result.name);

        setIsLoading(false);
        setSearchedList(_.filter(testSuiteList, isMatch));
      }
    }, 300);
  }, [search]);

  const handleSearchChange = (e) => {
    setIsLoading(true);
    setSearch(e.target.value);
  };

  const suiteList = searchedList.length ? searchedList : testSuiteList;

  return (
    <Grid >
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name='file alternate' />
              </div>
              <Header.Content>
                Test Suites
                <Header.Subheader>Manage test suites</Header.Subheader>
              </Header.Content>
            </Header>
          </div>

          <div className="page-actions">

            <Button onClick={() => props.history.push('/create-suite')} primary icon>
              <Icon name='plus' />   New Test Suite
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="visium-page-content">
        <Grid.Column>
          <div className="shadow" >
            {(pendingApiCall) ?
              <LoadingComponent msg="Test Suites Loading" />
              :
              <>
                {!testSuiteList.length ?
                  <NoDataFound msg='There is no test suite here!' />
                  :
                  <>
                    <Table celled size="small" selectable sortable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name
                    <Popup on="click" onUnmount={() => setSearch()} onMount={() => searchRef.current.focus()} trigger={<span style={{ float: "right" }}><Icon name='search' /></span>}>
                              <Input loading={isLoading} ref={searchRef} focus icon='search' placeholder='Search...' onChange={_.debounce(handleSearchChange, 500, { leading: true })} />
                            </Popup>
                          </Table.HeaderCell>
                          <Table.HeaderCell onClick={() => setSort(prev => prev === 'desc' ? 'asc' : 'desc')}>Modified
                    <span style={{ float: "right" }}><Icon name='sort' /></span>
                          </Table.HeaderCell>
                          <Table.HeaderCell># of Users</Table.HeaderCell>
                          <Table.HeaderCell>Duration (sec)</Table.HeaderCell>
                          <Table.HeaderCell>Description</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {suiteList.map((suite) => (
                          <Table.Row>
                            <Table.Cell>
                              <Highlighter
                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                searchWords={[search]}
                                autoEscape
                                textToHighlight={suite.name}
                              />
                            </Table.Cell>
                            <Table.Cell>{moment(suite.lastModificationTime).fromNow()}</Table.Cell>
                            <Table.Cell>{suite.noOfTotalUsers}</Table.Cell>
                            <Table.Cell>{suite.testDurationInSeconds}</Table.Cell>
                            <Table.Cell>{suite.description}</Table.Cell>
                            <Table.Cell textAlign='center' width={1} >
                              <Button.Group size='tiny'>
                                <Dropdown
                                  className='icon'
                                  labeled
                                  button
                                  text='Actions'
                                  icon='ellipsis horizontal'
                                  iconPosition='right'
                                  onClick={() => setSelectedSuiteId(suite.id)}
                                >
                                  <Dropdown.Menu direction='left'>
                                 
                                    <Dropdown.Item  onClick={() => props.history.push(`/test-suite/${suite.id}`)}><Icon name="magnify" />Go To Details</Dropdown.Item>
                                    <Dropdown.Item onClick={() => props.history.push(`/edit-suite/${suite.id}`)}><Icon name="edit" /> Edit Suite</Dropdown.Item>
                                    <Dropdown.Item onClick={handleDeleteButton}>
                                      <Icon name="trash alternate outline" /> Delete Suite
                                 </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Button.Group>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </>
                }
              </>
            }
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default TestSuiteListPage;