import React from 'react';
import Backdrop from './Backdrop';
import Sidebar from './Sidebar';

const Drawer = (props) => {

  const { visible, onClose } = props;

  return (
    <div>
      <Sidebar {...props} />
      {visible && <Backdrop close={onClose} />}
    </div>
  );
};

export default Drawer;