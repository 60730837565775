import React, { useState } from 'react';
import { Modal, Button, Form, Input, TextArea, Checkbox, Message, Loader, Header, Dimmer } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { startTest } from '../../../api/apiCalls';
import { useApiProgress } from '../../../api/ApiProgressHook';
import { useHistory } from 'react-router-dom';

const SummaryAndTestRunModal = (props) => {

  const { visible, handleClose } = props;
  const [testRunInfo, setTestRunInfo] = useState({ name: '', description: '', saveErrors: true });
  const [error, setError] = useState();

  const { testRunVariation } = useSelector(store => store.TestRunReducer);

  const pendingApiCall = useApiProgress('post', '/api/TestRuns');

  const history = useHistory();

  const handleChange = (e, { name, value }) => {
    setTestRunInfo(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCheckBoxChange = () => {
    setTestRunInfo(prev => ({
      ...prev,
      saveErrors: !prev.saveErrors
    }));
  };

  const { name, saveErrors, description } = testRunInfo;

  const handleStartButton = async () => {
    setError();
    const variation = { ...testRunVariation, name, description, saveErrors };
    try {
      const response = await startTest(variation);
      handleClose();
      history.push(`/test-run/${response.data.result.id}/monitoring`);
    } catch (err) {
      setError(err.response && err.response.data.error);
    }
  };

  if (pendingApiCall) {
    return (
      <Dimmer active page>
        <Header as='h4' icon inverted>
          <Loader size='huge'>Starting your test takes around 2-4 minutes. Your report will appear once we've gathered the data</Loader>
        </Header>
      </Dimmer>
    );
  }

  return (
    <Modal open={visible} onClose={handleClose} >
      <Modal.Content scrolling>
        <Form>
          <Form.Field>
            <label>Test Run Name</label>
            <Input name="name" onChange={handleChange} placeholder={`Test Run Name`} />
          </Form.Field>

          <Form.Field>
            <label>Test Note</label>
            <TextArea name='description' rows={2} onChange={handleChange} placeholder='Test Note' />
          </Form.Field>

          <Checkbox onChange={handleCheckBoxChange} checked={saveErrors} label="Save Errors" />
        </Form>
        {error &&
          <Message negative>
            <Message.Header>{error.message}</Message.Header>
            <p>{error.details}</p>
          </Message>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button primary disabled={name.length < 3} onClick={handleStartButton}>
          Start Test
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SummaryAndTestRunModal;