import React, { useEffect, useState, useRef } from 'react';
import { Grid, Header, Icon, Button, Table, Dropdown, Popup, Input, Label } from 'semantic-ui-react';
import { getUserList, deleteUser, getCurrentLoginInfo, getTenants } from '../../api/apiCalls';
import { useApiProgress } from '../../api/ApiProgressHook';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { openConfirmModal, closeConfirmModal } from '../../redux/actions/ModalActions';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';

const UserListPage = (props) => {

  const [userList, setUserList] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [error, setError] = useState();
  const [selectedUserId, setSelectedUserId] = useState();
  const [sort, setSort] = useState('desc');
  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const searchRef = useRef();

  const dispatch = useDispatch();
  const pendingApiCall = useApiProgress('get', '/api/Users');

  const { userRole } = useSelector(store => store.LoginReducer);

  useEffect(() => {
    fetchUserList();
  }, []);

  useEffect(() => {
    if (userRole && userRole === "SystemAdmin") {
      fetchTenantList();
    }
  }, [userRole]);

  useEffect(() => {
    let sortedResult = _.orderBy(userList, ['lastLoginTime'], [sort]);
    let sortedSearchedList = _.orderBy(searchedList, ['lastLoginTime'], [sort]);
    setUserList(sortedResult);
    setSearchedList(sortedSearchedList);
  }, [sort]);

  const fetchUserList = async () => {
    try {
      const response = await getUserList();
      let sortedResult = _.orderBy(response.data.result.items, ['lastLoginTime'], [sort]);
      setUserList(sortedResult);
    } catch (err) {
      setError(err.response && err.response.data.error);
    }
  };

  const fetchTenantList = async () => {
    try {
      const response = await getTenants();
      setTenantList(response.data.result.items);
    } catch (err) { }
  };

  const handleDeleteButton = () => {
    dispatch(openConfirmModal(deleteUserFunc, "Delete User", `Are you sure you want to delete user?`));
  };

  const deleteUserFunc = async () => {
    try {
      await deleteUser(selectedUserId);
      fetchUserList();
    } catch (err) {
      toast.error(err.response && err.response.data.error.message);
    }
    dispatch(closeConfirmModal());
  };

  useEffect(() => {
    setTimeout(() => {
      if (!search || (search && search.length < 1)) {
        setSearchedList([]);
        setIsLoading(false);
      } else {
        const re = new RegExp(_.escapeRegExp(search), 'i');
        const isMatch = (result) => re.test(result.name);

        setIsLoading(false);
        setSearchedList(_.filter(userList, isMatch));
      }
    }, 300);
  }, [search]);

  const handleSearchChange = (e) => {
    setIsLoading(true);
    setSearch(e.target.value);
  };

  const tenantName = (tenantId) => {
    const tenant = tenantList.find(item => item.id === tenantId);
    return tenant ? tenant.name : 'N/A';
  };

  const users = searchedList.length ? searchedList : userList;

  return (
    <Grid >
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name='users' />
              </div>
              <Header.Content>
                Users
                <Header.Subheader>Manage users</Header.Subheader>
              </Header.Content>
            </Header>
          </div>

          <div className="page-actions">
            <Button onClick={() => props.history.push('/create-user')} primary icon>
            <Icon name='plus' />  Add New User
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="visium-page-content">
        <Grid.Column>
        <div className="shadow" >
            <Table celled size="small" selectable sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>User Name
                    <Popup on="click" onUnmount={() => setSearch()} onMount={() => searchRef.current.focus()} trigger={<span style={{ float: "right" }}><Icon name='search' /></span>}>
                      <Input loading={isLoading} ref={searchRef} focus icon='search' placeholder='Search...' onChange={_.debounce(handleSearchChange, 500, { leading: true })} />
                    </Popup>
                  </Table.HeaderCell>
                  <Table.HeaderCell onClick={() => setSort(prev => prev === 'desc' ? 'asc' : 'desc')}>Last Login Time
                    <span style={{ float: "right" }}><Icon name='sort' /></span>
                  </Table.HeaderCell>
                  <Table.HeaderCell>Full Name</Table.HeaderCell>
                  <Table.HeaderCell>Email adress</Table.HeaderCell>
                  <Table.HeaderCell>User Status</Table.HeaderCell>
                  {(userRole && userRole === 'SystemAdmin') && <Table.HeaderCell>Tenant</Table.HeaderCell>}
                  <Table.HeaderCell>

                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {users.map((user) => (
                  <Table.Row>
                    <Table.Cell>
                      <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[search]}
                        autoEscape
                        textToHighlight={user.userName}
                      />
                    </Table.Cell>
                    <Table.Cell>{moment(user.lastLoginTime).fromNow()}</Table.Cell>
                    <Table.Cell>{user.name} {user.surname}</Table.Cell>
                    <Table.Cell>{user.emailAddress}</Table.Cell>
                    <Table.Cell textAlign="center">{user.isActive ? <Label color='green' content='Active' /> : <Label color='red' content='Inactive' />}</Table.Cell>
                    {(userRole && userRole === 'SystemAdmin') && <Table.Cell>{tenantName(user.tenantId)}</Table.Cell>}
                    <Table.Cell textAlign='center' width={1} >
                      <Button.Group size='tiny'>
                        <Dropdown
                                    className='icon'
                                    labeled
                                    button
                                    text='Actions'
                                    icon='ellipsis horizontal'
                                    iconPosition='right'
                          onClick={() => setSelectedUserId(user.id)}
                        >
                          <Dropdown.Menu direction='left'>
                            <Dropdown.Item onClick={() => props.history.push(`/edit-user/${user.id}`)}><Icon name="edit" /> Edit User</Dropdown.Item>
                            <Dropdown.Item onClick={handleDeleteButton}>
                              <Icon name="trash alternate outline" /> Delete User
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default UserListPage;