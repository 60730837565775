import { getCurrentLoginInfo } from '../../api/apiCalls';
import * as actions from '../consts';

export const setUserRole = (role) => ({
  type: actions.SET_USER_ROLE,
  role
});

export const fetchCurrentLoginInfo = (role) => async (dispatch) => {
  try {
    const response = await getCurrentLoginInfo();
    if (response.data.result.tenant.canAccessCreditPage) {
      const stored = localStorage.getItem('canAccessCreditPage');
      if (!stored || stored !== response.data.result.tenant.canAccessCreditPage) {
        localStorage.setItem('canAccessCreditPage', response.data.result.tenant.canAccessCreditPage);
      }
    } else {
      localStorage.setItem('canAccessCreditPage', false);
    }
    dispatch({
      type: actions.SET_CURRENT_LOGIN_INFO,
      info: response.data.result
    });
  } catch (err) { }
};

export const resetLoginInfos = () => ({
  type: actions.RESET_LOGIN_REDUCER
});