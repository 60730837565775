import React from 'react';
import moment from 'moment';
import ChartJsGraph from '../../../components/ChartJsGraph';

const ThroughputLineChart = (props) => {

  const overallLineChart = {
    type: 'line',
    data: {
      labels: props.labelsDate,
      datasets: [
        {
          label: 'Throughput',
          yAxisID: 'request-by-interval-axis',
          fillColor: 'rgba(220,220,220,0.35)',
          strokeColor: 'rgba(220,220,220,1)',
          pointColor: 'rgba(220,220,220,1)',
          pointStrokeColor: '#fff',
          pointHighlightFill: '#fff',
          pointHighlightStroke: 'rgba(220,220,220,1)',
          data: props.requestsByInterval,
          backgroundColor: ['rgba(105, 112, 213, 0.1)'],
        },
        {
          label: 'Num Users',
          yAxisID: 'virtual-users-by-interval-axis',
          illColor: 'rgba(151,187,205,0.35)',
          strokeColor: 'rgba(151,187,205,1)',
          pointColor: 'rgba(151,187,205,1)',
          pointStrokeColor: '#fff',
          pointHighlightFill: '#fff',
          pointHighlightStroke: 'rgba(151,187,205,1)',
          data: props.virtualUsersByInterval,
          backgroundColor: ['rgba(105, 112, 213, 0.05)'],
        },
      ],
    },
    options: {
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                millisecond: 'HH:mm:ss',
                second: 'HH:mm:ss',
                minute: 'HH:mm:ss',
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
            },
            id: 'request-by-interval-axis',
            type: 'linear',
            position: 'left',
            offset: true,
            scaleLabel: {
              display: true,
              labelString: 'Throughput',
            },
          },
          {
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
            },
            id: 'virtual-users-by-interval-axis',
            type: 'linear',
            position: 'right',
            offset: true,
            scaleLabel: {
              display: true,
              labelString: 'Virtual Users',
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return moment(tooltipItem[0].xLabel).format('HH:mm:ss.SSS');
          },
          label: function (tooltipItem, data) {
            return tooltipItem.yLabel;
          },
        },
      },
    },
  };

  return (
    <ChartJsGraph details={overallLineChart} />
  );
};

export default ThroughputLineChart;