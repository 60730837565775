import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, Modal, Popup, Grid, Checkbox, Input, Label, Header, Icon, Form, Radio } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import UploadComponent from '../../../components/UploadComponent';
import { createTestResource, downloadTestResource, deleteTestResource } from '../../../api/apiCalls';
import { useApiProgress } from '../../../api/ApiProgressHook';
import { toast } from 'react-toastify';
import Drawer from '../../../components/drawer/Drawer';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTestResources, addResourceSuccess, deleteResourceSuccess } from '../../../redux/actions/ResourceActions';
import { openConfirmModal, closeConfirmModal } from '../../../redux/actions/ModalActions';
import { saveByteArray } from '../../../shared/Utils';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/shell/shell';
import 'codemirror/mode/yaml/yaml';
import 'codemirror/mode/javascript/javascript';
import '../../cluster-manage/components/VmInfraRunLog.css';
import { PlaceholderForLoad } from '../../../components/PlaceholderForLoad';

const fileTypeName = ['Undefined', 'JMX', 'CSV', 'TXT', 'Other'];
const fileColor = ['red', 'green', 'brown', 'gray', 'yellow'];

const TestResourcesDrawer = (props) => {
	// Pagination => 2 dimensional array 1st for page 2nd for data sort array before split, sort by new modified
	// Add New file, refresh page
	// name search, date sort, type sort
	// Table columns: checkbox, filename, modification date, type, actions[delete, show] 
	// Sumbit => links 
	// resource type 1 jmx 2 csv
	//csvResourceIds: [8]
	//UpdateTestScenarioRequest
	//id: 66
	//jmxResourceId: 1
	//name: "Scenario_1588977416290"
	//noOfLoops: 0
	//noOfMaxUsers: 200
	//rampUpTimeInSec: 0
	//variables: []
	//weight: 100

	const { visible, handleClose, csvResourceIds, jmxResourceId, handleSubmit } = props;
	let resourceIds = [];
	if (csvResourceIds) {
		resourceIds = [...csvResourceIds];
	}
	resourceIds = [...resourceIds, jmxResourceId];

	const dispatch = useDispatch();


	const [selectedTestResources, setSelectedTestResources] = useState([]);

	const uploadInProgress = useApiProgress('post', '/api/TestResources');
	const [uploadProgress, setUploadProgress] = useState(0);
	const [selectedTestResourceContent, setSelectedTestResourceContent] = useState();
	const [contentLoading, setContentLoading] = useState(false);
	const [contentDowloading, setContentDowloading] = useState(false);

	const [searchedList, setSearchedList] = useState([]);
	const [sort, setSort] = useState('desc');
	const [typeFilter, setTypeFilter] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [search, setSearch] = useState(false);

	const searchRef = useRef();
	const testResourceList = useSelector(store => _.orderBy(store.ResourceReducer.resourceList, ['lastModificationTime'], [sort]));

	const handleDeleteButton = (resourceId) => {
		const deleteResource = async () => {
			try {
				await deleteTestResource(resourceId);
				dispatch(deleteResourceSuccess(resourceId));
				dispatch(closeConfirmModal());
			} catch (err) {
				dispatch(closeConfirmModal());
				return toast.error(err.response && err.response.data.error.message);
			}
		};

		dispatch(openConfirmModal(deleteResource, 'Delete resource', 'Are you sure you want to delete resource?'));
	};

	const showResource = async (resourceId) => {
		setContentLoading(true);
		const response = await downloadTestResource(resourceId);
		setSelectedTestResourceContent(response.data);
		setContentLoading(false);
	};

	const uploadFile = (files) => {
		const config = {
			headers: { 'content-type': 'multipart/form-data' },
			onUploadProgress: (progressEvent) => {
				setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			}
		};
		files.map(file => {
			let data = new FormData();
			const fileType = file.name.split('.').pop().toLowerCase();

			data.append('formFile', file);
			data.append('nameOverride', file.name);
			data.append('needsSplit', false);
			data.append('csvHasHeader', false);
			data.append('resourceType', fileType === 'jmx' ? 1 : 2);
			data.append('description', '');

			createTestResource(data, config)
				.then(res => {
					setUploadProgress(0);
					toast.success('File uploaded');
					dispatch(addResourceSuccess(res.data.result));
				});
		});
	};

	useEffect(() => {
		if (visible) {
			dispatch(fetchTestResources());
		} else {
			setSelectedTestResources([]);
		};
	}, [visible]);

	const handleCheckbox = (e, testResource) => {
		if (!resourceIds.includes(testResource.id)) {
			if (e.target.checked || selectedTestResources.map(item => item.id).includes(testResource.id)) {
				setSelectedTestResources(prev => prev.filter(item => item !== testResource));
			} else {
				setSelectedTestResources(prev => [...prev, testResource]);
			}
		} else {
			return toast.info('This resource already selected for scenario!');
		}
	};

	const handleDownloadResource = async (resource) => {
		setContentDowloading(true);
		try {
			const response = await downloadTestResource(resource?.id);
			let dataType = "text/plain";
			if (resource.resourceType === 1) {
				dataType = "text/xml";
			}
			saveByteArray(resource?.resourceFileName, response.data, dataType);
		} catch (err) {
			toast.error(err.response ? err.response.data.error.message : 'Download Failed');
		}
		setContentDowloading(false);
	};

	useEffect(() => {
		setTimeout(() => {
			if (!search || (search && search.length < 1)) {
				setSearchedList([]);
				setIsLoading(false);
			} else {
				const re = new RegExp(_.escapeRegExp(search), 'i');
				const isMatch = (result) => re.test(result.resourceFileName);

				setIsLoading(false);
				setSearchedList(_.filter(testResourceList, isMatch));
			}
		}, 300);
	}, [search]);

	useEffect(() => {
		let sortedSearchedList = _.orderBy(searchedList, ['lastModificationTime'], [sort]);
		setSearchedList(sortedSearchedList);
	}, [sort]);

	const handleSearchChange = (e) => {
		setIsLoading(true);
		setSearch(e.target.value);
	};
	
	const handleTypeFilterChange = (e, { value }) => setTypeFilter(parseInt(value, 10));

	let list = searchedList.length ? searchedList : testResourceList;
	if(typeFilter !== 0){
		list = list.filter(item => item.resourceType === typeFilter);
	}

	return (
		<Drawer style={{ padding: 16, width: 800 }} onClose={handleClose} visible={visible}>
			<Grid.Row columns={1} style={{ margin: '-16px -16px 16px -16px' }} className='page-header'>
				<Grid.Column>
					<div >
						<Header as='h3' className="page-title">
							<div className="page-icon">
								<Icon name='folder' />
							</div>
							<Header.Content>
								Resources
                <Header.Subheader>Manage resources</Header.Subheader>
							</Header.Content>
						</Header>
					</div>

					<div className="page-actions">
						{(contentDowloading) &&
							<div style={{ margin: '5px 5px 0 0' }}>
								<Icon loading size='large' name='circle notch' />
								<small>Downloading</small>
							</div>
						}
						<UploadComponent uploadFunction={uploadFile} accept={['.jmx', '.csv', '.txt']} loading={uploadInProgress} uploadProgress={uploadProgress} />
					</div>
				</Grid.Column>
			</Grid.Row>

			<div className="drawer-body">
				<Table celled size="small" selectable sortable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell></Table.HeaderCell>
							<Table.HeaderCell>
								File Name
								<Popup on="click" onMount={() => searchRef.current.focus()} trigger={<span style={{ float: "right" }}><Icon name='search' /></span>}>
									<Input loading={isLoading} ref={searchRef} focus icon='search' placeholder='Search...' onChange={_.debounce(handleSearchChange, 500, { leading: true })} />
								</Popup>
							</Table.HeaderCell>
							<Table.HeaderCell onClick={() => setSort(prev => prev === 'desc' ? 'asc' : 'desc')}>
								Modified
            		<span style={{ float: "right" }}><Icon name='sort' /></span>
							</Table.HeaderCell>
							<Table.HeaderCell>
								Type
								<Popup
									trigger={<span style={{ float: "right" }}><Icon name='filter' /></span>}
									on="click"
									position='bottom right'
								>
									<Form>
										<Form.Field>
											<Radio
												label='JMX'
												name='radioGroup'
												value={1}
												checked={typeFilter === 1}
												onChange={handleTypeFilterChange}
											/>
										</Form.Field>
										<Form.Field>
											<Radio
												label='CSV'
												name='radioGroup'
												value={2}
												checked={typeFilter === 2}
												onChange={handleTypeFilterChange}
											/>
										</Form.Field>
										<Form.Field>
											<Radio
												label='ALL'
												name='radioGroup'
												value={0}
												checked={typeFilter === 0}
												onChange={handleTypeFilterChange}
											/>
										</Form.Field>
									</Form>
								</Popup>
							</Table.HeaderCell>
							<Table.HeaderCell >
								Actions
              </Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{list.map((testResource) => (
							< Table.Row textAlign='center' >
								<Table.Cell>
									<Checkbox
										checked={selectedTestResources.map(item => item.id).includes(testResource.id)}
										onClick={(e) => handleCheckbox(e, testResource)}
										disabled={resourceIds.includes(testResource.id)}
									/>
								</Table.Cell>
								<Table.Cell textAlign="left">
									{testResource.resourceFileName}
								</Table.Cell>
								<Table.Cell>
									{testResource.lastModificationTime !== '0001-01-01T00:00:00' ? moment(testResource.lastModificationTime).fromNow() : 'N/A'}
								</Table.Cell>
								<Table.Cell>
									<Label color={fileColor[testResource.resourceType]}>
										{fileTypeName[testResource.resourceType]}
									</Label>
								</Table.Cell>
								<Table.Cell>
									<Button.Group size='mini'>
										<Modal onUnmount={() => setSelectedTestResourceContent()} closeIcon size="fullscreen" style={{ marginLeft: '2vw', marginRight: '2vw' }}
											trigger={<Button color='blue' icon='file' onClick={() => showResource(testResource.id)} ></Button>}>
											<Modal.Header content={testResource.resourceFileName} />
											<Modal.Content>
												{(contentLoading) ?
													<PlaceholderForLoad />
													:
													<CodeMirror
														value={selectedTestResourceContent}
														options={{
															lineNumbers: true,
															readOnly: true
														}}
													/>
												}
											</Modal.Content>
										</Modal>
										<Button disabled={contentDowloading} icon='download' onClick={() => handleDownloadResource(testResource)} />
										<Button negative icon='delete' onClick={() => handleDeleteButton(testResource.id)} />
									</Button.Group>

								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table >
			</div>
			<div className="drawer-footer">
				<Button.Group>
					<Button onClick={() => handleClose()}>Cancel</Button>
					<Button.Or />
					<Button color='blue' onClick={() => { handleSubmit(selectedTestResources); handleClose(); }} disabled={!selectedTestResources.length}>Submit</Button>
				</Button.Group>
			</div>
		</Drawer>
	);
};

export default TestResourcesDrawer;