import React, { useState, useEffect } from 'react';
import { Grid, Icon, Header, Label, Button, Statistic } from 'semantic-ui-react';
import { getRemainingCredit } from '../../api/apiCalls';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import LoadListComponent from './component/LoadListComponent';
import SpentListComponent from './component/SpentListComponent';

const CreditReportPage = (props) => {

  const date = new Date();

  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(date);

  const [remainingCredit, setRemainingCredit] = useState('N/A');
  const [pendingApiCall, setPendingApiCall] = useState(false);
  const { tenant } = useSelector(store => store.LoginReducer);

  const remainingCreditApi = async (tenantId) => {
    setPendingApiCall(true);
    try {
      const response = await getRemainingCredit(tenantId);
      setRemainingCredit(response.data.result);
    } catch (error) {

    }
    setPendingApiCall(false);
  };

  useEffect(() => {
    if (tenant) {
      remainingCreditApi(tenant.id);
    }
  }, [tenant]);

  const ButtonDatePicker = ({ value, onClick, label }) => (
    <Button as='div' labelPosition='left' onClick={onClick}>
      <Label as='a' basic >
        {label}
      </Label>
      <Button icon color="teal" onClick={onClick}>
        {value}
      </Button>
    </Button>
  );

  return (
    <Grid>
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name='try' />
              </div>
              <Header.Content>
                Credit Report
                <Header.Subheader>You can see load and create credits</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div className="page-actions">
            <Statistic size="mini" color="blue">
              <Statistic.Value>
                {pendingApiCall ? <Icon name='circle notched' loading /> :
                  remainingCredit}
              </Statistic.Value>
              <Statistic.Label>Remainig Credit</Statistic.Label>
            </Statistic>
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Button.Group>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              maxDate={endDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              customInput={<ButtonDatePicker label="Start" />}
            />
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              minDate={startDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              customInput={<ButtonDatePicker label="End" />}

            />
          </Button.Group>
          <Grid style={{ marginTop: 8 }}>
            <Grid.Row columns={2}>
              <Grid.Column width='8'>
                <div className="shadow" style={{ padding: 22 }}>
                  <SpentListComponent startDate={startDate} endDate={endDate} tenantId={tenant && tenant.id} />
                </div>
              </Grid.Column>
              <Grid.Column width='8'>
                <div className="shadow" style={{ padding: 22 }}>
                  <LoadListComponent startDate={startDate} endDate={endDate} tenantId={tenant && tenant.id} />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CreditReportPage;