import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js';

const ChartJsGraph = (props) => {

  const canvasRef = useRef();

  const [data, setData] = useState(null);
  const [chart, setChart] = useState(null);

  const { details } = props;

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    const chart = new Chart(ctx, {
      type: details.type,
      data: details.data,
      options: details.options,
    });

    setData(details.data);
    setChart(chart);
    renderChart(details.data);
  }, [details]);

  const renderChart = (data) => {
    setData((prevData) => {
      prevData.labels = data.labels;

      data.datasets.forEach((ds, index) => {
        prevData.datasets[index].data = data.datasets[index].data;
      });

      return prevData;
    });
  };

  if (chart) {
    chart.update();
  }

  return <canvas width={750} height={250} ref={canvasRef} />;
};

export default ChartJsGraph;