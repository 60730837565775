import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { closeConfirmModal } from '../redux/actions/ModalActions';

const ConfirmModal = (props) => {

  const { visible, submitFunc, header, description } = useSelector(store => store.ModalReducer.confirmModal);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeConfirmModal());
  };

  return (
    <Modal size="mini" open={visible} onClose={handleClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <p>{description}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={handleClose}>No</Button>
        <Button
          positive
          icon='checkmark'
          labelPosition='right'
          content='Yes'
          onClick={submitFunc}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmModal;