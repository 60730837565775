import * as actions from '../consts';

const defaultTestRunVariation = {
  name: null,
  description: '',
  vmInfraRunIds: [],
  saveErrors: true,
  testToolId: null,
  cpsProp: 0,
  pluginNames: [],
  testSuiteVariation: {
    noOfTotalUsers: null,
    testDurationInSeconds: null,
    testSuiteId: null,
    scenarioVariations: []
  }
};

export const defaultTestRunVariationError = {
  name: null,
  vmInfraRunIds: null,
  testToolId: null,
  scenario: null,
  scenarioVariations: null
};

const defaultState = {
  testRunVariation: { ...defaultTestRunVariation },
  testRunVariationError: { ...defaultTestRunVariationError },
  testRunButtonEnabled: false
};

const TestRunReducer = (state = { ...defaultState }, action) => {
  switch (action.type) {

    case actions.UPDATE_TEST_RUN_VARIATION:
      return {
        ...state,
        testRunVariation: {
          ...state.testRunVariation,
          [action.data.name]: action.data.value
        }
      };

    case actions.UPDATE_TEST_SUITE_VARIATION:
      return {
        ...state,
        testRunVariation: {
          ...state.testRunVariation,
          testSuiteVariation: {
            ...state.testRunVariation.testSuiteVariation,
            ...action.suiteVariation
          }
        }
      };

    case actions.ADD_TEST_SCENARIO_VARIATION: {
      let scenarioVariations = state.testRunVariation.testSuiteVariation.scenarioVariations
        .filter(item => item.testScenarioId !== action.scenarioVariation.testScenarioId);
      scenarioVariations = [...scenarioVariations, action.scenarioVariation]
      return {
        ...state,
        testRunVariation: {
          ...state.testRunVariation,
          testSuiteVariation: {
            ...state.testRunVariation.testSuiteVariation,
            scenarioVariations
          }
        }
      };
    }

    case actions.REMOVE_TEST_SCENARIO_VARIATION:
      return {
        ...state,
        testRunVariation: {
          ...state.testRunVariation,
          testSuiteVariation: {
            ...state.testRunVariation.testSuiteVariation,
            scenarioVariations: state.testRunVariation.testSuiteVariation.scenarioVariations.filter(item => item.testScenarioId !== action.scenarioId),
          }
        }
      };


    case actions.TEST_RUN_VALIDATION:
      return {
        ...state,
        testRunButtonEnabled: action.data.testRunButtonEnabled,
        testRunVariationError: action.data.testRunVariationError,
      };

    case actions.RESET_TEST_RUN_VARIATION:
      return {
        ...state,
        testRunVariation: { ...defaultTestRunVariation }
      };

    default:
      return state;
  }
};

export default TestRunReducer;