import React, { useEffect, useState, useRef } from 'react';
import Drawer from '../../../components/drawer/Drawer';
import { Header, Icon, Button, Message, Table, Popup, Input, Dropdown, Label, Checkbox, Grid } from 'semantic-ui-react';
import _ from 'lodash';
import Highlighter from 'react-highlight-words'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateTestRunVariation } from '../../../redux/actions/TestRunActions';
import { toast } from 'react-toastify';
import PluginCrudModal from './PluginCrudModal';
import { openConfirmModal, closeConfirmModal } from '../../../redux/actions/ModalActions';
import { deletePluginSuccess } from '../../../redux/actions/ResourceActions';
import { deletePlugin } from '../../../api/apiCalls';

const PluginsDrawer = (props) => {

  const { visible, handleClose, showSelect } = props;
  const dispatch = useDispatch();

  const [selectedPluginIds, setSelectedPluginIds] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [error, setError] = useState();
  const [sort, setSort] = useState('desc');
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [pluginModalVisible, setPluginModalVisible] = useState(false);
  const [selectedPlugin, setSelectedPlugin] = useState();
  const searchRef = useRef();

  const { pluginNames } = useSelector(store => store.TestRunReducer.testRunVariation);
  const pluginList = useSelector(store => _.orderBy(store.ResourceReducer.pluginList, ['lastModificationTime'], [sort]));

  useEffect(() => {
    if (!visible) {
      setError();
      setSelectedPluginIds([]);
    }
  }, [visible]);

  useEffect(() => {
    let sortedSearchedList = _.orderBy(searchedList, ['lastModificationTime'], [sort]);
    setSearchedList(sortedSearchedList);
  }, [sort]);

  useEffect(() => {
    setTimeout(() => {
      if (!search || (search && search.length < 1)) {
        setSearchedList([]);
        setIsLoading(false);
      } else {
        const re = new RegExp(_.escapeRegExp(search), 'i');
        const isMatch = (result) => re.test(result.pluginName);

        setIsLoading(false);
        setSearchedList(_.filter(pluginList, isMatch));
      }
    }, 300);
  }, [search]);

  const handleSearchChange = (e) => {
    setIsLoading(true);
    setSearch(e.target.value);
  };

  const handleCheckbox = (e, id) => {
    if (!pluginNames.includes(id)) {
      if (e.target.checked || selectedPluginIds.includes(id)) {
        setSelectedPluginIds(prev => prev.filter(item => item !== id));
      } else {
        setSelectedPluginIds(prev => [...prev, id]);
      }
    } else {
      return toast.info('This plugin already selected for test run!');
    }
  };

  const handleOpenModal = async (plugin) => {
    await setSelectedPlugin(plugin);
    setPluginModalVisible(true);
  };

  const deletePluginApi = async () => {
    try {
      await deletePlugin(selectedPlugin.id);
      dispatch(deletePluginSuccess(selectedPlugin.id));
    } catch (err) {
      return toast.error(err.response && err.response.data.error.message);
    }
    dispatch(closeConfirmModal());
  };

  const handleDeletePlugin = () => {
    dispatch(openConfirmModal(deletePluginApi, 'Delete plugin', 'Are you sure you want to delete plugin?'));
  };

  const list = searchedList.length ? searchedList : pluginList;

  return (
    <Drawer style={{ padding: 16 }} visible={visible} onClose={handleClose} >
      <Grid.Row columns={1} style={{ margin: '-16px -16px 16px -16px' }} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name='list' />
              </div>
              <Header.Content>
                Plugins
                <Header.Subheader>Manage plugins</Header.Subheader>
              </Header.Content>
            </Header>
          </div>

          <div className="page-actions">
            <Button onClick={() => handleOpenModal()} >
              New Plugin
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
      <div className="drawer-body">
        <Table celled size="small" selectable sortable>
          <Table.Header>
            <Table.Row>
              {showSelect && <Table.HeaderCell></Table.HeaderCell>}
              <Table.HeaderCell>Name
                <Popup on="click" onMount={() => searchRef.current.focus()} trigger={<span style={{ float: "right" }}><Icon name='search' /></span>}>
                  <Input loading={isLoading} ref={searchRef} focus icon='search' placeholder='Search...' onChange={_.debounce(handleSearchChange, 500, { leading: true })} />
                </Popup>
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => setSort(prev => prev === 'desc' ? 'asc' : 'desc')}>Modified
              <span style={{ float: "right" }}><Icon name='sort' /></span>
              </Table.HeaderCell>
              <Table.HeaderCell>JAR File Names</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {list.map((plugin) => (
              <Table.Row>
                {showSelect &&
                  <Table.Cell textAlign="center">
                    <Checkbox
                      onClick={(e) => handleCheckbox(e, plugin.id)}
                      checked={selectedPluginIds.includes(plugin.id)}
                      disabled={pluginNames.includes(plugin.id)}
                    />
                  </Table.Cell>
                }
                <Table.Cell>
                  <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[search]}
                    autoEscape
                    textToHighlight={plugin.pluginName}
                  />
                </Table.Cell>
                <Table.Cell>{plugin.lastModificationTime ? moment(plugin.lastModificationTime).fromNow() : 'N/A'}</Table.Cell>
                <Table.Cell>{plugin.fileNames.map(file => <Label>{file}</Label>)}</Table.Cell>
                <Table.Cell textAlign='center' width={1} >
                  <Button.Group color='teal'>
                    <Dropdown
                      className='button icon'
                      floating
                      onClick={() => setSelectedPlugin(plugin)}
                    >
                      <Dropdown.Menu direction='left'>
                        <Dropdown.Item onClick={() => handleOpenModal(plugin)}><Icon name="edit" /> Edit Plugin</Dropdown.Item>
                        <Dropdown.Item onClick={handleDeletePlugin}>
                          <Icon name="trash alternate outline" /> Delete Plugin
                      </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Button.Group>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {error &&
          <Message negative>
            <Message.Header>{error.message}</Message.Header>
            <p>{error.details}</p>
          </Message>
        }
      </div>
      <div className="drawer-footer">
        <Button negative onClick={handleClose} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button primary disabled={!selectedPluginIds.length}
          onClick={() => {
            dispatch(updateTestRunVariation('pluginNames', pluginNames.concat(selectedPluginIds)));
            handleClose();
          }}
        >
          Submit
        </Button>
      </div>
      <PluginCrudModal visible={pluginModalVisible} handleClose={() => setPluginModalVisible(false)} data={selectedPlugin} />
    </Drawer >
  );
};

export default PluginsDrawer;