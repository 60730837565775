import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

const defaultAzure = {
  armClientId: '',
  armSubscriptionId: '',
  armTenantId: '',
  armStorageAccount: ''
};

const AzureForm = (props) => {

  const [azure, setAzure] = useState(defaultAzure);
  const { handleProviderDataChange, azureAccount } = props;

  useEffect(() => {
    handleProviderDataChange('azure', azure);
  }, [azure]);

  useEffect(() => {
    if (azureAccount) {
      setAzure(azureAccount);
    } else {
      setAzure(defaultAzure);
    }
  }, [azureAccount]);

  const handleChange = (e, { name, value }) => {
    setAzure(prevCloud => ({
      ...prevCloud,
      [name]: value
    }));
  }

  const { armClientId, armSubscriptionId, armTenantId, armStorageAccount } = azure;

  return (
    <Form>
      <Form.Field>
        <label>Arm Client Id: </label>
        <Form.Input name="armClientId" value={armClientId} onChange={handleChange} placeholder='Arm Client Id' />
      </Form.Field>
      <Form.Field>
        <label>Arm Subscription Id: </label>
        <Form.Input name="armSubscriptionId" value={armSubscriptionId} onChange={handleChange} placeholder='Arm Subscription Id' />
      </Form.Field>
      <Form.Field>
        <label>Arm Tenant Id: </label>
        <Form.Input name="armTenantId" value={armTenantId} onChange={handleChange} placeholder='ArmTenantId' />
      </Form.Field>
      <Form.Field>
        <label>Arm Storage Account: </label>
        <Form.Input name="armStorageAccount" value={armStorageAccount} onChange={handleChange} placeholder='Arm Storage Account' />
      </Form.Field>
    </Form>
  );
};

export default AzureForm;