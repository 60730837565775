import React from 'react';
import { Statistic, Icon } from 'semantic-ui-react';

const TestLiveStats = ({ testResults, totalTime, totalUser, loading }) => {

  const { virtualUsers, rpmInterval, rpmLastMinute,
    noOfTotalRequests, noOfErroredRequests, clusterCount, startedCount, runningCount } = testResults || {};

  const successCount = testResults ? noOfTotalRequests - noOfErroredRequests : 0;

  const TestStatistic = ({ iconProps, value, label }) => {
    return (
      <Statistic size="mini" color="blue">
        <Statistic.Value>
          {loading ? <Icon name='circle notched' loading /> :
            `${value || 0}`}
        </Statistic.Value>
        <Statistic.Label>{label}</Statistic.Label>
      </Statistic>
    );
  };

  const secondtoDate = (second) => {
    var date = new Date(1970, 0, 1); // Epoch
    date.setSeconds(second);

    const hours = date.getHours();
    const minutes = '0' + date.getMinutes();
    const seconds = '0' + date.getSeconds();

    const human = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    return human;
  };

  return (
    <Statistic.Group size="mini" widths="12" style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }} >
      <TestStatistic value={clusterCount} label="Total Clusters" />
      <TestStatistic value={startedCount} label="Total Inst." />
      <TestStatistic value={runningCount} label="Running Inst." />
      <TestStatistic value={totalUser} label="Total User" />
      <TestStatistic value={virtualUsers} label="Virtual User" />
      <TestStatistic value={rpmInterval} label="RPM (interval)" />
      <TestStatistic value={rpmLastMinute} label="RPM (last min.)" />
      <TestStatistic value={noOfTotalRequests} label="Request Count" />
      <TestStatistic value={successCount} label="Success Count" />
      <TestStatistic value={noOfErroredRequests} label="Error Count" />
      <TestStatistic value={`${(100 / noOfTotalRequests * (noOfTotalRequests - noOfErroredRequests)).toFixed(2)} %`} label="Ratio" />
      <TestStatistic value={secondtoDate(totalTime || 0)} label="Total Test Time" />
    </Statistic.Group>
  );
};

export default TestLiveStats;