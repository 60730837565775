import { getTestResources, fetchPlugins, updateTestResource } from '../../api/apiCalls';
import * as actions from '../consts';

const fetchTestResourcesBegin = () => ({
  type: actions.FETCH_TEST_RESOURCE_LIST_BEGIN
});

const fetchTestResourcesSuccess = (resources) => ({
  type: actions.FETCH_TEST_RESOURCE_LIST_SUCCESS,
  resources
});

const fetchTestResourcesFailure = (error) => ({
  type: actions.FETCH_TEST_RESOURCE_LIST_FAILURE,
  error
});

export const fetchTestResources = () => async (dispatch) => {
  dispatch(fetchTestResourcesBegin());
  try {
    const response = await getTestResources();
    dispatch(fetchTestResourcesSuccess(response.data.result));
  } catch (err) {
    dispatch(fetchTestResourcesFailure(err.response ? err.response.data.error : err));
  }
};

const fetchPluginsBegin = () => ({
  type: actions.FETCH_PLUGIN_LIST_BEGIN
});

const fetchPluginsSuccess = (plugins) => ({
  type: actions.FETCH_PLUGIN_LIST_SUCCESS,
  plugins
});

const fetchPluginsFailure = () => ({
  type: actions.FETCH_PLUGIN_LIST_FAILURE
});

export const getPlugins = () => async (dispatch) => {
  dispatch(fetchPluginsBegin());
  try {
    const response = await fetchPlugins();
    dispatch(fetchPluginsSuccess(response.data.result));
  } catch (err) {
    dispatch(fetchPluginsFailure());
    throw err;
  }
};

const updateResourceBegin = () => ({
  type: actions.UPDATE_TEST_RESOURCE_BEGIN
});

const updateResourceSuccess = (resource) => ({
  type: actions.UPDATE_TEST_RESOURCE_SUCCESS,
  resource
});

const updateResourceFailed = () => ({
  type: actions.UPDATE_TEST_RESOURCE_FAILURE,
});

export const updateResource = (resource) => async (dispatch) => {
  dispatch(updateResourceBegin());

  let data = new FormData();

  data.append('formFile', resource.file);
  data.append('nameOverride', resource.resourceFileName);
  data.append('needsSplit', resource.needsSplit);
  data.append('csvHasHeader', resource.csvHasHeader);
  data.append('resourceType', resource.resourceType);

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };

  try {
    const response = await updateTestResource(resource.id, data, config);
    dispatch(updateResourceSuccess(response.data.result));
    return response.data.result;
  } catch (err) {
    dispatch(updateResourceFailed());
    throw err;
  }
};

export const addResourceSuccess = (resource) => ({
  type: actions.ADD_TEST_RESOURCE_SUCCESS,
  resource
});

export const deleteResourceSuccess = (resourceId) => ({
  type: actions.DELETE_TEST_RESOURCE_SUCCESS,
  resourceId
});

export const addPluginSuccess = (plugin) => ({
  type: actions.ADD_PLUGIN_SUCCESS,
  plugin
});

export const deletePluginSuccess = (pluginId) => ({
  type: actions.DELETE_PLUGIN_SUCCESS,
  pluginId
});