import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, TextArea, Message, Button, Label, Icon } from 'semantic-ui-react';
import UploadComponent from '../../../components/UploadComponent';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createPlugin, updatePlugin } from '../../../api/apiCalls';
import { useDispatch } from 'react-redux';
import { addPluginSuccess } from '../../../redux/actions/ResourceActions';

const defaultPlugin = {
  pluginName: '',
  description: '',
  fileNames: []
};

const PluginCrudModal = ({ data, visible, handleClose }) => {

  const [plugin, setPlugin] = useState({ ...defaultPlugin });
  const [files, setFiles] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      if (data) {
        setPlugin(data);
      }
    } else {
      setPlugin({ ...defaultPlugin });
      setFiles([]);
      setError();
    }
  }, [visible]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { id, pluginName, fileNames, description } = plugin;

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      }
    };

    let data = new FormData();
    for (var x = 0; x < files.length; x++) {
      data.append('formFiles', files[x]);
    }

    data.append('pluginName', pluginName);
    data.append('description', description || '');

    for (var y = 0; y < fileNames.length; y++) {
      data.append('fileNames', fileNames[y]);
    }

    setInProgress(true);
    setError();
    try {
      let response;
      if (id) {
        response = await updatePlugin(id, data, config);
      } else {
        response = await createPlugin(data, config);
      }
      dispatch(addPluginSuccess(response.data.result));
      handleClose();
    } catch (error) {
      setError(error.response && error.response.data.error)
    }
    setInProgress(false);
    setUploadProgress(0);
  };

  const handleChange = (e, { name, value }) => {
    setPlugin(prev => ({ ...prev, [name]: value }));
  };

  const getFiles = (newFiles) => {
    console.log('fileNames', plugin.fileNames)
    setFiles(prev => _.uniqBy(prev.concat(newFiles.filter(item => !fileNames.includes(item.name))), function (e) {
      return e.name
    }));
  };

  const handleRemovePlugin = (fileName) => {
    const fileExist = files.find(item => item.name === fileName);
    if (fileExist) {
      setFiles(prev => prev.filter(item => item.name !== fileName));
    } else {
      setPlugin(prev => ({ ...prev, fileNames: prev.fileNames.filter(item => item !== fileName) }));
    }
  };

  useEffect(() => {
    const names = files.map(item => item.name);
    setPlugin(prev => ({
      ...prev,
      fileNames: _.uniqBy(prev.fileNames.concat(names), function (e) {
        return e
      })
    }));
  }, [files]);

  const { id, pluginName, fileNames, description } = plugin;
  const text = data ? 'Edit' : 'Add';

  return (
    <Modal open={visible} onClose={handleClose} closeIcon>
      <Modal.Header>{text} Plugin</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Upload Files</label>
            <UploadComponent uploadFunction={getFiles} accept={['.jar']} multiple={true} loading={inProgress} uploadProgress={uploadProgress} />
          </Form.Field>

          <Form.Field>
            {fileNames.map(file => (
              <Label color="blue"
                onClick={() => handleRemovePlugin(file)}>
                {file} <Icon name='delete' />
              </Label>
            ))}
          </Form.Field>

          <Form.Field>
            <label>Plugin Name</label>
            <Input onChange={handleChange} name="pluginName" placeholder="Plugin Name" value={pluginName} />
          </Form.Field>

          <Form.Field>
            <label>Description</label>
            <TextArea name='description' onChange={handleChange} placeholder='Tell us more' value={description} />
          </Form.Field>
        </Form>
        {error &&
          <Message negative>
            <Message.Header>{error.message}</Message.Header>
            <p>{error.details}</p>
          </Message>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={handleClose}>Cancel</Button>
        <Button
          positive
          icon='checkmark'
          labelPosition='right'
          content={id ? 'Update' : 'Save'}
          onClick={handleSubmit}
          disabled={inProgress || (!pluginName || !fileNames.length)}
          loading={inProgress}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default PluginCrudModal;