import React, { useEffect, useState, useRef } from 'react';
import { Grid, Header, Icon, Button, Table, Dropdown, Popup, Input } from 'semantic-ui-react';
import { getAllTestRuns, getTestSuites } from '../../api/apiCalls';
import { useApiProgress } from '../../api/ApiProgressHook';
import moment from 'moment';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import NoDataFound from '../../components/NoDataFound';
import LoadingComponent from '../../components/LoadingComponent';

const TestRunListPage = (props) => {

  const [testSuiteList, setTestSuiteList] = useState([]);

  const [testRunList, setTestRunList] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [error, setError] = useState();
  const [selectedSuiteId, setSelectedSuiteId] = useState();
  const [sort, setSort] = useState('desc');
  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [filterParams, setFilterParams] = useState({ suiteId: null });
  const searchRef = useRef();

  const pendingApiCall = useApiProgress('get', '/api/TestRuns');

  useEffect(() => {
    fetchTestSuites();
  }, []);

  useEffect(() => {
    const { suiteId } = filterParams;
    fetchTestRuns(suiteId);
  }, [filterParams]);

  useEffect(() => {
    let sortedResult = _.orderBy(testRunList, ['creationTime'], [sort]);
    let sortedSearchedList = _.orderBy(searchedList, ['creationTime'], [sort]);
    setTestRunList(sortedResult);
    setSearchedList(sortedSearchedList);
  }, [sort]);

  const fetchTestRuns = async (suiteId) => {
    try {
      const response = await getAllTestRuns(suiteId);
      let sortedResult = _.orderBy(response.data.result, ['creationTime'], [sort]);
      setTestRunList(sortedResult);
    } catch (err) {
      setError(err.response && err.response.data.error);
    }
  };

  const fetchTestSuites = async () => {
    try {
      const response = await getTestSuites();
      let sortedResult = _.orderBy(response.data.result, ['lastModificationTime'], ['desc']);
      setTestSuiteList(sortedResult.map(item => ({ key: item.id, text: item.name, value: item.id })));
    } catch (err) {
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (!search || (search && search.length < 1)) {
        setSearchedList([]);
        setIsLoading(false);
      } else {
        const re = new RegExp(_.escapeRegExp(search), 'i');
        const isMatch = (result) => re.test(result.name);

        setIsLoading(false);
        setSearchedList(_.filter(testRunList, isMatch));
      }
    }, 300);
  }, [search]);

  const handleSearchChange = (e) => {
    setIsLoading(true);
    setSearch(e.target.value);
  };

  const handleFilterDropdownChange = (e, { name, value }) => {
    const newValue = value === '' ? null : value;
    setFilterParams(prev => ({ ...prev, [name]: newValue }));
  };

  const { suiteId } = filterParams;

  const runList = searchedList.length ? searchedList : testRunList;


  const getSuiteName = (suiteId) => {
    let suite = testSuiteList.find(item => item.key === suiteId);
    return suite ? suite.text : "N/A";
  };

  return (
    <Grid >
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name='play circle outline' />
              </div>
              <Header.Content>
                Test Runs
                <Header.Subheader>Manage test runs</Header.Subheader>
              </Header.Content>
            </Header>
          </div>

          <div className="page-actions">
            {suiteId && <Button as={Link} to={`/test-suite/${suiteId}`}>Go Suite</Button>}
          </div>
        </Grid.Column>
      </Grid.Row>


      <Grid.Row className="visium-page-content" style={{ marginTop: 0 }}>
        <Grid.Column width={16} style={{ marginBottom: 14 }}>
          <Dropdown
            placeholder='Test Suite'
            icon='filter'
            floating
            labeled
            button
            clearable
            className='icon'
            name="suiteId"
            value={suiteId}
            options={testSuiteList}
            onChange={handleFilterDropdownChange}
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <div className="shadow" >

            {(pendingApiCall) ?
              <LoadingComponent msg="Test Runs Loading" />
              :
              <>
                {!testRunList.length ?
                  <NoDataFound msg='There is no test run here!' />
                  :
                  <>
                    <Table celled size="small" selectable sortable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name
                            <Popup on="click" onUnmount={() => setSearch()} onMount={() => searchRef.current.focus()} trigger={<span style={{ float: "right" }}><Icon name='search' /></span>}>
                              <Input loading={isLoading} ref={searchRef} focus icon='search' placeholder='Search...' onChange={_.debounce(handleSearchChange, 500, { leading: true })} />
                            </Popup>
                          </Table.HeaderCell>
                          <Table.HeaderCell onClick={() => setSort(prev => prev === 'desc' ? 'asc' : 'desc')}>Time
                          <span style={{ float: "right" }}><Icon name='sort' /></span>
                          </Table.HeaderCell>
                          <Table.HeaderCell># of Users</Table.HeaderCell>
                          <Table.HeaderCell>Duration (sec)</Table.HeaderCell>
                          <Table.HeaderCell>Description</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {runList.map((run) => (
                          <Table.Row>
                            <Table.Cell>
                              <Highlighter
                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                searchWords={[search]}
                                autoEscape
                                textToHighlight={run.name}
                              />
                              <br />
                              <small>Test Suite: {getSuiteName(run.testSuiteVariation.testSuiteId)}</small>
                            </Table.Cell>
                            <Table.Cell>{moment(run.creationTime).fromNow()}</Table.Cell>
                            <Table.Cell>{run.testSuiteVariation && run.testSuiteVariation.noOfTotalUsers}</Table.Cell>
                            <Table.Cell>{run.testSuiteVariation && run.testSuiteVariation.testDurationInSeconds}</Table.Cell>
                            <Table.Cell>{run.description}</Table.Cell>
                            <Table.Cell textAlign='center' width={1} >
                              <Button.Group size='tiny' >
                                <Dropdown
                                  className='icon'
                                  labeled
                                  button
                                  text='Actions'
                                  icon='ellipsis horizontal'
                                  iconPosition='right'
                                >
                                  <Dropdown.Menu direction='left'>
                                    <Dropdown.Item onClick={() => props.history.push(`/test-run/${run.id}/summary`)} >
                                      <Icon name="magnify" /> Summary
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => props.history.push(`/test-run/${run.id}/monitoring`)} >
                                      <Icon name="eye" /> Monitoring
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => props.history.push(`/test-suite/${run.testSuiteVariation.testSuiteId}`)} >
                                      <Icon name="file alternate outline" /> Suite Details
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Button.Group>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </>
                }
              </>
            }
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default TestRunListPage;