export const CloudProviderTypes = {
  AZURE: 1,
  AWS: 2,
  DIGITALOCEAN: 3,
  GOOGLECLOUD: 4,
  ONPREM: 5,
};

export const CloudProviderNameArray = ['Not Used', 'Azure', 'Aws', 'DigitalOcean', 'GoogleCloud', 'On Premise'];

export const imageBuildStatus = [
  { message: 'Not Built Yet', color: 'gray', operationEnded: true },
  { message: 'Building', color: 'yellow', operationEnded: false },
  { message: 'Built Ready', color: 'green', operationEnded: true },
  { message: 'Built Failed', color: 'red', operationEnded: true }
];


export const VmInfraStatusMap = [
  {
    index: 0,
    state: 'UninitializedState',
    status: 'Not Created Yet',
    style: 'green',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: false,
    stopDisabled: true,
    refreshDisabled: true,
    operationEnded: true,
  },
  {
    index: 1,
    state: 'CreationRequested',
    status: 'Infra Creation in Progress',
    style: 'green',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: true,
    stopDisabled: true,
    refreshDisabled: true,
    operationEnded: false,
  },
  {
    index: 2,
    state: 'CreationSucceeded',
    status: 'Infra Creation in Progress',
    style: 'green',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: true,
    stopDisabled: true,
    refreshDisabled: true,
    operationEnded: false,
  },
  {
    index: 3,
    state: 'CreationFailed',
    status: 'Infra Creation Failed',
    style: 'red',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: false,
    stopDisabled: false,
    refreshDisabled: false,
    operationEnded: true,
  },

  {
    index: 4,
    state: 'UpdateRequested',
    status: 'Infra Creation in Progress',
    style: 'green',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: true,
    stopDisabled: true,
    refreshDisabled: true,
    operationEnded: false,
  },
  {
    index: 5,
    state: 'UpdateSucceeded',
    status: 'Infra Creation in Progress',
    style: 'green',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: true,
    stopDisabled: true,
    refreshDisabled: true,
    operationEnded: false,
  },
  {
    index: 6,
    state: 'UpdateFailed',
    status: 'Infra Creation Failed',
    style: 'red',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: false,
    stopDisabled: false,
    refreshDisabled: false,
    operationEnded: true,
  },

  {
    index: 7,
    state: 'OutputRequested',
    status: 'Infra Creation in Progress',
    style: 'green',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: true,
    stopDisabled: true,
    refreshDisabled: true,
    operationEnded: false,
  },
  {
    index: 8,
    state: 'OutputSucceeded',
    status: 'Infra Creation in Progress',
    style: 'green',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: true,
    stopDisabled: true,
    refreshDisabled: true,
    operationEnded: false,
  },
  {
    index: 9,
    state: 'OutputFailed',
    status: 'Infra Creation Failed',
    style: 'red',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: false,
    stopDisabled: false,
    refreshDisabled: false,
    operationEnded: true,
  },

  {
    index: 10,
    state: 'AppProvisioningRequested',
    status: 'Infra Creation in Progress',
    style: 'green',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: true,
    stopDisabled: true,
    refreshDisabled: true,
    operationEnded: false,
  },
  {
    index: 11,
    state: 'AppProvisioningSucceeded',
    status: 'Infra Ready for Use',
    style: 'green',
    readyStyle: 'success',
    infraReady: true,
    playDisabled: true,
    stopDisabled: false,
    refreshDisabled: false,
    operationEnded: true,
  },
  {
    index: 12,
    state: 'AppProvisioningFailed',
    status: 'Infra Creation Failed',
    style: 'red',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: false,
    stopDisabled: false,
    refreshDisabled: false,
    operationEnded: true,
  },

  {
    index: 13,
    state: 'DestroyRequested',
    status: 'Destroy in Progress',
    style: 'green',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: true,
    stopDisabled: true,
    refreshDisabled: true,
    operationEnded: false,
  },
  {
    index: 14,
    state: 'DestroySucceeded',
    status: 'Infra Destroyed',
    style: 'red',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: false,
    stopDisabled: true,
    refreshDisabled: true,
    operationEnded: true,
  },
  {
    index: 15,
    state: 'DestroyFailed',
    status: 'Destroy Failed',
    style: 'red',
    readyStyle: 'danger',
    infraReady: false,
    playDisabled: true,
    stopDisabled: false,
    refreshDisabled: true,
    operationEnded: true,
  },
];
