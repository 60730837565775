import React, { useEffect, useState } from 'react';
import { Grid, Header, Icon, Button, Table, Modal, Dropdown } from 'semantic-ui-react';
import { fetchCloudAccounts, createCloudAccount, updateCloudAccount, deleteCloudAccount } from '../../api/apiCalls';
import { useApiProgress } from '../../api/ApiProgressHook';
import moment from 'moment';
import CloudAccountCrudForm from './components/CloudAccountCrudForm';
import ImageBuilderForm from './components/ImageBuilderForm';
import { getCloudInventories } from '../../redux/actions/CloudActions';
import { useDispatch, useSelector } from 'react-redux';
import { CloudProviderTypes } from './consts';
import { openConfirmModal, closeConfirmModal } from '../../redux/actions/ModalActions';
import NoDataFound from '../../components/NoDataFound';
import LoadingComponent from '../../components/LoadingComponent';
import { getIconName } from '../test-run/components/TestRunSideMenu';

const CloudAccountsPage = () => {

  const [cloudAccountList, setCloudAccountList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [modalImageBuildVisible, setModalImageBuildVisible] = useState(false);
  const [error, setError] = useState();
  const [selectedAcc, setSelectedAcc] = useState(undefined);
  const [initialApiCall, setInitialCall] = useState(false);


  const dispatch = useDispatch();
  const pendingApiCall = useApiProgress('get', '/api/CloudAccounts');
  const pendingPostApiCall = useApiProgress('post', '/api/CloudAccounts');
  const pendingPutApiCall = useApiProgress('put', '/api/CloudAccounts/');

  useEffect(() => {

    const getDatas = async () => {
      setInitialCall(true);
      await dispatch(getCloudInventories());
      await getCloudAccounts();
      setInitialCall(false);
    };

    getDatas();
  }, []);

  const getCloudAccounts = async () => {
    try {
      const response = await fetchCloudAccounts();
      setCloudAccountList(response.data.result);
    } catch (err) { }
  };


  const handleCreateSubmit = async (body) => {
    setError(null);
    try {
      await createCloudAccount(body);
      setModalVisible(false);
      getCloudAccounts();
    } catch (err) {
      setError(err.response.data.error);
    }
  };

  const handleUpdateSubmit = async (body) => {
    setError(null);
    try {
      await updateCloudAccount(body.id, body);
      setModalEditVisible(false);
      getCloudAccounts();
    } catch (err) {
      setError(err.response.data.error);
    }
  };

  const handleDeleteBtn = async () => {
    dispatch(openConfirmModal(deleteCloudAcc, "Delete Cloud Account", `Are you sure you want to delete cloud account?`));
  };

  const deleteCloudAcc = async () => {
    try {
      await deleteCloudAccount(selectedAcc.id);
      getCloudAccounts();
    } catch (err) { }
    dispatch(closeConfirmModal());
  };

  const cloudInventories = useSelector(store => store.CloudReducer.cloudInventories);

  const providers = cloudInventories.map(item => {
    const { cloudProvider } = item;
    return { key: cloudProvider.id, text: cloudProvider.name, value: cloudProvider.id }
  }) || [];

  return (
    <>
      <Grid >
        <Grid.Row columns={1} className='page-header'>
          <Grid.Column>
            <div >
              <Header as='h3' className="page-title">
                <div className="page-icon">
                  <Icon name='cloud' />
                </div>
                <Header.Content>
                  Cloud Accounts
                    <Header.Subheader>Manage cloud accounts</Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <div className="page-actions">
              <Button onClick={() => setModalVisible(true)} primary icon>
                <Icon name='plus' />  Add New Account
              </Button>

            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="visium-page-content">
          <Grid.Column>
            <div className="shadow" >
              {(initialApiCall) ?
                <LoadingComponent msg="Accounts Loading" />
                :
                <>
                  {!cloudAccountList.length ?
                    <NoDataFound msg='There is no account here!' />
                    :
                    <>
                      <Table celled size="small" selectable sortable>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Account Name</Table.HeaderCell>
                            <Table.HeaderCell>Modified</Table.HeaderCell>
                            <Table.HeaderCell>Account Type</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {cloudAccountList.map((acc) => (
                            <Table.Row>
                              <Table.Cell>
                                <div style={{display:'flex', alignItems:'center'}}>
                                  <div>
                                    <Icon {...getIconName(acc.providerId)} size='big' />
                                  </div>
                                  <div>
                                    {acc.name}
                                    <br />
                                    {acc.description}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell>{moment(acc.lastModificationTime).fromNow()}</Table.Cell>
                              <Table.Cell>{providers.find(prov => prov.key === acc.providerId).text}</Table.Cell>
                              <Table.Cell textAlign='center' width={1} >
                                <Button.Group size='tiny' >
                                  <Dropdown
                                    className='icon'
                                    labeled
                                    button
                                    text='Actions'
                                    icon='ellipsis horizontal'
                                    iconPosition='right'
                                    onClick={() => setSelectedAcc(acc)}
                                  >
                                    <Dropdown.Menu direction='left'>

                                      <Dropdown.Item onClick={() => { setModalEditVisible(true) }}><Icon name="edit" /> Edit Account</Dropdown.Item>

                                      {acc.providerId !== CloudProviderTypes.ONPREM &&
                                        <Dropdown.Item onClick={() => { setModalImageBuildVisible(true) }}>
                                          <Icon name="disk" /> Image Builds
                                       </Dropdown.Item>
                                      }

                                      <Dropdown.Item onClick={() => { handleDeleteBtn() }}>
                                        <Icon name="trash alternate outline" /> Delete Account
                                      </Dropdown.Item>

                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Button.Group>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </>
                  }
                </>
              }
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        size='large'
        closeIcon
      >
        <Header icon='cloud' content='Add Cloud Account' />
        <Modal.Content>
          <CloudAccountCrudForm saveAction={handleCreateSubmit} inProgress={pendingPostApiCall} error={error} providers={providers} />
        </Modal.Content>
      </Modal>
      <Modal
        open={modalEditVisible}
        onClose={() => setModalEditVisible(false)}
        size='large'
        closeIcon
      >
        <Header icon='cloud' content='Edit Cloud Account' />
        <Modal.Content>
          <CloudAccountCrudForm cloudAccount={selectedAcc} saveAction={handleUpdateSubmit} inProgress={pendingPutApiCall} error={error} providers={providers} />
        </Modal.Content>
      </Modal>
      <Modal
        open={modalImageBuildVisible}
        onClose={() => setModalImageBuildVisible(false)}
        size='large'
        closeIcon
      >
        <Header icon='disk' content='Initiate Image Build' />
        <Modal.Content>
          <ImageBuilderForm cloudAccount={selectedAcc} />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default CloudAccountsPage;