import React from 'react';
import { Table, Header, Divider } from 'semantic-ui-react';

const TopNResponses = (props) => {

  const data = props.data || [];

  return (
    <div style={{ marginTop: '3em' }}>
      <Header>TOP N RESPONSES</Header>
      <Divider />
      <Table basic>
        <Table.Header>
          <Table.HeaderCell>Scenario Name</Table.HeaderCell>
          <Table.HeaderCell>Request Name</Table.HeaderCell>
          <Table.HeaderCell>Mean</Table.HeaderCell>
          <Table.HeaderCell>Median</Table.HeaderCell>
          <Table.HeaderCell>Min</Table.HeaderCell>
          <Table.HeaderCell>Max</Table.HeaderCell>
          <Table.HeaderCell>Count</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {data.map(item => (
            <Table.Row>
              <Table.Cell>
                {item.scenarioName}
              </Table.Cell>
              <Table.Cell>
                {item.requestName}
              </Table.Cell>
              <Table.Cell>
                {item.meanInMs}
              </Table.Cell>
              <Table.Cell>
                {item.medianInMs}
              </Table.Cell>
              <Table.Cell>
                {item.minInMs}
              </Table.Cell>
              <Table.Cell>
                {item.maxInMs}
              </Table.Cell>
              <Table.Cell>
                {item.count}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default TopNResponses;