import * as actions from '../consts';

const defaultState = {
  cloudInventories: [],
  fetchClustersAgain: false,
  clientSecretModal: {
    visible: false,
    submitFunc: null,
    btnTxt: `Let's Start`,
    headerTxt: `Client Secret`,
    isDestroyAction: false
  }
};

const CloudReducer = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case actions.FETCH_CLOUD_INVENTORIES_SUCCESS:
      return {
        ...state,
        cloudInventories: action.data
      };

    case actions.FETCH_CLUSTER_LIST_AGAIN:
      return {
        ...state,
        fetchClustersAgain: action.data
      };

    case actions.OPEN_CLIENT_SECRET_MODAL:
      return {
        ...state,
        clientSecretModal: {
          visible: true,
          submitFunc: action.data.submitFunc,
          btnTxt: action.data.btnTxt,
          headerTxt: action.data.headerTxt,
          isDestroyAction: action.data.isDestroyAction
        }
      };

    case actions.CLOSE_CLIENT_SECRET_MODAL:
      return {
        ...state,
        clientSecretModal: {
          visible: false,
          submitFunc: null,
          btnTxt: `Let's Start`,
          headerTxt: `Client Secret`,
          isDestroyAction: false
        }
      };

    default:
      return state;
  }
};

export default CloudReducer;