import * as actions from '../consts';

const defaultConfirmModal = {
  visible: false,
  submitFunc: null,
  header: 'Confirm',
  description: 'Are you sure?'
};

const defaultState = {
  confirmModal: { ...defaultConfirmModal }
};

const CloudReducer = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case actions.OPEN_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          visible: true,
          submitFunc: action.data.submitFunc,
          header: action.data.header,
          description: action.data.description
        }
      };

    case actions.CLOSE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: { ...defaultConfirmModal }
      };

    default:
      return state;
  }
};

export default CloudReducer;