import React, { useState, useEffect } from 'react';
import { Form, Button, Input, Icon, Loader, Message, Header, Segment } from 'semantic-ui-react';
import { forgotPassword } from '../../api/apiCalls';
import { Link } from 'react-router-dom';
import LoginLayout from '../../layout/LoginLayout';

const ForgotPasswordPage = (props) => {

  const [email, setEmail] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [successed, setSuccessed] = useState(false);
  const [error, setError] = useState();
  const [emailValidation, setEmailValidation] = useState();

  useEffect(() => {
    setError();
    setEmailValidation();
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError();
    setInProgress(true);
    setEmailValidation();
    try {
      forgotPassword(email);
      setSuccessed(true);
    } catch (err) {
      setError(err.response.data.error || error);
    }
    setInProgress(false);
  };

  return (
    <LoginLayout>
      <Form size='large' error>
        <Segment style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Header as='h3' style={{ color: '#172B4D' }} textAlign='center'>
            Reset Password
        </Header>
          {successed ?
            <Message>Check your mail box.</Message>
            :
            <>
              <Form.Field error={emailValidation}>
                <Input size='big' fluid name="email" icon='at' iconPosition='left'
                  value={email}
                  type="email"
                  placeholder='Email Address'
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailValidation && <label color='red'>{emailValidation}</label>}
              </Form.Field>

              <Message negative hidden={!error}>
                <Message.Header>{error?.message}</Message.Header>
                <p>{error?.details}</p>
              </Message>
              <Button primary fluid size='big' onClick={handleSubmit} type='submit' disabled={inProgress || !email} >
                <Loader inline size="mini" active={inProgress} /> Submit
              </Button>
            </>
          }
        </Segment>
      </Form>
      <Message warning><Link to='/login'><Icon name="sign-in" /> Login Page</Link></Message>
    </LoginLayout>
  );
};

export default ForgotPasswordPage;