import React, { useState } from 'react';
import { Form, Input, Header, Button, Checkbox } from 'semantic-ui-react';
import { toast } from 'react-toastify';

export const creditLoadTypeList = [
  { key: 1, text: 'Periodic', value: 1 },
  { key: 2, text: 'Promo', value: 2 },
  { key: 3, text: 'PeriodicPromo', value: 3 },
  { key: 4, text: 'Bought', value: 4 },
  { key: 5, text: 'Other', value: 5 },
];

export const creditLoadPeriodTypeList = [
  { key: 1, text: 'Daily', value: 1 },
  { key: 2, text: 'Weekly', value: 2 },
  { key: 3, text: 'Monthly', value: 3 },
  { key: 4, text: 'Yearly', value: 4 },
  { key: 5, text: 'Once', value: 5 },
];

const defaultLoadData = {
  name: '',
  description: '',
  hasExpired: false,
  amount: 1,
  creditLoadTypeId: 0,
  loadPeriod: 0
};

const LoadCreditForm = (props) => {

  const [loadData, setLoadData] = useState({ ...defaultLoadData });
  const [pendingApiCall, setPendingApiCall] = useState(false);

  const { handleCancel, handleOk } = props;

  const handleChange = (e, { name, value }) => {
    setLoadData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNumberChange = (e, { name, value }) => {
    setLoadData(prev => ({
      ...prev,
      [name]: Math.abs(parseInt(value, 10))
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPendingApiCall(true);
    try {
      await handleOk(loadData);
      toast.success('Credit successfully added.');
      handleCancel();
    } catch (err) {
      toast.error(err.response.data.error.details);
      setLoadData({ ...defaultLoadData });
    }
    setPendingApiCall(false);
  };

  const { amount, hasExpired } = loadData;

  return (
    <Form>
      <Header size="medium">Load Credit</Header>
      <Form.Group widths='equal'>
        <Form.Field>
          <label>Name</label>
          <Input name="name" placeholder='Name' onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <label>Description</label>
          <Input name="description" placeholder='Description' onChange={handleChange} />
        </Form.Field>
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Field>
          <label>Amount</label>
          <Input name="amount" placeholder='Amount' min={1} value={amount} type="number" onChange={handleNumberChange} />
        </Form.Field>
        <Form.Field >
          <label>Credit Load Type</label>
          <Form.Dropdown
            placeholder='Credit Load Type'
            fluid
            selection
            options={creditLoadTypeList}
            onChange={handleChange}
            name="creditLoadTypeId"
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Field >
          <label>Load Period Type</label>
          <Form.Dropdown
            placeholder='Load Period Type'
            fluid
            selection
            options={creditLoadPeriodTypeList}
            onChange={handleChange}
            name="loadPeriod"
          />
        </Form.Field>
        <Form.Field >
          <label>Has Expired</label>
          <Checkbox checked={hasExpired} onClick={() => setLoadData(prev => ({ ...prev, hasExpired: !hasExpired }))} />
        </Form.Field>
      </Form.Group>
      <div style={{ float: 'right' }}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={pendingApiCall} loading={pendingApiCall}>Load Credit</Button>
      </div>
    </Form>
  );
};

export default LoadCreditForm;