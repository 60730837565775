import * as actions from '../consts';
import { defaultTestRunVariationError } from '../reducers/TestRunReducer';

export const updateTestRunVariation = (name, value) => (dispatch) => {
  dispatch({
    type: actions.UPDATE_TEST_RUN_VARIATION,
    data: { name, value }
  });
  dispatch(validateTestRunVariation());
};

export const updateTestSuiteVariation = (testSuite) => (dispatch) => {
  const { noOfTotalUsers, testDurationInSeconds, id } = testSuite;
  const suiteVariation = { noOfTotalUsers, testDurationInSeconds, testSuiteId: id };
  dispatch({
    type: actions.UPDATE_TEST_SUITE_VARIATION,
    suiteVariation
  });
  dispatch(validateTestRunVariation());
};

export const resetTestRunVariation = () => ({
  type: actions.RESET_TEST_RUN_VARIATION
});

export const addScenarioVariation = (scenario) => (dispatch) => {
  const { id: testScenarioId, jmxResourceId, csvResourceIds, noOfLoops, rampUpTimeInSec, noOfMaxUsers, weight, variables, domains } = scenario;
  const scenarioVariation = { testScenarioId, jmxResourceId, csvResourceIds, noOfLoops, rampUpTimeInSec, noOfMaxUsers, weight, variables, domains };
  dispatch({
    type: actions.ADD_TEST_SCENARIO_VARIATION,
    scenarioVariation
  });
  dispatch(validateTestRunVariation());
};

export const removeScenarioVariation = (scenarioId) => (dispatch) => {
  dispatch({
    type: actions.REMOVE_TEST_SCENARIO_VARIATION,
    scenarioId
  });
  dispatch(validateTestRunVariation());
};


const validateTestRunVariation = () => (dispatch, getState) => {
  let testRunButtonEnabled = true;

  const testRunVariation = getState().TestRunReducer.testRunVariation;
  let testRunVariationError = { ...defaultTestRunVariationError };

  if (testRunVariation.vmInfraRunIds.length === 0) {
    testRunButtonEnabled = false;
    testRunVariationError['vmInfraRunIds'] = 'No cloud resource selected';
  }

  if (!testRunVariation.testToolId) {
    testRunButtonEnabled = false;
    testRunVariationError['testToolId'] = 'Jmeter version not selected';
  }

  if (testRunVariation.testSuiteVariation.scenarioVariations.length) {
    const totalWeight = testRunVariation.testSuiteVariation.scenarioVariations.map(scenario => scenario.weight)
      .reduce((prev, next) => prev + next, 0);
    if (totalWeight !== 100) {
      testRunButtonEnabled = false;
      testRunVariationError['scenario'] = 'Total weight must be 100';
    }
    testRunVariation.testSuiteVariation.scenarioVariations.map((scenario) => {
      if (!scenario.jmxResourceId || scenario.jmxResourceId === 0) {
        testRunButtonEnabled = false;
        testRunVariationError['scenarioVariations'] = 'There is no jmx file attached to scenario(s).'
      }
    });
  } else {
    testRunButtonEnabled = false;
    testRunVariationError['scenario'] = 'No scenario selected';
  }

  dispatch({
    type: actions.TEST_RUN_VALIDATION,
    data: {
      testRunButtonEnabled,
      testRunVariationError
    }
  })
};