import React, { useState, useEffect } from 'react';
import { Grid, Segment, Form, Input, Button, Header, Divider, Message } from 'semantic-ui-react';
import { changePassword, changePasswordUser } from '../../../api/apiCalls';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const PasswordChange = (props) => {

  const { userId } = props;

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState();
  const [inProgress, setInProgress] = useState(false);
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

  const [formErrors, setFormErrors] = useState({
    oldPassErr: null,
    repeatPassErr: null
  });

  const loggedUser = useSelector(store => store.LoginReducer.user);
  const { userRole } = useSelector(store => store.LoginReducer);

  const isOwnPassword = loggedUser && parseInt(userId, 10) === loggedUser.id;

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;
    if (newPassword !== newPasswordRepeat) {
      setFormErrors(prev => ({ ...prev, repeatPassErr: "Passwords don't match" }));
      hasError = true;
    }
    if (isOwnPassword) {
      if (!oldPassword) {
        hasError = true;
        setFormErrors(prev => ({ ...prev, oldPassErr: "This field is required" }));
      }
    }

    if (!hasError) {
      setError();
      setInProgress(true);
      const body = { newPassword };
      if (isOwnPassword) {
        body['oldPassword'] = oldPassword;
      }
      try {
        if (userRole) {
          await changePassword(userId, body);
        } else {
          await changePasswordUser(body);
        }
        toast.success('Password successfully changed');
      } catch (err) {
        toast.error(err.response && err.response.data.error.message);
      }
      setInProgress(false);
      setNewPassword('');
      setNewPasswordRepeat('');
      setOldPassword('');
    }
  };

  useEffect(() => {
    setError();
    setFormErrors({
      oldPassErr: null,
      repeatPassErr: null
    });
  }, [oldPassword, newPassword, newPasswordRepeat]);

  const { repeatPassErr, oldPassErr } = formErrors;

  return (
    <Grid.Row>
      <Grid.Column>
        <Segment className="shadow">
          <Header>Change Password</Header>
          <Form onSubmit={handleSubmit}>
            {isOwnPassword &&
              <Form.Field error={oldPassErr} required>
                <label>Old Password</label>
                <Input type="password" onChange={(e) => setOldPassword(e.target.value)} placeholder='Old Password' value={oldPassword} />
                {oldPassErr && <label color='red'>{oldPassErr}</label>}
              </Form.Field>
            }

            <Form.Field required>
              <label>New Password</label>
              <Input type="password" onChange={(e) => setNewPassword(e.target.value)} placeholder='New Password' value={newPassword} />
            </Form.Field>

            <Form.Field error={repeatPassErr} required>
              <label>Confirm Password</label>
              <Input type="password" onChange={(e) => setNewPasswordRepeat(e.target.value)} placeholder='Confirm Password' value={newPasswordRepeat} />
              {repeatPassErr && <label color='red'>{repeatPassErr}</label>}
            </Form.Field>

            <Divider />
            {error &&
              <Message negative>
                <Message.Header>{error.message}</Message.Header>
                <p>{error.details}</p>
              </Message>
            }
            <Button disabled={inProgress || !newPassword} loading={inProgress} fluid size="large" type="submit" primary >
              Change Password
            </Button>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid.Row >
  );
};

export default PasswordChange;