import React, { useEffect, useState } from 'react';
import { Grid, Icon, Header, Table, Button, Form } from 'semantic-ui-react';
import { getTenant, getExpenseDetail } from '../../api/apiCalls';
import LoadDetails from './components/LoadDetails';

const TenantDetailsPage = (props) => {

  const tenantId = props.match.params.id;

  const [tenant, setTenant] = useState({});

  useEffect(() => {
    fetchTenant();
  }, []);

  const fetchTenant = async () => {
    if (tenantId) {
      try {
        const response = await getTenant(tenantId);
        setTenant(response.data.result);
      } catch (err) { }
    }
  };

  const { name, tenancyName, isActive } = tenant;

  return (
    <Grid>
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name='building' />
              </div>
              <Header.Content>
                Tenant: {name}
                <Header.Subheader>Manage tenant credit and more</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div className="page-actions">
            <Button onClick={() => props.history.push(`/edit-tenant/${tenantId}`)}>
              Edit Tenant
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className='page-content'>
        <Grid.Column>
          <div className='segment-shadow'>
            <Table basic='very' celled >
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={4}>
                    <Header as='h4' image>
                      <Header.Content>
                        Name
                        <Header.Subheader>{name}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell width={4}>
                    <Header as='h4' image>
                      <Header.Content>
                        Tenancy Name
                        <Header.Subheader>{tenancyName}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell width={4}>
                    <Header as='h4' image>
                      <Header.Content>
                        Tenant Status
                        <Header.Subheader>{isActive ? 'Active' : 'Inactive'}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                </Table.Row>

              </Table.Body>
            </Table>

            <LoadDetails tenantId={tenantId} />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default TenantDetailsPage;