import React from 'react';
import Plot from 'react-plotly.js';
import useDimensions from "react-use-dimensions";

const PlotlyGraph = ({ data, start, end, timeSpanStart, timeSpanEnd, updateTimeSpanStartAndEnd, isResponseChart, time }) => {

  const virtualUsersByInterval = data ? data.virtualUsersByInterval : [];
  const requestsByInterval = data ? data.requestsByInterval : [];
  const meanResponsesByInterval = data ? data.meanResponsesByInterval : [];
  const medianResponsesByInterval = data ? data.medianResponsesByInterval : [];
  const maxResponsesByInterval = data ? data.maxResponsesByInterval : [];

  const [ref, { width }] = useDimensions();

  const handlePanOrZoomAction = (e) => {
    if (e['xaxis.autorange']) {
      updateTimeSpanStartAndEnd(-1, -1);
    } else {
      const startTime = new Date(e['xaxis.range[0]']);
      const endTime = new Date(e['xaxis.range[1]']);
      if ((startTime < timeSpanStart && startTime >= start)
        || (endTime > timeSpanEnd && endTime <= end)) {
        updateTimeSpanStartAndEnd(startTime.valueOf(), endTime.valueOf());
      }
    }
  };

  const leftAxisConfig = {
    mode: "lines+markers",
    x: time,
    type: "scatter",
    fill: 'tozeroy',
    fillcolor: 'rgba(122, 154, 131,0.035)',
    hoveron: 'points+fills',
    line: {
      color: 'rgba(122, 154, 131,0.7)'
    },
    marker: {
      color: 'rgba(122, 154, 131,0.7)'
    }
  };

  const rightAxisConfig = {
    mode: "lines+markers",
    x: time,
    type: "scatter",
    fill: 'tozeroy',
    yaxis: "y2",
    fillcolor: 'rgba(105, 112, 213,0.04)',
    hoveron: 'points+fills',
    line: {
      color: 'rgba(105, 112, 213,0.7)'
    },
    marker: {
      color: 'rgba(105, 112, 213,0.7)'
    }
  };

  const requestsByIntervalConfig = {
    name: "Throughput",
    y: requestsByInterval,
    ...rightAxisConfig
  };

  const virtualUsersByIntervalConfig = {
    name: "Virtual Users",
    y: virtualUsersByInterval,
    ...leftAxisConfig
  };

  const meanResponsesByIntervalConfig = {
    name: "Mean Response Times",
    y: meanResponsesByInterval,
    ...leftAxisConfig
  };

  const maxResponsesByIntervalConfig = {
    name: "Max Response Times",
    y: maxResponsesByInterval,
    ...rightAxisConfig
  };

  const medianResponsesByIntervalConfig = {
    type: "scatter",
    mode: "lines+markers",
    name: "Median Response Times",
    x: time,
    y: medianResponsesByInterval,
    yaxis: "y3",
    fill: 'tozeroy',
    fillcolor: 'rgba(60,129,121,0.02)',
    hoveron: 'points+fills',
    line: {
      color: 'rgba(60,129,121,1)'
    },
    marker: {
      color: 'rgba(60,129,121,1)'
    }
  };

  // const responseTimeMax = maxResponsesByInterval.length ? Math.max(Math.max(...maxResponsesByInterval), Math.max(...meanResponsesByInterval)) + 1 : 10;
  // const hitChartMax = virtualUsersByInterval.length ? Math.max(Math.max(...virtualUsersByInterval), Math.max(...requestsByInterval)) + 1 : 10;

  const lineChartLayout = {
    width: width,
    height: 550,
    dragmode: 'pan',
    hovermode: 'x unified',
    xaxis: {
      autorange: true,
      type: 'date',
      tickmode: 'auto',
      tickangle: -45,
      nticks: 50,
      fixedrange: 'true',
      range: [start, end],
      rangeslider: {
        range: [start, end],
        thickness: 0.1,
        yaxis: {
          fixedrange: true
        }
      },
      tickformatstops: [
        {
          "dtickrange": [null, 1000],
          "value": "%H:%M:%S.%L"
        },
        {
          "dtickrange": [1000, 60000],
          "value": "%H:%M:%S"
        },
        {
          "dtickrange": [60000, 360000],
          "value": "%H:%M"
        },
        {
          "dtickrange": [3600000, 8640000],
          "value": "%H:%M h"
        }
      ],
    },
    yaxis: {
      title: isResponseChart ? 'Mean (ms)' : 'Virtual Users',
      titlefont: {color: '#1f77b4'},
      tickfont: {color: '#1f77b4'},
      fixedrange: true
    },
    yaxis2: {
      title: isResponseChart ? 'Max (ms)' : 'Throughput',
      overlaying: 'y',
      side: 'right',
      titlefont: {color: '#1f77b4'},
      tickfont: {color: '#1f77b4'},
      fixedrange: true,
    },
    yaxis3: {
      title: 'Median (ms)',
      overlaying: 'y',
      titlefont: {color: '#1f77b4'},
      tickfont: {color: '#1f77b4'},
      fixedrange: true,
      side: 'right',
      position: 0.96
    },
    legend: {
      orientation: 'h',
      x: 0.5,
      y: 1.1,
      xanchor: 'center'
    },
  };

  const chartData = !isResponseChart ? [requestsByIntervalConfig, virtualUsersByIntervalConfig] :
  [meanResponsesByIntervalConfig, maxResponsesByIntervalConfig, medianResponsesByIntervalConfig];


  return (
    <div ref={ref} style={{ marginTop: '20px' }}>
      <Plot data={chartData}
        config={{
          showLink: false, showSources: false, displaylogo: false,
          modeBarButtonsToRemove: ['lasso2d', 'autoScale2d', 'resetScale2d', 'toggleSpikelines', 'select2d', 'hoverClosestCartesian', 'hoverCompareCartesian']
        }}
        layout={lineChartLayout}
        onRelayout={handlePanOrZoomAction}
        onSliderChange={handlePanOrZoomAction}
      />
    </div>
  );
};

export default PlotlyGraph;