import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, TextArea, Message } from 'semantic-ui-react';

const EditInformationModal = (props) => {

  const [newData, setNewData] = useState({
    name: '', description: ''
  });
  const [error, setError] = useState();

  const { open, handleClose, saveFunction, data, txt, inProgress } = props;

  useEffect(() => {
    setNewData(data);
  }, [data]);

  useEffect(() => {
    setError();
  }, [newData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError();
    try {
      await saveFunction(newData);
      handleClose();
    } catch (err) {
      setError(err.response && err.response.data.error);
    }
  };

  const handleChange = (e, { name, value }) => {
    setNewData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const { name, description } = newData;

  return (
    <Modal size="small" open={open} onClose={handleClose}>
      <Modal.Header>Edit {txt} Informations</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>{txt} Name</label>
            <Input name="name" onChange={handleChange} placeholder={`${txt} Name`} defaultValue={name} />
          </Form.Field>

          <Form.Field>
            <label>Description</label>
            <TextArea name='description' onChange={handleChange} placeholder='Tell us more' value={description} />
          </Form.Field>
        </Form>
        {error &&
          <Message negative>
            <Message.Header>{error.message}</Message.Header>
            <p>{error.details}</p>
          </Message>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={handleClose}>Cancel</Button>
        <Button
          positive
          icon='checkmark'
          labelPosition='right'
          content='Update'
          onClick={handleSubmit}
          disabled={inProgress}
          loading={inProgress}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EditInformationModal;