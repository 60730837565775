import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal, Checkbox } from 'semantic-ui-react';

import * as stripAnsi from 'strip-ansi';
import { UnControlled as CodeMirror } from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/shell/shell';
import 'codemirror/mode/yaml/yaml';
import 'codemirror/mode/javascript/javascript';
import './VmInfraRunLog.css';

const signalR = require("@aspnet/signalr");

const ScrollYMax = 5000000;

const codeMirrorOptions = {
  lineNumbers: true,
};

const VmInfraRunLogModal = (props) => {

  const [logActual, setLogActual] = useState('');
  const [logPrinted, setLogPrinted] = useState('');
  const [followLogs, setFollowLogs] = useState(true);
  const [scrollY, setScrollY] = useState(ScrollYMax);
  const [lastTop, setLastTop] = useState(0);
  const [inProgress, setInProgress] = useState(false);

  const { vmInfraRunId, visible, handleClose } = props;

  useEffect(() => {
    if (visible) {
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl("/signalr/vm-infra-run")
        .configureLogging(signalR.LogLevel.Debug)
        .build();
      setInProgress(true);

      hubConnection.start().then(
        () => {
          setInProgress(false);
          sendSetupMessage(hubConnection);
        },
        (error) => {
          console.error('Error while establishing connection :(', error);
          setInProgress(false);
        }
      );

      return function unmount() {
        hubConnection.stop();
        setLogActual('');
        setLogActual('');
        setScrollY(ScrollYMax);
        setLastTop(0);
        setFollowLogs(true);
      };
    }
  }, [visible]);

  useEffect(() => {
    setLogPrinted(logActual);
  }, [logActual]);

  useEffect(() => {
    if (followLogs) {
      setScrollY(ScrollYMax);
    } else {
      setScrollY(lastTop);
    }
  }, [lastTop, followLogs]);

  const sendSetupMessage = (hubConnection) => {
    const stream = hubConnection.stream('SetupReceiveLogs', vmInfraRunId);

    stream.subscribe({
      next(newLog) {
        let formattedLogs = formatLogArray(newLog);
        setLogActual(prev => prev + '\n' + formattedLogs);
      },
    });
  };

  const formatLogArray = (newLog) => {
    return newLog.map((x) => moment(x.d).format('YYYY-MM-DD HH:mm:ss') + ' | ' + x.l).join('\n');
  };

  const onScroll = (editor, data) => {
    setLastTop(data.top);
  };

  return (
    <Modal open={visible} onClose={handleClose} size="fullscreen" style={{ marginLeft: '2vw', marginRight: '2vw' }} closeIcon>
      <Modal.Content>
        <Checkbox style={{ marginBottom: 22 }} defaultChecked={followLogs} onChange={() => setFollowLogs(prev => !prev)} toggle label={<label>Follow Logs</label>} />
        <CodeMirror
          value={stripAnsi(logPrinted)}
          scroll={{
            x: 0,
            y: scrollY, // hopefully we will not reach that many lines, just want to go to the end
          }}
          onScroll={onScroll}
          options={codeMirrorOptions}
        />
      </Modal.Content>
    </Modal>
  );
};

export default VmInfraRunLogModal;