import React, { useCallback } from 'react';
import { Message, Icon } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';

const UploadComponent = (props) => {

  const { uploadFunction, accept, loading, uploadProgress, multiple } = props;

  const onDrop = useCallback((acceptedFiles) => {
    uploadFunction(acceptedFiles);

  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: multiple || false, accept });


  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps({ disabled: loading })} />
        <Message icon size="tiny">
          <Icon name={loading ? 'circle notched' : 'inbox'} loading={loading} />
          <Message.Content>
            <Message.Header>{loading ? `Loading %${uploadProgress}` : 'Upload file'}</Message.Header>
          </Message.Content>
        </Message>
      </div>
    </section>
  );
};

export default UploadComponent;