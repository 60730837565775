import React, { useState, useEffect } from 'react';
import { Card, Label, Table, Button, Popup, Dropdown, Loader, Icon } from 'semantic-ui-react';
import { CloudProviderTypes, VmInfraStatusMap } from '../../cloud-accounts/consts';
import ClusterEditModal from './ClusterEditModal';
import { useDispatch, useSelector } from 'react-redux';
import { getLastRun, getCapacity, createVmInfra, updateVmInfra, destroyVmInfra, deleteCluster, refreshKeys, getPublicKey } from '../../../api/apiCalls';
import { openSecretModal } from '../../../redux/actions/CloudActions';
import VmInfraRunLogModal from './VmInfraRunLogModal';
import VmInfraInfoModal from './VmInfraInfoModal';
import { openConfirmModal, closeConfirmModal } from '../../../redux/actions/ModalActions';
import { fetchClusterListAgain } from '../../../redux/actions/CloudActions';
import { getIconName } from '../../test-run/components/TestRunSideMenu';
import { base64ToArrayBuffer, saveByteArray } from '../../../shared/Utils';
import { toast } from 'react-toastify';

const signalR = require("@aspnet/signalr");

const ClusterCard = (props) => {

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [hubCon, setHubCon] = useState();
  const [capacity, setCapacity] = useState();
  const [lastRun, setLastRun] = useState(null);
  const [statusFromWs, setStatusFromWs] = useState();
  const [logModalVisible, setLogModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const { data } = props;
  const { id, name, cloudAccount, workerVmCount, workerVmTypeId } = data;
  const { providerId, name: accountName } = cloudAccount;
  const cloudInfo = useSelector(store => store.CloudReducer.cloudInventories.find(provider => provider.cloudProvider.id === providerId));
  const { cloudProvider } = cloudInfo || {};
  const { computeInstances } = cloudInfo || [];

  let infraStatus;
  if (statusFromWs) {
    infraStatus = VmInfraStatusMap[statusFromWs];
  } else if (lastRun && lastRun.vmInfraStatus) {
    infraStatus = VmInfraStatusMap[lastRun.vmInfraStatus];
  } else {
    infraStatus = VmInfraStatusMap[0];
  }
  const { status, style, infraReady, playDisabled, stopDisabled, refreshDisabled, operationEnded } = infraStatus;

  useEffect(() => {

    const getCapacityInfo = async (vmRunId) => {
      try {
        const response = await getCapacity(vmRunId);
        setCapacity(response.data.result.noOfAvailInstances);
      } catch (err) { }
    };

    const fetchLastRun = async () => {
      try {
        const response = await getLastRun(id);
        setLastRun(response.data.result);
        if (VmInfraStatusMap[response.data.result.vmInfraStatus].infraReady) {
          getCapacityInfo(response.data.result.vmInfraRunId);
        }
      } catch (err) { }
    };

    fetchLastRun();

  }, [data]);

  useEffect(() => {

    const conWsAndGetStatus = async (vmInfraRunId) => {
      if (hubCon) {
        await hubCon.stop();
      }
      openSignalRConnForVmInfraRun(vmInfraRunId);
    };

    if (lastRun) {
      conWsAndGetStatus(lastRun.vmInfraRunId);
    }
  }, [lastRun]);

  useEffect(() => {
    return function unmount() {
      if (hubCon) {
        hubCon.stop();
      }
    };
  }, [hubCon]);

  const vmSlug = (type) => {
    const computeInstance = computeInstances.find(instance => instance.id === workerVmTypeId) || {};
    if (providerId === CloudProviderTypes.ONPREM) {
      return <Label color="red">N/A</Label>
    }
    if (type === 'vmSlug') {
      return < >{computeInstance.friendlyName}</>
    } else if (type === 'vmType') {
      return (
        <>
          <>{computeInstance.core} Core / </>
          <>{computeInstance.ram} GB</>
        </>
      );
    }
  }

  const dispatch = useDispatch();

  const handleCreateBtn = () => {
    if (providerId !== CloudProviderTypes.ONPREM) {
      dispatch(openSecretModal(createVmInfraRun, 'Create Infrastructure', 'Confirm Creating Infrastructure'));
    } else {
      createVmInfraRun();
    }
  };

  const handleUpdateBtn = () => {
    if (providerId !== CloudProviderTypes.ONPREM) {
      dispatch(openSecretModal(updateVmInfraRun, 'Update Infrastructure', 'Confirm Updating Infrastructure'));
    } else {
      createVmInfraRun();
    }
  };

  const handleStopBtn = () => {
    if (providerId !== CloudProviderTypes.ONPREM) {
      const destroyWithIp = providerId === CloudProviderTypes.AZURE ? true : false;
      dispatch(openSecretModal(destroyVmInfraRun, 'Destroy Infrastructure', 'Confirm Destroying Infrastructure', destroyWithIp));
    } else {
      destroyVmInfraRun();
    }
  };

  const createVmInfraRun = async (theClientSecret = null) => {
    const bdy = { vmInfraDetailId: id, theClientSecret };
    try {
      const response = await createVmInfra(bdy);
      setLastRun(prevState => ({
        ...prevState,
        vmInfraRunId: response.data.result.id
      }));
    } catch (err) { }
  };

  const destroyVmInfraRun = async (theClientSecret = null, destroyWithIp = false) => {
    const bdy = { theClientSecret, destroyWithIp };
    try {
      const response = await destroyVmInfra(lastRun.vmInfraRunId, bdy);
      setLastRun(prevState => ({
        ...prevState,
        vmInfraRunId: response.data.result.id
      }));
    } catch (err) { }
  };


  const updateVmInfraRun = async (theClientSecret = null) => {
    const bdy = { theClientSecret };
    try {
      const response = await updateVmInfra(lastRun.vmInfraRunId, bdy);
      setLastRun(prevState => ({
        ...prevState,
        vmInfraRunId: response.data.result.id
      }));
    } catch (err) { }
  };

  const openSignalRConnForVmInfraRun = (vmInfraRunId) => {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/signalr/vm-infra-run")
      .configureLogging(signalR.LogLevel.Debug)
      .build();
    setHubCon(hubConnection);
    startSignalRConnForVmInfraRun(vmInfraRunId, hubConnection);
  };

  const startSignalRConnForVmInfraRun = async (vmInfraRunId, hubConnection) => {
    hubConnection.start()
      .then(
        () => {
          setupSignalRForVmInfraRun(vmInfraRunId, hubConnection);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const setupSignalRForVmInfraRun = (vmInfraRunId, hubConnection) => {
    const stream = hubConnection.stream('SetupReceiveStatus', vmInfraRunId);

    stream.subscribe({
      next(newStatus) {
        setStatusFromWs(newStatus);
      }
    });
  };

  const handleDeleteButton = () => {
    dispatch(openConfirmModal(deleteInfra, "Delete Cluster", "Are you sure you want to the cluster?"));
  };

  const deleteInfra = async () => {
    try {
      await deleteCluster(id);
      dispatch(fetchClusterListAgain(true));
    } catch (err) { }
    dispatch(closeConfirmModal());
  };

  const handleRefreshKey = async () => {

    if (!lastRun) {
      toast.error('Keys renewal failed.');
    } else {
      const status = lastRun.vmInfraStatus;
      if(status === 0 && status === 3 && status === 14) {
        toast.error('Keys renewal failed. Please check the status of the cluster');
      } else {
        try {
          const response = await refreshKeys(id);
          toast.error(response.data.result);
        } catch (err) {
          toast.error(err.response ? err.response.data.error.message : 'Keys renewal Failed');
        }
      }
    }
  };

  const handleDownloadPublicKey = async () => {
    try {
      const response = await getPublicKey(id);
      const arr = base64ToArrayBuffer(response.data.result);
      saveByteArray(`id_rsa.pub`, arr, "text/plain");
    } catch (err) {
      toast.error(err.response ? err.response.data.error.message : 'Failed');
    }
  };

  return (
    <>
      <Table.Row>
        <Table.Cell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Icon {...getIconName(providerId)} size='big' />
            </div>
            <div>
              <strong>{name}</strong>
              <br />
              Account Name: {accountName}
            </div>
          </div>
        </Table.Cell>

        <Table.Cell textAlign='center'>
          <div>
            <Label color={style} size='tiny' >
              {status}
            </Label>
            <div style={{ float: 'right', textAlign: 'right' }}>
              <Loader inline size="medium" active={!operationEnded} />
            </div>
          </div>
        </Table.Cell>


        <Table.Cell textAlign='center'>
          {cloudProvider.name}
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {workerVmCount}
        </Table.Cell>

        <Table.Cell textAlign='center'>
          {vmSlug('vmSlug')}
        </Table.Cell>

        <Table.Cell textAlign='center'>
          {vmSlug('vmType')}
        </Table.Cell>

        <Table.Cell textAlign='center'>
          <>{capacity || 'N/A'}</>
        </Table.Cell>

        <Table.Cell textAlign='center' width={1} >
          <Button.Group size='tiny' >

            <Dropdown className='icon'
              labeled
              button
              text='Actions'
              icon='ellipsis horizontal'
              iconPosition='right'
            >
              <Dropdown.Menu direction='left'>
                <Dropdown.Item icon='edit' text='Edit Cluster' onClick={() => setEditModalVisible(true)} />
                <Dropdown.Item icon='trash' text='Delete Cluster' onClick={handleDeleteButton} disabled={infraReady} />
                <Dropdown.Item disabled={playDisabled} icon='play' text='Create Infrastructure' onClick={handleCreateBtn} />
                <Dropdown.Item disabled={refreshDisabled} icon='refresh' text='Update Infrastructure' onClick={handleUpdateBtn} />
                <Dropdown.Item disabled={stopDisabled} icon='stop' text='Destroy Infrastructure' onClick={handleStopBtn} />
                <Dropdown.Item icon='search' text='Show Logs' onClick={() => setLogModalVisible(true)} />
                <Dropdown.Item disabled={!lastRun} icon='info' text='More Details About VM' onClick={() => setInfoModalVisible(true)} />
                {providerId === CloudProviderTypes.ONPREM &&
                  <>
                    <Dropdown.Item icon='download' text='Download Public Keys' onClick={handleDownloadPublicKey} />
                    <Dropdown.Item icon='refresh' text='Refresh Keys' onClick={handleRefreshKey} />
                  </>
                }
              </Dropdown.Menu>
            </Dropdown>
          </Button.Group>
        </Table.Cell>

      </Table.Row>

      <ClusterEditModal cluster={data} visible={editModalVisible} handleClose={() => setEditModalVisible(false)} />
      <VmInfraRunLogModal visible={logModalVisible} vmInfraRunId={lastRun && lastRun.vmInfraRunId} handleClose={() => setLogModalVisible(false)} />
      <VmInfraInfoModal visible={infoModalVisible} vmInfraRunId={lastRun && lastRun.vmInfraRunId} handleClose={() => setInfoModalVisible(false)} />
      {/* <Grid.Column >
        <Card fluid>
          <Label attached='top' size="big">{name}
            <span style={{ float: "right" }} >
              <Dropdown>
                <Dropdown.Menu direction='left'>
                  <Dropdown.Item icon='edit' text='Edit Cluster' onClick={() => setEditModalVisible(true)} />
                  <Dropdown.Item icon='trash' text='Delete Cluster' onClick={handleDeleteButton} disabled={infraReady} />
                  <Dropdown.Item disabled={playDisabled} icon='play' text='Create Infrastructure' onClick={handleCreateBtn} />
                  <Dropdown.Item disabled={refreshDisabled} icon='refresh' text='Update Infrastructure' onClick={handleUpdateBtn} />
                  <Dropdown.Item disabled={stopDisabled} icon='stop' text='Destroy Infrastructure' onClick={handleStopBtn} />
                  <Dropdown.Item icon='search' text='Show Logs' onClick={() => setLogModalVisible(true)} />
                  <Dropdown.Item disabled={!lastRun} icon='info' text='More Details About VM' onClick={() => setInfoModalVisible(true)} />

                </Dropdown.Menu>
              </Dropdown>
            </span>
          </Label>
          <Card.Content>
            <Card.Description>
              <div style={{ marginTop: 8 }} >
                <Label color={style} size="large" ribbon='left' >
                  {status}
                </Label>
                <div style={{ float: 'right', textAlign: 'right' }}>
                  <Loader inline size="medium" active={!operationEnded} />
                </div>
              </div>
              <Table basic='very'>
                <Table.Body>
                  <BasicTableRow name="Account" value={accountName} />
                  <BasicTableRow name="Cloud Type" value={cloudProvider.name} />
                  <BasicTableRow name="# of VMs" value={workerVmCount} />
                  <Table.Row>
                    <Table.Cell><b>VM Slug</b></Table.Cell>
                    <Table.Cell>{vmSlug('vmSlug')}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell><b>VM Types</b></Table.Cell>
                    <Table.Cell>{vmSlug('vmType')}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell><b># of Inst.</b></Table.Cell>
                    <Table.Cell><Label color="brown">{capacity || 'N/A'}</Label></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card.Description>
          </Card.Content>
          <Button.Group attached="bottom">
        <Popup content='Create Infrastructure' trigger={<Button disabled={playDisabled} onClick={handleCreateBtn} icon='play' />} />
        <Popup content='Update Infrastructure' trigger={<Button disabled={refreshDisabled} onClick={handleUpdateBtn} icon='refresh' />} />
        <Popup content='Destroy Infrastructure' trigger={<Button disabled={stopDisabled} onClick={handleStopBtn} icon='stop' />} onClick={handleStopBtn} />
        <Popup content='Show Logs' trigger={<Button icon='search' onClick={() => setLogModalVisible(true)} />} />
        <Popup content='Vm Info' trigger={<Button disabled={!lastRun} onClick={() => setInfoModalVisible(true)} icon='info' />} />
        <Popup content='Dowload Result(JTL) Files' trigger={<Button disabled={!lastRun} icon='download' />} />
      </Button.Group>
          <ClusterEditModal cluster={data} visible={editModalVisible} handleClose={() => setEditModalVisible(false)} />
          <VmInfraRunLogModal visible={logModalVisible} vmInfraRunId={lastRun && lastRun.vmInfraRunId} handleClose={() => setLogModalVisible(false)} />
          <VmInfraInfoModal visible={infoModalVisible} vmInfraRunId={lastRun && lastRun.vmInfraRunId} handleClose={() => setInfoModalVisible(false)} />
        </Card>
      </Grid.Column> */}

    </>
  );
};


export const BasicTableRow = ({ name, value }) => {
  return (
    <Table.Row>
      <Table.Cell><b>{name}</b></Table.Cell>
      <Table.Cell>{value}</Table.Cell>
    </Table.Row>
  );
};

export default ClusterCard;