import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { fetchLoadReport } from '../../../api/apiCalls';
import { Table, Header, Icon } from 'semantic-ui-react';
import { creditLoadTypeList } from '../../../admin/tenants/components/LoadCreditForm';
import NoDataFound from '../../../components/NoDataFound';
import LoadingComponent from '../../../components/LoadingComponent';
import _ from 'lodash';

const LoadListComponent = ({ startDate, endDate, tenantId }) => {

  const [creditLoads, setCreditLoads] = useState({});
  const [pendingApiCall, setPendingApiCall] = useState(false);

  useEffect(() => {
    fetchLoadHistory();
  }, [startDate, endDate, tenantId]);


  const fetchLoadHistory = async () => {
    const start = moment(startDate).format('MM/DD/YYYY HH:mm:ss Z');
    const end = moment(endDate.setHours(23, 59, 59, 0)).format('MM/DD/YYYY HH:mm:ss Z');
    if (tenantId) {
      setPendingApiCall(true);
      try {
        const response = await fetchLoadReport(tenantId, start, end);
        let sortedResult = _.orderBy(response.data.result.details, ['creationTime'], ['desc']);
        setCreditLoads({ ...response.data.result, details: sortedResult });
      } catch (err) { }
      setPendingApiCall(false);
    }
  };

  const loadList = creditLoads.details || [];
  const { sum } = creditLoads;

  return (
    <div>
      <Header as='h3'>
        <Icon name='add' />
        <Header.Content>
          Credit Loads
          <Header.Subheader>Total load: {sum || 'N/A'} </Header.Subheader>
        </Header.Content>
      </Header>
      {pendingApiCall ?
        <LoadingComponent msg="Loading" />
        :
        <>
          {!loadList.length ?
            <NoDataFound msg="There is no loan here!" />
            :
            <>
              <Table basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Load Type</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Load Time</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Amount</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Has Expired</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Description</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {loadList.map(load => (
                    <Table.Row>
                      <Table.Cell>{load.creditLoadTypeId ? creditLoadTypeList.find(item => item.key === load.creditLoadTypeId).text : 'Other'}</Table.Cell>
                      <Table.Cell textAlign="center">{moment(load.creationTime).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                      <Table.Cell textAlign="center">{load.amount}</Table.Cell>
                      <Table.Cell textAlign="center">{load.hasExpired ? 'Expired' : 'Not Expired'}</Table.Cell>
                      <Table.Cell textAlign="right">{load.description}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          }
        </>
      }
    </div>
  );
};

export default LoadListComponent;