import React, { useState, useEffect } from 'react';
import { fetchInfraDetails, getTestTools, getAllTestRuns, getLastRun } from '../../../api/apiCalls';
import { useApiProgress } from '../../../api/ApiProgressHook';
import { Dropdown, Form, Label, Icon, Header, List, Button, Input, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import NoDataFound from '../../../components/NoDataFound';
import moment from 'moment';
import { VmInfraStatusMap, CloudProviderNameArray, CloudProviderTypes } from '../../cloud-accounts/consts';
import { getCloudInventories } from '../../../redux/actions/CloudActions';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import VmInfraInfoModal from '../../cluster-manage/components/VmInfraInfoModal';
import PluginsDrawer from './PluginsDrawer';
import { updateTestRunVariation } from '../../../redux/actions/TestRunActions';
import { getPlugins } from '../../../redux/actions/ResourceActions';
import { Link } from 'react-router-dom';
import LoadingComponent from '../../../components/LoadingComponent';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';

export const getIconName = (providerId) => {
  switch (providerId) {
    case CloudProviderTypes.AZURE:
      return { name: 'microsoft', color: 'blue' };
    case CloudProviderTypes.AWS:
      return { name: 'aws', color: 'orange' };
    case CloudProviderTypes.GOOGLECLOUD:
      return { name: 'google', color: 'blue' };
    case CloudProviderTypes.DIGITALOCEAN:
      return { name: 'digital ocean', color: 'blue' };
    default:
      return { name: 'server', color: 'brown' };
  }
};

const networkTypeOptions = [
  { key: 0, text: 'Unlimited', value: 0 },
  { key: 1, text: 'Fast Ethernet: 100 Mbit/s', value: 12800000 },
  { key: 2, text: 'WIFI 802.11a/g: 54 Mbit/s', value: 6912000 },
  { key: 3, text: 'ADLS2+: 24 Mbit/s', value: 3072000 },
  { key: 4, text: 'Mobile data HSPA+: 21 Mbp/s', value: 2688000 },
  { key: 5, text: 'Mobile data HSPA: 14,4 Mbp/s', value: 1843200 },
  { key: 6, text: 'ADSL2: 12 Mbit/s', value: 1536000 },
  { key: 8, text: 'Ethernet LAN: 10 Mbit/s', value: 1280000 },
  { key: 7, text: 'ADSL: 8 Mbit/s', value: 1024000 },
  { key: 9, text: 'Mobile data EDGE: 384 kbit/s', value: 49152 },
  { key: 10, text: 'Mobile data GPRS: 171 kbit/s', value: 21888 },
  { key: 11, text: 'WAN modems v.92 modems: 56 kbit/s', value: 7168 }
];

const TestRunSideMenu = () => {

  const [vmInfraDetails, setVmInfraDetails] = useState([]);
  const [toolList, setToolList] = useState([]);
  const [testRunList, setTestRunList] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [pluginDrawerVisible, setPluginDrawerVisible] = useState(false);
  const [selectedLastRun, setSelectedLastRun] = useState();
  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const dispatch = useDispatch();
  const { push } = useHistory();

  const { pluginNames, testToolId, vmInfraRunIds, cpsProp } = useSelector(store => store.TestRunReducer.testRunVariation);
  const pluginList = useSelector(store => store.ResourceReducer.pluginList);

  useEffect(() => {

    const getDatas = async () => {
      await dispatch(getCloudInventories());
      dispatch(getPlugins());
      getInfraList();
      getToolList();
      getSuiteTestRuns();
    };

    getDatas();
  }, []);

  const computeInstances = useSelector(store => store.CloudReducer.cloudInventories.map(item => item.computeInstances).flat());

  const pendingApiCall = useApiProgress('get', '/api/TestRuns');

  const getInfraList = async () => {
    try {
      const response = await fetchInfraDetails();
      response.data.result.map(async vm => {
        const lastRun = await getInfraLastRun(vm.id);
        const newVm = {
          key: vm.id, text: vm.name, value: lastRun && lastRun.vmInfraRunId,
          infraReady: lastRun ? VmInfraStatusMap[lastRun.vmInfraStatus].infraReady : false,
          data: vm,
          lastRun,
          content: dropdownContent(vm, lastRun),
        };
        setVmInfraDetails(prev => [...prev, newVm]);
      });
    } catch (err) { }
  };

  const getInfraLastRun = async (id) => {
    try {
      const response = await getLastRun(id);
      return response.data.result;
    } catch (err) { return {}; }
  };

  const getToolList = async () => {
    try {
      const response = await getTestTools();
      setToolList(response.data.result.map(tool => ({ key: tool.id, text: tool.imageName.replace('gsengun/', ''), value: tool.id })));
    } catch (err) { }
  };

  const getSuiteTestRuns = async () => {
    try {
      const response = await getAllTestRuns(id);
      setTestRunList(_.orderBy(response.data.result, ['creationTime'], ['desc']));
    } catch (err) { }
  };

  const dropdownContent = (vm, lastRun) => {
    const style = VmInfraStatusMap[lastRun ? lastRun.vmInfraStatus : 0].style;
    const infraReady = lastRun ? VmInfraStatusMap[lastRun.vmInfraStatus].infraReady : false;
    return (
      <Header as='h6' disabled={!infraReady}>
        <Icon {...getIconName(vm.cloudAccount.providerId)} />
        <Header.Content>
          {vm.name}
          <Header.Subheader>
            <Label.Group size="mini">
              <Label color={infraReady ? 'green' : 'red'} style={{ marginLeft: 0 }} basic>{infraReady ? 'Ready' : 'Not Ready'}</Label>
              <Label style={{ marginLeft: 0 }} basic>{CloudProviderNameArray[vm.cloudAccount.providerId]}</Label>
              {vm.region && <Label style={{ marginLeft: 0 }} basic>{vm.region}</Label>}
              <Label color={style} style={{ marginLeft: 0 }} basic >
                Status: {VmInfraStatusMap[lastRun ? lastRun.vmInfraStatus : 0].status}
              </Label>
              <Label basic style={{ marginLeft: 0 }} >Worker Count: {vm.workerVmCount}</Label>
            </Label.Group>
          </Header.Subheader>
        </Header.Content>
      </Header >
    )
  };

  const handleCloudDropDownChange = (e, { value }) => {
    const newOne = _.xor(value, vmInfraRunIds);
    if (!vmInfraRunIds.includes(newOne)) {
      const details = vmInfraDetails.find(infra => infra.value === newOne[0]);
      if (details && details.infraReady) {
        dispatch(updateTestRunVariation('vmInfraRunIds', value));
      }
    } else {
      dispatch(updateTestRunVariation('vmInfraRunIds', value));
    }
  };

  const handleDropdownChange = (e, { name, value }) => {
    dispatch(updateTestRunVariation(name, value));
  };

  const cloudListItem = (vmId) => {
    const details = vmInfraDetails.find(infra => infra.value === vmId);
    return (
      <List.Item>
        <List.Content floated="right">
          <Button size="tiny" onClick={() => { setSelectedLastRun(details.lastRun.vmInfraRunId); setInfoModalVisible(true); }}>Info</Button>
        </List.Content>
        <List.Icon size="big" verticalAlign="middle" {...getIconName(details.data.cloudAccount.providerId)} />
        <List.Content>
          <List.Header as="a">
            {details.text}
          </List.Header>
          <List.Description>
            <Label.Group size="mini">
              <Label style={{ marginLeft: 0 }} basic>{CloudProviderNameArray[details.data.cloudAccount.providerId]}</Label>
              {details.data.region && <Label style={{ marginLeft: 0 }} basic>{details.data.region}</Label>}
              <Label basic style={{ marginLeft: 0 }} >Worker Count: {details.data.workerVmCount}</Label>
              {vmInfo(details.data.cloudAccount.providerId, details.data.workerVmTypeId)}
            </Label.Group>
          </List.Description>
        </List.Content>
      </List.Item>
    );
  };

  const vmInfo = (providerId, typeId) => {
    if (providerId !== CloudProviderTypes.ONPREM) {
      const computeInstance = computeInstances.find(instance => instance.id === typeId && instance.cloudProviderId === providerId) || {};
      return (
        <>
          <Label color="brown">{computeInstance.friendlyName}</Label>
          <Label color="red">{computeInstance.core} Core</Label>
          <Label color="brown">{computeInstance.ram} GB</Label>
        </>
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (!search || (search && search.length < 1)) {
        setSearchedList([]);
        setIsLoading(false);
      } else {
        const re = new RegExp(_.escapeRegExp(search), 'i');
        const isMatch = (result) => re.test(result.name);

        setIsLoading(false);
        setSearchedList(_.filter(testRunList, isMatch));
      }
    }, 300);
  }, [search]);

  const handleSearchChange = (e) => {
    setIsLoading(true);
    setSearch(e.target.value);
  };

  const testRuns = searchedList.length ? searchedList : testRunList;

  return (
    <>
      <div className="shadow" style={{ padding: 16 }}>
        <h3>Run Configuration</h3>
        <Form>
          <Form.Field>
            <label>Cloud Resource Selection: </label>
            <Dropdown
              options={vmInfraDetails}
              placeholder='Select Cloud Resource'
              selection
              multiple
              floating
              name="vmInfraRunIds"
              value={vmInfraRunIds}
              noResultsMessage="There is no cluster"
              onChange={handleCloudDropDownChange}
            />
          </Form.Field>
          <List divided verticalAlign="middle" relaxed >
            {vmInfraRunIds.map(vmId => cloudListItem(vmId))}
          </List>
          <Form.Field>
            <label>Jmeter version: </label>
            <Dropdown
              options={toolList}
              placeholder='Select jmeter version'
              selection
              floating
              clearable
              onChange={handleDropdownChange}
              value={testToolId}
              name="testToolId"
            />
          </Form.Field>
          <Form.Field>
            <label>Network Types: </label>
            <Dropdown
              options={networkTypeOptions}
              placeholder='Select network type'
              selection
              floating
              onChange={handleDropdownChange}
              value={cpsProp}
              name="cpsProp"
            />
          </Form.Field>
          <Form.Field>
            <label>Plugins: </label>
            <Label.Group>
              {pluginNames.map(plugin => (
                <Label color="blue"
                  onClick={() => dispatch(updateTestRunVariation('pluginNames', pluginNames.filter(item => item !== plugin)))}>
                  {pluginList.find(item => item.id === plugin).pluginName} <Icon name='delete' />
                </Label>
              ))}
              <Label icon onClick={() => setPluginDrawerVisible(true)}><Icon name="plus" /> Add</Label>
            </Label.Group>
          </Form.Field>
        </Form>
      </div>

      <div className="shadow" style={{ marginTop: 16 }} >
        <Segment basic clearing style={{ marginBottom: '-14px', marginRight: '-14px' }} >
          <Header as='h4' floated='right'>
            <Input loading={isLoading} size="mini" icon='search' placeholder='Search...' onChange={_.debounce(handleSearchChange, 500, { leading: true })} />
          </Header>
          <Header as='h3' floated='left'>
            Test Runs
            <List.Item onClick={getSuiteTestRuns} as={Link}> <Icon size="small" name="refresh" /></List.Item>
          </Header>
        </Segment>
        <div className="issue-list-container" style={{ maxHeight: '55vh', minHeight: '25vh' }}>
          {pendingApiCall ?
            <LoadingComponent msg="Loading..." />
            :
            <>
              {testRunList.length === 0 && <NoDataFound msg='There is no run here!' />}
              {testRuns.map(run =>
                <div className="issue-card" key={run.id} onClick={() => push(`/test-run/${run.id}/monitoring`)}>
                  <div className='title'>
                    <Highlighter
                      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                      searchWords={[search]}
                      autoEscape
                      textToHighlight={run.name}
                    />
                  </div>
                  <small className='testCycle'>{moment(run.creationTime).fromNow()}</small>
                  <div className='bottom'>
                    <Label icon size='mini' className='date'>
                      <Icon name="user" /> {run.testSuiteVariation && run.testSuiteVariation.noOfTotalUsers}
                    </Label>
                    <Label icon size='mini' className='date'>
                      <Icon name="time  " />  {run.testSuiteVariation && run.testSuiteVariation.testDurationInSeconds}
                    </Label>
                  </div>
                </div>
              )}
            </>
          }
        </div>
      </div>
      <VmInfraInfoModal visible={infoModalVisible} vmInfraRunId={selectedLastRun} handleClose={() => setInfoModalVisible(false)} />
      <PluginsDrawer visible={pluginDrawerVisible} handleClose={() => setPluginDrawerVisible(false)} showSelect={true} />
    </>
  );
};

export default TestRunSideMenu;