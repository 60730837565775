import React from 'react';
import { Form, Grid, Segment, Image } from 'semantic-ui-react';
import netasLogo from "../assets/netaslogo.png";
import logo from "../assets/visiumLoad.png";

const LoginPage = (props) => {

  const { children } = props;

  return (
    <Grid textAlign='center' style={{ height: '100vh'}} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image src={logo} centered style={{ height: 100, marginBottom: '20px' }} />
        {children}
        <Image src={netasLogo} centered style={{ height: 20 }} />
      </Grid.Column>
    </Grid>
  );
};

export default LoginPage;