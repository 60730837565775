import React from 'react';
import './Backdrop.css';

const Backlog = (props) => {
  return (
    <div
      className="backdrop"
      onClick={props.close}
    />
  )
};

export default Backlog;