import React, { useState, useEffect } from 'react';
import { Button, Card, Form, Segment, Divider, Grid, Checkbox, Message, Label, Icon, Dropdown, Input, Header, ListItem, Progress } from 'semantic-ui-react';
import ScenarioResource from './ScenarioResource';
import UploadComponent from '../../../components/UploadComponent';
import { createTestResource, updateTestScenario } from '../../../api/apiCalls';
import { useApiProgress } from '../../../api/ApiProgressHook';
import { toast } from 'react-toastify';
import EditInformationModal from './EditInformationModal';
import TestResourcesDrawer from './TestResourcesDrawer';
import { addScenarioVariation, removeScenarioVariation } from '../../../redux/actions/TestRunActions';
import { addResourceSuccess } from '../../../redux/actions/ResourceActions';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import VariablesAndDomainsModal from './VariablesAndDomainsModal';

const ScenarioCard = (props) => {

  const [scenario, setScenario] = useState({});
  const [scenarioValuesError, setScenarioValuesError] = useState({ noOfLoops: null, noOfMaxUsers: null, rampUpTimeInSec: null, weight: null });
  const [collapsed, setCollapsed] = useState(false);
  const [checked, setChecked] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [folderVisible, setFolderVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { data, handleDelete, duplicateScenario, testDurationInSeconds } = props;

  const dispatch = useDispatch();

  const uploadInProgress = useApiProgress('post', '/api/TestResources');

  useEffect(() => {
    setScenario(data);

  }, [data]);


  const uploadFile = (files) => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      }
    };
    files.map(file => {
      let data = new FormData();
      const fileType = file.name.split('.').pop().toLowerCase();

      data.append('formFile', file);
      data.append('nameOverride', file.name);
      data.append('needsSplit', false);
      data.append('csvHasHeader', false);
      data.append('resourceType', fileType === 'jmx' ? 1 : 2);
      data.append('description', '');

      createTestResource(data, config)
        .then(res => {
          setUploadProgress(0);
          addResourceToScenario([res.data.result]);
          dispatch(addResourceSuccess(res.data.result));
          toast.success('File uploaded');
        }).catch(err => {
          toast.error('File upload error!');
        });
    });
  };

  const addResourceToScenario = (files) => {
    const newScenario = { ...scenario };
    files.map((file) => {
      if (file.resourceType === 1) {
        newScenario.jmxResourceId = file.id;
      } else {
        newScenario.csvResourceIds = [...newScenario.csvResourceIds, file.id];
      }
    });
    updateScenario(newScenario);
  };

  const removeResourceToScenario = (resource) => {
    const newScenario = { ...scenario };
    if (resource.resourceType === 1) {
      newScenario.jmxResourceId = 0;
    } else {
      const newCsvResourceIds = scenario.csvResourceIds.filter(item => item !== resource.id);
      newScenario.csvResourceIds = [...newCsvResourceIds];
    }
    updateScenario(newScenario);
  };

  const { id, name, description, lastModificationTime, noOfLoops, noOfMaxUsers, rampUpTimeInSec, weight, domains, variables, csvResourceIds, jmxResourceId } = scenario;

  const updateInProgress = useApiProgress('put', '/api/TestScenarios/' + id);

  useEffect(() => {
    if (rampUpTimeInSec > testDurationInSeconds) {
      setScenarioValuesError(prev => ({ ...prev, rampUpTimeInSec: `Max value ${testDurationInSeconds}` }));
    } else {
      setScenarioValuesError(prev => ({ ...prev, rampUpTimeInSec: null }));
    }
  }, [rampUpTimeInSec, testDurationInSeconds]);

  const handleCheckboxChange = () => {
    if (jmxResourceId === 0 && !checked) {
      toast.info('This scenario has not a jmx file!');
    } else {
      setChecked(prev => !prev);
    }
  };

  useEffect(() => {
    if (checked) {
      dispatch(addScenarioVariation(scenario));
    } else {
      dispatch(removeScenarioVariation(id));
    }
  }, [checked]);

  useEffect(() => {
    if (checked) {
      dispatch(addScenarioVariation(scenario));
    }
  }, [scenario]);

  const updateScenario = async (newScenario) => {
    try {
      const response = await updateTestScenario(id, newScenario);
      setScenario(response.data.result);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleNumberChange = _.debounce((e, { name, value, min, max }) => {
    let error = null;
    if (value || value !== '') {
      let newValue = parseInt(value, 10);
      if (newValue < min) {
        error = `Min value ${min}`;
      } else if (max && newValue > max) {
        error = `Max value ${max}`;
      }
    } else {
      error = `Can not be empty`;
    }
    setScenarioValuesError(prev => ({ ...prev, [name]: error }));
    if (!error) {
      const newScenario = {
        ...scenario,
        [name]: parseInt(value, 10)
      };
      updateScenario(newScenario);
    }
  }, 1000);

  const addVariableOrDomain = async (newVariables, newDomains) => {
    const newScenario = {
      ...scenario,
      variables: newVariables,
      domains: newDomains
    };

    await updateScenario(newScenario);
    setModalVisible(false);
  };

  return (
    <Card fluid>
      {updateInProgress && <Progress percent={100} indicating attached="top" />}
      <div className="scenario-card-header" style={ checked ? {backgroundColor:'#d3f0d3'} : {backgroundColor:'#fcf9f9'}}>
        <div>
          <Header as='h4' className="page-title">
            <div className='header-checkbox' >
              <Checkbox onClick={handleCheckboxChange} checked={checked} className='scenario-checkbox' />
            </div>
            <Header.Content>
              {name}
              <Header.Subheader><small> {description || 'No description'}</small></Header.Subheader>
            </Header.Content>
          </Header>
        </div>
        <div>
          <Button.Group size='tiny'>
            <Dropdown
              className='icon'
              labeled
              button
              text='Actions'
              icon='ellipsis horizontal'
              iconPosition='right'
            >
              <Dropdown.Menu direction='left'>
                <Dropdown.Item onClick={() => setEditModalOpened(true)}><Icon name="pencil" /> Edit Scenario Info</Dropdown.Item>
                <Dropdown.Item onClick={() => setModalVisible(true)}><Icon name="edit" /> Edit Domains and Variables</Dropdown.Item>
                <Dropdown.Item onClick={() => duplicateScenario(id)}>
                  <Icon name="clone outline" /> Duplicate Scenario
                  </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDelete(id)}>
                  <Icon name="trash alternate outline" /> Delete Scenario
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Button.Group>
        </div>
      </div>
      <Card.Content style={{ marginBottom: 20 }} >
        <Card.Description>
          <Form>
            <Form.Group widths='equal'>
              <Form.Field error={scenarioValuesError.noOfMaxUsers}>
                <label>Max Users per Instance</label>
                <Input icon="user" iconPosition="left" name="noOfMaxUsers" min={1} max={800} defaultValue={noOfMaxUsers} type="number" onChange={handleNumberChange} />
              </Form.Field>
              <Form.Field error={scenarioValuesError.weight}>
                <label>Weight</label>
                <Input icon="percent" iconPosition="left" name="weight" min={0} max={100} defaultValue={weight} type="number" onChange={handleNumberChange} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field error={scenarioValuesError.rampUpTimeInSec}>
                <label>Ramp-up Time in Seconds</label>
                <Input icon="chart line" iconPosition="left" name="rampUpTimeInSec" min={0} max={testDurationInSeconds} defaultValue={rampUpTimeInSec} type="number" onChange={handleNumberChange} />
              </Form.Field>
              <Form.Field error={scenarioValuesError.noOfLoops}>
                <label>Number of Loops (0 for infinite loops)</label>
                <Input icon="redo" iconPosition="left" name="noOfLoops" min={0} defaultValue={noOfLoops} type="number" onChange={handleNumberChange} />
              </Form.Field>
            </Form.Group>
          </Form>
          {collapsed &&
            <>
              <Segment basic style={{ margin: '0px -14px -14px -14px' }}>
                <Grid columns={2} relaxed='very'>
                  <Grid.Column>
                    <UploadComponent uploadFunction={uploadFile} accept={['.jmx', '.csv', '.txt']} loading={uploadInProgress} uploadProgress={uploadProgress} />
                  </Grid.Column>
                  <Grid.Column>
                    <span onClick={() => setFolderVisible(true)}>
                      <Message
                        as="a"
                        style={{ marginTop: 12 }}
                        size='tiny'
                        icon='plus'
                        header='Select from shared folder'
                      />
                    </span>
                  </Grid.Column>
                </Grid>

                <Divider vertical>OR</Divider>
              </Segment>
              <ScenarioResource csvResourceIds={csvResourceIds} jmxResourceId={jmxResourceId} handleRemove={removeResourceToScenario} />
            </>
          }
        </Card.Description>
      </Card.Content>
      <Label onClick={() => setCollapsed(prev => !prev)} as='a' attached='bottom' style={{ textAlign: 'center' }} >
        <Icon name={collapsed ? 'angle up' : 'angle down'} />
        {collapsed ? 'Hide Test Resources' : 'Show Test Resources'}
      </Label>
      <EditInformationModal open={editModalOpened} handleClose={() => setEditModalOpened(false)}
        data={scenario}
        saveFunction={updateScenario}
        txt="Scenario"
        inProgress={updateInProgress}
      />
      <TestResourcesDrawer
        handleClose={() => setFolderVisible(false)}
        visible={folderVisible}
        csvResourceIds={csvResourceIds}
        jmxResourceId={jmxResourceId}
        handleSubmit={addResourceToScenario}
      />
      <VariablesAndDomainsModal visible={modalVisible} handleSubmit={addVariableOrDomain}
        handleClose={() => setModalVisible(false)} variables={variables} domains={domains}
        inProgress={updateInProgress}
      />
      {updateInProgress && <Progress percent={100} indicating attached="bottom" />}
    </Card >
  );
};

export default ScenarioCard;