import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import CloudReducer from './reducers/CloudReducer';
import ModalReducer from './reducers/ModalReducer';
import ResourceReducer from './reducers/ResourceReducer';
import TestRunReducer from './reducers/TestRunReducer';
import LoginReducer from './reducers/LoginReducer';

const rootReducer = combineReducers({
  CloudReducer,
  ModalReducer,
  ResourceReducer,
  TestRunReducer,
  LoginReducer
});

const configureStore = () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
}

export default configureStore;