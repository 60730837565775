import React, { useState, useEffect } from 'react';
import { Segment, Button, Header, Icon, Form, Input, TextArea, Divider, Grid, Message } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { getTestSuite, updateTestSuite, createTestSuite } from '../../api/apiCalls';

const defaultSuite = {
  name: '',
  description: null,
  noOfTotalUsers: 0,
  testDurationInSeconds: 0
};

const TestSuiteCrudPage = (props) => {

  const suiteId = props.match.params.id;

  const [suite, setSuite] = useState(defaultSuite);
  const [error, setError] = useState();
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    fetchTestSuite();
  }, []);

  useEffect(() => {
    setError();
  }, [suite]);

  const fetchTestSuite = async () => {
    if (suiteId) {
      try {
        const response = await getTestSuite(suiteId);
        setSuite(response.data.result);
      } catch (err) { }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSuite(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    setSuite(prev => ({
      ...prev,
      [name]: parseInt(value, 10)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError();
    setInProgress(true);
    try {
      let response;
      if (suiteId) {
        response = await updateTestSuite(suiteId, suite);
      } else {
        response = await createTestSuite(suite);
      }
      props.history.push(`/test-suite/${response.data.result.id}`);
    } catch (err) {
      setError(err.response.data.error);
    }
    setInProgress(false);
  };

  const title = `${suiteId ? 'Edit ' : ' Create new'} test suite`;
  const { name, description, noOfTotalUsers, testDurationInSeconds } = suite;

  return (
    <Grid >
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name={suiteId ? 'edit' : 'plus'} />
              </div>
              <Header.Content>
                {title}
                <Header.Subheader>{title} and scenarios</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div className="page-actions">
            <Button as={Link} to="/test-suites">Back </Button>
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>

          <Segment className="shadow">
            <Form onSubmit={handleSubmit}>
              <Form.Field>
                <label>Suite Name</label>
                <Input name="name" onChange={handleChange} placeholder='Suite name' defaultValue={name} />
              </Form.Field>

              <Form.Field>
                <label>Description</label>
                <TextArea name="description" onChange={handleChange} placeholder='Tell us more' value={description} />
              </Form.Field>

              <Form.Group widths='equal'>
                <Form.Input label='Number of Users' name='noOfTotalUsers' value={noOfTotalUsers} onChange={handleNumberChange} type="number" />
                <Form.Input label='Test Duration in Seconds (0 to run until tests stop themselves)' name='testDurationInSeconds'
                  value={testDurationInSeconds} onChange={handleNumberChange} type="number" />
              </Form.Group>

              <Divider />
              {error &&
                <Message negative>
                  <Message.Header>{error.message}</Message.Header>
                  <p>{error.details}</p>
                </Message>
              }
              <Grid.Row>
                <Grid.Column>
                  <Button disabled={inProgress} loading={inProgress} fluid size="large" animated type="submit" primary >
                    <Button.Content visible>Next</Button.Content>
                    <Button.Content hidden>
                      <Icon name='arrow right' />
                    </Button.Content>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
};

export default TestSuiteCrudPage;