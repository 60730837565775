import React from 'react';
import { Placeholder } from 'semantic-ui-react';

export const PlaceholderForLoad = () => (
  <Placeholder fluid>
    <Placeholder.Paragraph>
      <Placeholder.Line length='full' />
      <Placeholder.Line length='full' />
      <Placeholder.Line length='full' />
      <Placeholder.Line length='full' />
    </Placeholder.Paragraph>
    <Placeholder.Paragraph>
      <Placeholder.Line length='full' />
      <Placeholder.Line length='full' />
    </Placeholder.Paragraph>
  </Placeholder>
);