import React, { useState } from 'react';
import { Modal, Header } from 'semantic-ui-react';
import ClusterTemplateForm from './ClusterTemplateForm';
import { updateCluster } from '../../../api/apiCalls';
import { useApiProgress } from '../../../api/ApiProgressHook';
import { useDispatch } from 'react-redux';
import { fetchClusterListAgain } from '../../../redux/actions/CloudActions';

const ClusterEditModal = (props) => {

  const [err, setErr] = useState();
  const { cluster, visible, handleClose } = props;

  const pendingApiCall = useApiProgress('put', '/api/VmInfraDetails/');
  const dispatch = useDispatch();

  const handleSaveAction = async (body) => {
    setErr();
    try {
      await updateCluster(body.id, body);
      handleClose();
      dispatch(fetchClusterListAgain(true));
    } catch (error) {
      setErr(error.response.data.error);
    }
  };

  return (
    <Modal
      open={visible}
      onClose={handleClose}
      size='large'
      closeIcon
    >
      <Header icon='edit' content='Edit Cluster' />
      <Modal.Content>
        <ClusterTemplateForm cluster={cluster} saveAction={handleSaveAction} inProgress={pendingApiCall} error={err} />
      </Modal.Content>
    </Modal>
  );
};

export default ClusterEditModal;