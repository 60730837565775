import React from 'react';

import { useSelector } from 'react-redux';
import PasswordChange from '../../admin/users/components/PasswordChange';

const EditProfilePage = () => {

  const { user } = useSelector(store => store.LoginReducer);

  return (
    <div>
      <PasswordChange userId={user && user.id} />
    </div>
  );
};

export default EditProfilePage;