import React from 'react';
import { Image, Popup, Icon } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoginInfos } from '../redux/actions/LoginActions';

const SideMenu = (props) => {

  const { role, canAccessCreditPage } = props;
  const dispatch = useDispatch();
  const { user } = useSelector(store => store.LoginReducer);
  const history = useHistory();

  const handleMenuClick = (link) => {
    const { push, location } = history;
    const { pathname } = location;
    if (pathname === link) {
      window.location.reload();
    } else {
      push(link);
    }
  };

  return (
    <>
      <div className="side-bar-u">
        <div className="sb-logo">
          <Image style={{ width: 40, marginLeft: 9 }} as={Link} to='/' src={require('../assets/logo.png')} size='small' />
        </div>

        <div className="sb-menu-container">
          {(role && (role === 'SystemAdmin')) ?
            <Popup basic
              trigger={
                <span className="sb-menu-item" onClick={() => handleMenuClick("/tenants")}>
                  <Icon name='building' size='large' />
                </span>
              }
              content='Tenants'
              position='right center'
              className='sb-popover'
              inverted
            />
            :
            <>
              <Popup basic
                trigger={
                  <span className="sb-menu-item" onClick={() => handleMenuClick("/cloud-accounts")}>
                    <Icon name='cloud' size='large' />
                  </span>
                }
                content='Cloud Accounts'
                position='right center'
                className='sb-popover'
                inverted
              />
              <Popup basic
                trigger={
                  <span className="sb-menu-item" onClick={() => handleMenuClick("/clusters")} >
                    <Icon name='server' size='large' />
                  </span>
                }
                content='Clusters'
                position='right center'
                className='sb-popover'
                inverted
              />
              <Popup basic
                trigger={
                  <span className="sb-menu-item" onClick={() => handleMenuClick("/test-suites")} >
                    <Icon name='file alternate' size='large' />
                  </span>
                }
                content='Test Suites'
                position='right center'
                className='sb-popover'
                inverted
              />
              <Popup basic
                trigger={
                  <span className="sb-menu-item" onClick={() => handleMenuClick("/test-runs")} >
                    <Icon name='play circle outline' size='large' />
                  </span>
                }
                content='Test Runs'
                position='right center'
                className='sb-popover'
                inverted
              />
              {(canAccessCreditPage) &&
                <Popup basic
                  trigger={
                    <span className="sb-menu-item" onClick={() => handleMenuClick("/credit-report")} >
                      <Icon name='try' size='large' />
                    </span>
                  }
                  content='Credit Report'
                  position='right center'
                  className='sb-popover'
                  inverted
                />}
            </>
          }

          {(role && (role === 'Admin' || role === 'SystemAdmin')) && <Popup basic
            trigger={
              <span className="sb-menu-item" onClick={() => handleMenuClick("/users")} >
                <Icon name='users' size='large' />
              </span>
            }
            content='User List'
            position='right center'
            className='sb-popover'
            inverted
          />}


        </div>
        <div className="sb-menu-container to-bottom" >
          <Popup basic
            trigger={
              <Link onClick={() => {
                localStorage.clear();
                dispatch(resetLoginInfos());
                window.location = "/";
              }} className="sb-menu-item">
                <Icon name='log out' size='large' />
              </Link>
            }
            content='Sign out'
            position='right center'
            className='sb-popover'
            inverted
          />
          <Popup basic
            trigger={
              <Link to="/edit-profile" className="sb-menu-item">
                <Icon name='user outline' size='large' />
              </Link>
            }
            content={user ? user.userName : ''}
            position='right center'
            className='sb-popover'
            inverted
          />
        </div>
      </div>
    </>
  )
};

export default SideMenu;