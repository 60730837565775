export const FETCH_CLOUD_INVENTORIES_SUCCESS = 'FETCH_CLOUD_INVENTORIES_SUCCESS';
export const FETCH_CLUSTER_LIST_AGAIN = 'FETCH_CLUSTER_LIST_AGAIN';
export const OPEN_CLIENT_SECRET_MODAL = 'OPEN_CLIENT_SECRET_MODAL';
export const CLOSE_CLIENT_SECRET_MODAL = 'CLOSE_CLIENT_SECRET_MODAL';
export const OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_MODAL';
export const CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL';

export const FETCH_TEST_RESOURCE_LIST_BEGIN = 'FETCH_TEST_RESOURCE_LIST_BEGIN';
export const FETCH_TEST_RESOURCE_LIST_SUCCESS = 'FETCH_TEST_RESOURCE_LIST_SUCCESS';
export const FETCH_TEST_RESOURCE_LIST_FAILURE = 'FETCH_TEST_RESOURCE_LIST_FAILURE';

export const FETCH_PLUGIN_LIST_BEGIN = 'FETCH_PLUGIN_LIST_BEGIN';
export const FETCH_PLUGIN_LIST_SUCCESS = 'FETCH_PLUGIN_LIST_SUCCESS';
export const FETCH_PLUGIN_LIST_FAILURE = 'FETCH_PLUGIN_LIST_FAILURE';

export const RESET_TEST_RUN_VARIATION = 'RESET_TEST_RUN_VARIATION';
export const UPDATE_TEST_RUN_VARIATION = 'UPDATE_TEST_RUN_VARIATION';
export const UPDATE_TEST_SUITE_VARIATION = 'UPDATE_TEST_SUITE_VARIATION';
export const UPDATE_TEST_SCENARIO_VARIATION = 'UPDATE_TEST_SCENARIO_VARIATION';
export const ADD_TEST_SCENARIO_VARIATION = 'ADD_TEST_SCENARIO_VARIATION';
export const REMOVE_TEST_SCENARIO_VARIATION = 'REMOVE_TEST_SCENARIO_VARIATION';


export const UPDATE_TEST_RESOURCE_BEGIN = 'UPDATE_TEST_RESOURCE_BEGIN';
export const UPDATE_TEST_RESOURCE_SUCCESS = 'UPDATE_TEST_RESOURCE_SUCCESS';
export const UPDATE_TEST_RESOURCE_FAILURE = 'UPDATE_TEST_RESOURCE_FAILURE';

export const ADD_TEST_RESOURCE_SUCCESS = 'ADD_TEST_RESOURCE_SUCCESS';
export const DELETE_TEST_RESOURCE_SUCCESS = 'DELETE_TEST_RESOURCE_SUCCESS';

export const TEST_RUN_VALIDATION = 'TEST_RUN_VALIDATION';

export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_CURRENT_LOGIN_INFO = 'SET_CURRENT_LOGIN_INFO';
export const RESET_LOGIN_REDUCER = 'RESET_LOGIN_REDUCER';

export const ADD_PLUGIN_SUCCESS = 'ADD_PLUGIN_SUCCESS';
export const DELETE_PLUGIN_SUCCESS = 'DELETE_PLUGIN_SUCCESS';