import React, { useEffect, useState } from 'react';
import { Modal, Icon, Grid, Message, List, Table, Header } from 'semantic-ui-react';
import { getVmInfraInfo } from '../../../api/apiCalls';
import { useApiProgress } from '../../../api/ApiProgressHook';
import { BasicTableRow } from './ClusterCard';
import { PlaceholderForLoad } from '../../../components/PlaceholderForLoad';

const VmInfraInfoModal = (props) => {

  const [infraInfo, setInfraInfo] = useState({});
  const [error, setError] = useState();
  const [showPass, setShowPass] = useState(false);

  const { vmInfraRunId, visible, handleClose } = props;

  const pendingPostApiCall = useApiProgress('get', `/api/VmInfraRuns/${vmInfraRunId}/info`);

  useEffect(() => {
    if (visible) {
      fetchInfo(vmInfraRunId);

      return function unmount() {
        setInfraInfo({});
        setError();
      };
    }
  }, [visible]);

  const fetchInfo = async (infraRunId) => {
    try {
      const res = await getVmInfraInfo(infraRunId);
      setInfraInfo(res.data.result);
    } catch (err) {
      setError(err.response.data.error);
    }
  };

  const { sshIp, sshPort, sshUsername, grafanaUsername, grafanaPassword, monitoringIp, workerPublicIps, publicIpPrefix } = infraInfo;
  const grafanaUrl = `http://${monitoringIp}:8900`;

  return (
    <Modal open={visible} onClose={handleClose} closeIcon>
      <Modal.Content>
        {error ?
          <Message
            icon='warning'
            header='Error!'
            content={error.message}
          />
          :
          <>
            {pendingPostApiCall ?
              <PlaceholderForLoad />
              :
              <Grid columns={2} padded='vertically'>
                <Grid.Column>
                  <Header as='h4' block>
                    Public IP Prefix
                  </Header>
                  {publicIpPrefix}
                  <Header as='h4' block>
                    Monitor Node IPs
                  </Header>
                  {monitoringIp}
                  <Header as='h4' block>
                    Worker Node IPs
                  </Header>
                  {workerPublicIps && workerPublicIps.map(node => <List.Item content={node} />)}
                </Grid.Column>
                <Grid.Column>
                  <Header as='h4' block>
                    SSH Info
                  </Header>
                  <Table basic='very'>
                    <BasicTableRow name="Username:" value={sshUsername} />
                    <BasicTableRow name="IP:" value={sshIp} />
                    <BasicTableRow name="Port: " value={sshPort} />
                  </Table>
                  <Header as='h4' block>
                    Grafana Dashboard
                  </Header>
                  <Table basic='very'>
                    <BasicTableRow name="Grafana adress:" value={<a target="_blank" href={grafanaUrl}>{grafanaUrl}</a>} />
                    <BasicTableRow name="Username:" value={grafanaUsername} />
                    <Table.Row>
                      <Table.Cell><b>Password: </b></Table.Cell>
                      <Table.Cell>
                        {showPass ? grafanaPassword : '********************'}
                        <List.Item as="a" style={{ float: 'right' }} onClick={() => setShowPass((prev) => !prev)}><Icon name={showPass ? 'eye slash outline' : 'eye'} /></List.Item>
                      </Table.Cell>
                    </Table.Row>
                  </Table>
                </Grid.Column>
              </Grid>
            }
          </>
        }
      </Modal.Content>
    </Modal>
  );
};

export default VmInfraInfoModal;