import React, { useEffect, useState } from 'react';
import { Grid, Button, Icon, Header, Loader, Dimmer, Card, Table } from 'semantic-ui-react';
import { fetchInfraDetails } from '../../api/apiCalls';
import { useApiProgress } from '../../api/ApiProgressHook';
import ClusterCard from './components/ClusterCard';
import ClusterCreateModal from './components/ClusterCreateModal';
import { getCloudInventories, fetchClusterListAgain } from '../../redux/actions/CloudActions';
import { useDispatch, useSelector } from 'react-redux';
import NoDataFound from '../../components/NoDataFound';

const ClusterListPage = () => {

  const [clusterList, setClusterList] = useState([]);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [error, setError] = useState();
  const [initialApiCall, setInitialApiCall] = useState(false);

  const dispatch = useDispatch();
  const pendingApiCall = useApiProgress('get', '/api/VmInfraDetails');
  const fetchClustersAgain = useSelector(store => store.CloudReducer.fetchClustersAgain);

  useEffect(() => {

    const getDatas = async () => {
      setInitialApiCall(true);
      await dispatch(getCloudInventories());
      await getClustersInfo();
      setInitialApiCall(false);
    };

    getDatas();
  }, []);

  useEffect(() => {
    if (fetchClustersAgain) {
      getClustersInfo();
    }
  }, [fetchClustersAgain]);

  const getClustersInfo = async () => {
    setError();
    setClusterList([]);
    try {
      const response = await fetchInfraDetails();
      setClusterList(response.data.result);
      dispatch(fetchClusterListAgain(false));
    } catch (err) {
      setError((err.response && err.response.data.error) || err);
    }
  };

  return (
    <>
      <Grid >
        <Grid.Row columns={1} className='page-header'>
          <Grid.Column>
            <div >
              <Header as='h3' className="page-title">
                <div className="page-icon">
                  <Icon name='server' />
                </div>
                <Header.Content>
                  Manage Clusters
                  <Header.Subheader>You can access all clusters and manage them</Header.Subheader>
                </Header.Content>
              </Header>
            </div>

            <div className="page-actions">
              <Button onClick={() => setCreateModalVisible(true)} icon primary>
                <Icon name='plus' /> Add New Cluster
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="visium-page-content">
          <Grid.Column>

              {(initialApiCall || pendingApiCall) &&
                <Dimmer active inverted>
                  <Loader size='large'>Loading</Loader>
                </Dimmer>
              }
              {!clusterList.length ?
                <NoDataFound msg="There is no cluster here!" />
                :
                <div className="shadow" >
                <Table celled size="small" selectable sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Status</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Cloud Type</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'># of VMs</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>VM Slug</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>VM Types</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'># of Inst.</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {clusterList.map(cluster => (
                        <ClusterCard key={cluster.id} data={cluster} />
                      ))}
                    </Table.Body>
                  </Table>

                  {/* <Card.Group centered >
                    <Grid>
                      <Grid.Row columns={2}>
                        {clusterList.map(cluster => (
                          <ClusterCard key={cluster.id} data={cluster} />
                        ))}
                      </Grid.Row>
                    </Grid>
                  </Card.Group> */}

                </div>
              }
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ClusterCreateModal visible={createModalVisible} handleClose={() => setCreateModalVisible(false)} />
    </>
  );
};

export default ClusterListPage;