import React, { useState, useEffect } from 'react';
import { Form, Button, Icon, Label, Table, Popup } from 'semantic-ui-react';
import { createImageBuild } from '../../../api/apiCalls';
import moment from 'moment';
import { imageBuildStatus, CloudProviderTypes } from '../consts';
import { PlaceholderForLoad } from '../../../components/PlaceholderForLoad';

const signalR = require("@aspnet/signalr");

// Post https://demo.visiumload.netas.com.tr/signalr/image-build/negotiate to create ws connection
const ImageBuilderForm = (props) => {

  const { cloudAccount } = props;
  const defaultData = {
    theClientSecret: '',
    region: '',
    resourceGroup: '',
    providerId: cloudAccount.providerId,
    cloudAccountId: cloudAccount.id
  };
  const [imageBuilds, setImageBuilds] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    if (cloudAccount) {
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl("/signalr/image-build")
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      setInProgress(true);
      hubConnection.start().then(
        () => {
          sendSetupMessage(hubConnection);
        },
        (error) => {
          console.error('Error while establishing connection :(', error);
        }
      );

      return function unmount() {
        hubConnection.stop();
      }
    }
  }, [cloudAccount]);

  const { theClientSecret, resourceGroup, providerId, cloudAccountId } = data;

  const sendSetupMessage = (hubConnection) => {
    const stream = hubConnection.stream('SetupReceiveBuildStatus', cloudAccountId, providerId);

    stream.subscribe({
      next(buildStatus) {
        setImageBuilds(buildStatus.regionStatuses);
        setInProgress(false);
      },
    });
  };

  const handleChange = (e, { name, value }) => {
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleInitBtn = (region) => {
    setData(prevData => ({
      ...prevData,
      region
    }));
  };

  const startImageBuild = async (data) => {
    try {
      await createImageBuild(data);
      setData(defaultData);
    }
    catch (err) { }
  };

  return (
    <>
      {inProgress ?
        <PlaceholderForLoad />
        :
        <Table celled size="small" selectable sortable>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell>
                Region Name
              </Table.HeaderCell>
              <Table.HeaderCell>
                Modification Date
              </Table.HeaderCell>
              <Table.HeaderCell>
                Image Status
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>
                Build Image
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {imageBuilds.map((imageBuild) => (
              < Table.Row textAlign='center' >
                <Table.Cell>
                  {imageBuild.cloudProviderRegion.friendlyName} {imageBuild.cloudProviderRegion.name !== imageBuild.cloudProviderRegion.friendlyName && '(' + imageBuild.cloudProviderRegion.name + ')'}
                </Table.Cell>
                <Table.Cell>
                  {imageBuild.lastModificationTime !== '0001-01-01T00:00:00' ? moment(imageBuild.lastModificationTime).fromNow() : 'N/A'}
                </Table.Cell>
                <Table.Cell>
                  <Label color={imageBuildStatus[imageBuild.imageBuildStatus].color}>
                    {imageBuildStatus[imageBuild.imageBuildStatus].message}
                  </Label>
                </Table.Cell>
                <Table.Cell>
                  {imageBuild.imageBuildStatus !== 1 && imageBuild.imageBuildStatus !== 2 &&
                    <Popup onClose={() => setData(defaultData)} open={data.region === imageBuild.cloudProviderRegion.name} trigger={
                      <Button icon size="mini" onClick={() => { handleInitBtn(imageBuild.cloudProviderRegion.name) }}>
                        <Icon name='play circle outline' />
                      </Button>} on='focus'>
                      <Popup.Content>
                        <Form>
                          <Form.Field>
                            <label>Client Secret: </label>
                            <Form.Input type="password" name="theClientSecret" value={theClientSecret} onChange={handleChange} placeholder="Provider's client secret" />
                          </Form.Field>
                          {cloudAccount.providerId === CloudProviderTypes.AZURE &&
                            <Form.Field>
                              <label>Resource Group: </label>
                              <Form.Input name="resourceGroup" value={resourceGroup} onChange={handleChange} placeholder='Resource Group' />
                            </Form.Field>}
                          <Button color='teal' onClick={() => startImageBuild(data)}>
                            <Icon name='play circle outline' /> Build
                          </Button>
                        </Form>
                      </Popup.Content>
                    </Popup>
                  }
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table >
      }
    </>
  );
};

export default ImageBuilderForm;