import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

const defaultGoogleCloud = {
  type: "service_account",
  projectId: "",
  privateKeyId: "",
  client_email: "",
  clientEmail: "",
  clientId: "",
  authUri: "https://accounts.google.com/o/oauth2/auth",
  tokenUri: "https://accounts.google.com/o/oauth2/token",
  authProviderCertUrl: "https://www.googleapis.com/oauth2/v1/certs",
  clientCertUrl: ""
}

const GoogleCloudForm = (props) => {

  const [googleCloud, setGoogleCloud] = useState(defaultGoogleCloud);
  const { handleProviderDataChange, googleAccount } = props;

  useEffect(() => {
    handleProviderDataChange('google', googleCloud);
  }, [googleCloud]);

  useEffect(() => {
    if (googleAccount) {
      setGoogleCloud(googleAccount);
    } else {
      setGoogleCloud(defaultGoogleCloud);
    }
  }, [googleAccount]);

  const handleChange = (e, { name, value }) => {
    setGoogleCloud(prevCloud => ({
      ...prevCloud,
      [name]: value
    }));
  }

  const handleClientEmailChange = (e, { value }) => {
    setGoogleCloud(prevCloud => ({
      ...prevCloud,
      clientEmail: value,
      client_email: value
    }));
  }

  const { projectId, privateKeyId, clientEmail, clientId, clientCertUrl } = googleCloud;

  return (
    <Form>
      <Form.Field>
        <label>Project Id: </label>
        <Form.Input name="projectId" value={projectId} onChange={handleChange} placeholder='Project Id' />
      </Form.Field>
      <Form.Field>
        <label>Private Key Id: </label>
        <Form.Input name="privateKeyId" value={privateKeyId} onChange={handleChange} placeholder='Private Key Id' />
      </Form.Field>
      <Form.Field>
        <label>Client Email: </label>
        <Form.Input name="clientEmail" value={clientEmail} onChange={handleClientEmailChange} placeholder='Client Email' />
      </Form.Field>
      <Form.Field>
        <label>Client Id: </label>
        <Form.Input name="clientId" value={clientId} onChange={handleChange} placeholder='Client Id' />
      </Form.Field>
      <Form.Field>
        <label>Client Certificate Url: </label>
        <Form.Input name="clientCertUrl" value={clientCertUrl} onChange={handleChange} placeholder='Client Certificate Url' />
      </Form.Field>
    </Form>
  );
};

export default GoogleCloudForm;