import React, { useState, useEffect } from 'react';
import { Form, Button, Table, Header } from 'semantic-ui-react';
import { defaultInfo, validateNodeInfo } from './ClusterTemplateForm';

const ClusterTemplateOmPremFrom = (props) => {

  const [newWorkerNode, setNewWorkerNode] = useState({
    name: '',
    privateIp: '',
    publicIp: ''
  });

  useEffect(() => {
    setWorkerErr({});
  }, [newWorkerNode]);

  const [workerErr, setWorkerErr] = useState({});

  const { cluster, handleControlNodeChange, handleMonitorNodeChange, handleSshChange, handleAddWorkerButton, handleDeleteButton } = props;

  const { id, vmInfraExtraInfo } = cluster;
  const { controlVirtualMachines, monitorVirtualMachines, workerVirtualMachines, sshInfo } = vmInfraExtraInfo;

  const handleNewWorkerChange = (e, { name, value }) => {
    setNewWorkerNode(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddButton = (e) => {
    e.preventDefault();
    if (validateNodeInfo(newWorkerNode, setWorkerErr)) {
      handleAddWorkerButton(newWorkerNode);
      setNewWorkerNode({...defaultInfo});
    }
  }

  const { name, privateIp, publicIp } = newWorkerNode;
  const { name: nameErr, privateIp: privateIpErr, publicIp: publicIpErr } = workerErr;

  return (
    <Form>
      <Header as='h4' block>
        Control Node Info
    </Header>

      {controlVirtualMachines.map(conNode => <Form.Group key={conNode} widths='equal'>
        <Form.Input label='Name' name='name' placeholder='Name' value={conNode.name} onChange={handleControlNodeChange} disabled={id} />
        <Form.Input label='Private Ip' name='privateIp' placeholder='Private Ip' value={conNode.privateIp} onChange={handleControlNodeChange} disabled={id} />
        <Form.Input label='Public Ip' name='publicIp' placeholder='Public Ip' value={conNode.publicIp} onChange={handleControlNodeChange} disabled={id} />
      </Form.Group>
      )}
      <Header as='h4' block>
        Monitor Node Info
    </Header>
      {monitorVirtualMachines.map(monNode => <Form.Group key={monNode} widths='equal'>
        <Form.Input label='Name' name='name' placeholder='Name' value={monNode.name} onChange={handleMonitorNodeChange} disabled={id} />
        <Form.Input label='Private Ip' name='privateIp' placeholder='Private Ip' value={monNode.privateIp} onChange={handleMonitorNodeChange} disabled={id} />
        <Form.Input label='Public Ip' name='publicIp' placeholder='Public Ip' value={monNode.publicIp} onChange={handleMonitorNodeChange} disabled={id} />
      </Form.Group>
      )}
      <Header as='h4' block>
        Worker Nodes Info
    </Header>

      <Table basic='very'>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Private IP</Table.HeaderCell>
            <Table.HeaderCell>Public IP</Table.HeaderCell>
            <Table.HeaderCell textAlign='right'></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {workerVirtualMachines.map((worNode, key) =>
          <Table.Row>
            <Table.Cell><b>{parseInt(key + 1, 10)}</b></Table.Cell>
            <Table.Cell><b>{worNode.name}</b></Table.Cell>
            <Table.Cell>{worNode.privateIp}</Table.Cell>
            <Table.Cell>{worNode.publicIp}</Table.Cell>
            <Table.Cell textAlign='right'>
              <Button icon='trash alternate outline' color='red' onClick={(e) => handleDeleteButton(e, worNode)} />
            </Table.Cell>
          </Table.Row>
        )}
      </Table>
      <Form.Group widths='equal' >
        <Form.Input error={nameErr} name='name' placeholder='Name' value={name} onChange={handleNewWorkerChange} />
        <Form.Input error={privateIpErr} name='privateIp' placeholder='Private Ip' value={privateIp} onChange={handleNewWorkerChange} />
        <Form.Input error={publicIpErr} name='publicIp' placeholder='Public Ip' value={publicIp} onChange={handleNewWorkerChange} />
        <Button icon="plus" style={{ height: 38 }} onClick={handleAddButton} />
      </Form.Group>

      <Header as='h4' block>
        SSH Info
      </Header>
      <Form.Group widths='equal'>
        <Form.Input label='Username: ' name='username' placeholder='Username' value={sshInfo.username} onChange={handleSshChange} />
        <Form.Input label='Ip: ' name='ip' placeholder='Ip' value={sshInfo.ip} onChange={handleSshChange} />
      </Form.Group>
    </Form>
  );
};

export default ClusterTemplateOmPremFrom;