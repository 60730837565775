export const aggregationOptions = [
  { key: '1s', text: '1s', value: 1 },
  { key: '2s', text: '2s', value: 2 },
  { key: '5s', text: '5s', value: 5 },
  { key: '10s', text: '10s', value: 10 },
  { key: '30s', text: '30s', value: 30 },
  { key: '1m', text: '1m', value: 60 },
  { key: '2m', text: '2m', value: 120 },
  { key: '5m', text: '5m', value: 300 },
  { key: '10m', text: '10m', value: 600 },
];

export const refreshOptions = [
  { key: '1s', text: '1s', value: 1 },
  { key: '2s', text: '2s', value: 2 },
  { key: '5s', text: '5s', value: 5 },
  { key: '10s', text: '10s', value: 10 },
  { key: '30s', text: '30s', value: 30 },
  { key: '1m', text: '1m', value: 60 },
  { key: '2m', text: '2m', value: 120 },
  { key: '5m', text: '5m', value: 300 },
];

export const quickRangeOptions = [
  { key: '1m', text: 'Last 1 minute', value: 1 },
  { key: '5m', text: 'Last 5 minutes', value: 5 },
  { key: '10m', text: 'Last 10 minutes', value: 10 },
  { key: '20m', text: 'Last 20 minutes', value: 20 },
  { key: '30m', text: 'Last 30 minutes', value: 30 },
  { key: '1h', text: 'Last 1 hour', value: 60 },
  { key: '2h', text: 'Last 2 Hours', value: 120 },
];