import React, { useEffect, useState } from 'react';
import { Grid, Image, Header, Segment, Icon, Card, TextArea, Form, Dimmer, Loader, Divider } from 'semantic-ui-react';
import { getTestRun, getTestReport } from '../../api/apiCalls';
import { useApiProgress } from '../../api/ApiProgressHook';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReportStats from './components/ReportStats';
import ThroughputLineChart from './components/ThroughputLineChart';
import TopNResponses from './components/TopNResponses';
import ScenarioResults from './components/ScenarioResults';
import NoDataFound from '../../components/NoDataFound';

const prettyMilliseconds = require('pretty-ms');

const TestReportPage = (props) => {

  const testRunId = props.match.params.id;
  const summaryInProgress = useApiProgress('get', `/api/report/${testRunId}/getReportJson`);
  const testRunInProgress = useApiProgress('get', `/api/TestRuns/${testRunId}`);

  const [testRun, setTestRun] = useState({});
  const [testReport, setTestReport] = useState({});
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    fetchTestRun();
    fetchTestReport();
  }, []);

  const fetchTestRun = async () => {
    try {
      const response = await getTestRun(testRunId);
      setTestRun(response.data.result)
    } catch (err) { };
  };

  const fetchTestReport = async () => {
    try {
      const response = await getTestReport(testRunId);
      setTestReport(response.data.result)
    } catch (err) { };
  };

  const { name, description, creationTime } = testRun;
  const { intervalMs, startDateMs, endDateMs, durationMs,
    noOfTotalRequests, noOfErroredRequests, rpmInterval,
    topNRequests, description: reportDescription,
    requestsByInterval, virtualUsersByInterval, scenarios, averageResponseTime } = testReport || {};

  let labelsDate = [];

  let testStartMs = parseInt(startDateMs || -1, 10);
  let testEndMs = parseInt(endDateMs || -1, 10);
  let duration = parseInt(durationMs || -1, 10);

  if (testStartMs !== -1) {
    testStartMs -= 2000;
    testEndMs += 2000;

    const timeDiff = testEndMs - testStartMs;
    const aggregationIntervalInMs = parseInt(intervalMs, 10) * 1000;
    const noOfIntervals = Math.floor(timeDiff / aggregationIntervalInMs) + 1;

    var current = testStartMs;
    for (var i = 0; i <= noOfIntervals; ++i) {
      labelsDate.push(current);
      current += aggregationIntervalInMs;
    }
  }

  if (testRunInProgress || summaryInProgress) {
    return (
      <Dimmer active page>
        <Header as='h4' icon inverted>
          <Loader size='huge'>Creating Summary</Loader>
        </Header>
      </Dimmer>
    );
  }

  if (!testReport || testReport === {}) {
    return <NoDataFound msg="Report not found!" />
  }

  return (
    <Grid columns="3" centered >
      <Grid.Column mobile="16" computer="14" widescreen="12" >
        <div style={{ padding: 12 }}>
          <div className="sb-logo" style={{ float: 'right' }}>
            <Image
              style={{ display: 'inline-block', height: '50px', width: '160px' }}
              as={Link} to='/' src={require('../../assets/visiumLoad.png')} size='small' />
          </div>
          <Header as='h2'>
            {name}
            <Header.Subheader>
              {description}
            </Header.Subheader>
          </Header>
          <Grid columns={2} divided stackable style={{ marginTop: 12 }} >
            <Grid.Column width="2" verticalAlign='middle' >
              <Segment placeholder style={{ minHeight: "10em" }} size="tiny" textAlign="center" >
                <Header icon>
                  <Icon name='plus' size="mini" />
                  Upload
                </Header>
              </Segment>
            </Grid.Column>

            <Grid.Column verticalAlign='middle'>
              <div style={{ fontSize: '16px' }} >
                <label>Report created by <strong>Auto Generated</strong></label><br />
                <label>Date of Run: <strong>{moment(creationTime).format('LLL')}</strong></label><br />
                <label>Duration: <strong>{prettyMilliseconds(duration, { verbose: true, compact: true })}</strong></label>
              </div>
            </Grid.Column>
          </Grid>

          <div style={{ marginTop: '3em', marginBottom: '3em' }}>
            <span onClick={() => setIsOpened(prev => !prev)}>
              <Header disabled size="small"><Icon name={isOpened ? "minus" : "plus"} /> Show Description</Header>
            </span>
            {isOpened && <Card style={{ left: 0, right: 0, backgroundColor: '#f5f5f5' }} fluid >
              <Form>
                <TextArea rows={2} value={reportDescription} />
              </Form>
            </Card>}
          </div>

          <ReportStats
            noOfTotalRequests={noOfTotalRequests}
            noOfErroredRequests={noOfErroredRequests}
            topNRequests={topNRequests}
            rpmInterval={rpmInterval}
            averageResponseTime={averageResponseTime}
          />

          <div style={{ marginTop: '3em', marginBottom: '3em' }}>
            <Header>TEST SETUP DETAILS</Header>
            <Divider />
            <Grid>
              <NewItem title="Link to the report" content={(<a target="_blank" href={window.location.href}>{window.location.href}</a>)} icon="linkify" />
              {/* <NewItem title="Executed By" content="Ahmed AKÇIL" icon="user" /> */}
              <NewItem title="Locations Used" content="External" icon="point" />
              <NewItem title="Test Started" content={`${new Date(startDateMs)}`} icon="flag" />
              <NewItem title="Test Finished" content={`${new Date(endDateMs)}`} icon="flag" />
              <NewItem title="Elapsed" content={`${prettyMilliseconds(duration, { verbose: true })}`} icon="time" />
            </Grid>
          </div>

          <div style={{ marginTop: '3em', marginBottom: '3em' }}>
            <Header>OVERALL RESULTS</Header>
            <Divider />
            <small>Concurrent Users and Hits/s</small>
            <ThroughputLineChart
              requestsByInterval={requestsByInterval}
              virtualUsersByInterval={virtualUsersByInterval}
              labelsDate={labelsDate}
            />
          </div>

          <TopNResponses data={topNRequests} />

          {scenarios && scenarios.map(scenario => (
            <ScenarioResults data={scenario} key={scenario.scenarioName} labelsDate={labelsDate} />
          ))}

        </div>
      </Grid.Column>
    </Grid >
  );
};

const NewItem = ({ title, content, icon }) => {
  return (
    <Grid.Row style={{ paddingBottom: 0 }}>
      <Grid.Column width={3}>
        <Icon name={icon} color="blue" /> {title}
      </Grid.Column>
      <Grid.Column width={13}>
        <strong>{content}</strong>
      </Grid.Column>
    </Grid.Row>
  );
};

export default TestReportPage;