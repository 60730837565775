

import axios from 'axios';


export const setAuthorizationHeader = (token) => {
  axios.defaults.headers['Authorization'] = "Bearer " + token;
}

export const login = (data) => axios.post('/api/TokenAuth/Authenticate', data);

export const fetchInfraDetails = () => axios.get('/api/VmInfraDetails');

export const fetchCloudInventories = () => axios.get('/api/CloudInventories');

export const fetchCloudAccounts = () => axios.get('/api/CloudAccounts');

export const createCloudAccount = (body) => axios.post('/api/CloudAccounts', body);

export const updateCloudAccount = (id, body) => axios.put('/api/CloudAccounts/' + id, body);

export const deleteCloudAccount = (id) => axios.delete('/api/CloudAccounts/' + id);

export const fetchImageBuilds = (cloudAccountId = null, providerId = null) => {
  return axios.get('/api/ImageBuilds', { params: { cloudAccountId, providerId } });
};

export const createImageBuild = (body) => axios.post('/api/ImageBuilds', body)

export const createCluster = (body) => axios.post('/api/VmInfraDetails', body);

export const updateCluster = (id, body) => axios.put('/api/VmInfraDetails/' + id, body);

export const deleteCluster = (id) => axios.delete('/api/VmInfraDetails/' + id);

export const getLastRun = (id) => axios.get(`/api/VmInfraDetails/${id}/lastrun`);

export const getInfraStatus = (id) => axios.get(`/api/VmInfraDetails/${id}/status`);

export const getCapacity = (id) => axios.get(`/api/VmInfraRuns/${id}/Capacity`);

export const createVmInfra = (body) => axios.post(`/api/VmInfraRuns`, body);

export const updateVmInfra = (id, body) => axios.put(`/api/VmInfraRuns/${id}`, body);

export const destroyVmInfra = (id, body) => axios.post(`/api/VmInfraRuns/${id}/destroy`, body);

export const getVmInfraInfo = (id) => axios.get(`/api/VmInfraRuns/${id}/info`);

export const getTestSuites = () => axios.get(`/api/TestSuites`);

export const getTestSuite = (id) => axios.get(`/api/TestSuites/${id}`);

export const createTestSuite = (body) => axios.post(`/api/TestSuites`, body);

export const updateTestSuite = (id, body) => axios.put(`/api/TestSuites/${id}`, body);

export const deleteTestSuite = (id) => axios.delete(`/api/TestSuites/${id}`);

export const getTestScenario = (id) => axios.get(`/api/TestScenarios/${id}`);

export const createTestScenario = (body) => axios.post(`/api/TestScenarios`, body);

export const duplicateTestScenario = (id) => axios.post(`/api/TestScenarios/${id}/duplicate`, {});

export const updateTestScenario = (id, body) => axios.put(`/api/TestScenarios/${id}`, body);

export const deleteTestScenario = (id) => axios.delete(`/api/TestScenarios/${id}`);

export const createTestResource = (data, config) => axios.post(`/api/TestResources`, data, config);

export const deleteTestResource = (id) => axios.delete(`/api/TestResources/${id}`);

export const downloadTestResource = (id) => axios.get(`/api/TestResources/${id}/download`);

export const getTestResource = (id) => axios.get(`/api/TestResources/${id}`);

export const getTestResources = () => axios.get('/api/TestResources');

export const updateTestResource = (id, body, config) => axios.put(`/api/TestResources/${id}`, body, config);

export const getTestTools = () => axios.get('/api/TestTools');

export const getAllTestRuns = (suiteId = null) => axios.get(`/api/TestRuns`, { params: { suiteId } });

export const fetchPlugins = () => axios.get(`/api/Plugins`);

export const createPlugin = (data, config) => axios.post(`/api/Plugins`, data, config);

export const updatePlugin = (id, data, config) => axios.put(`/api/Plugins/${id}`, data, config);

export const deletePlugin = (id) => axios.delete(`/api/Plugins/${id}`);

export const getTestReport = (id) => axios.get(`/api/report/${id}/getReportJson`);

export const getTestRun = (id) => axios.get(`/api/TestRuns/${id}`);

export const getCurrentLoginInfo = () => axios.get(`/api/services/app/Session/GetCurrentLoginInformations`);

export const getUserList = () => axios.get(`/api/Users`);

export const getUser = (id) => axios.get(`/api/Users/${id}`);

export const createUser = (body) => axios.post(`/api/Users`, body);

export const updateUser = (id, body) => axios.put(`/api/Users/${id}`, body);

export const deleteUser = (id) => axios.delete(`/api/Users/${id}`);

export const changePassword = (id, body) => axios.put(`/api/Users/changepassword/${id}`, body);

export const changePasswordUser = (body) => axios.put(`/api/Password/change`, body);

export const getTenants = () => axios.get(`/api/Tenants`);

export const getTenant = (id) => axios.get(`/api/Tenants/${id}`);

export const createTenant = (body) => axios.post(`/api/Tenants`, body);

export const updateTenant = (id, body) => axios.put(`/api/Tenants/${id}`, body);

export const deleteTenant = (id) => axios.delete(`/api/Tenants/${id}`);

export const getExpenseDetail = (id) => axios.get(`/api/credit/${id}/expenseDetail`);

export const createExpenseDetail = (id) => axios.post(`/api/credit/expenseDetail`);

export const fetchLoadReport = (id, start = null, end = null) =>
  axios.get(`/api/credit/${id}/loadReport`, { params: { start, end } });

export const fetchSpentReport = (id, start = null, end = null) =>
  axios.get(`/api/credit/${id}/spentReport`, { params: { start, end } });

export const getRemainingCredit = (id) => axios.get(`/api/credit/${id}`);

export const loadCredit = (id, body) => axios.post(`/api/credit/${id}/load`, body);

export const startTest = (body) => axios.post(`/api/TestRuns`, body);

export const stopTest = (id) => axios.post(`/api/TestRuns/${id}/stop`, {});

export const repeatTest = (id, body) => axios.post(`/api/TestRuns/${id}/repeat`, body);

export const getErroredRequest = (id, params) => axios.get(`/api/TestRuns/${id}/erroredRequests`, { params });

export const getErroredRequestSummary = (id) => axios.get(`/api/TestRuns/${id}/erroredRequestsSummary`);

export const getErrorDetail = (id, time, vmInfraRunId) => axios.get(`/api/TestRuns/${id}/erroredRequests/${time}`, { params: { vmInfraRunId } });

export const resetPassword = (id, body) => axios.put(`/api/Password/reset/${id}`, body);

export const forgotPassword = (email) => axios.post(`/api/Password/forgot/${email}`, {});

export const refreshKeys = (id) => axios.get(`/api/VmInfraRuns/${id}/refresh/keys`); 

export const getPublicKey = (id) => axios.get(`/api/VmInfraRuns/${id}/publickey`); 