import React from 'react';
import { Table, Label, Button, Message, Checkbox } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { updateResource } from '../../../redux/actions/ResourceActions';

const ScenarioResource = (props) => {

  const dispatch = useDispatch();

  const { csvResourceIds, jmxResourceId, handleRemove } = props;
  let allResourceIds = [...csvResourceIds, jmxResourceId];

  const resourceList = useSelector(store => store.ResourceReducer.resourceList.filter(resource => allResourceIds.includes(resource.id)));

  const handleCheckbox = (e, { name, value }) => {
    const updatedResource = { ...value, [name]: !value[name] };
    dispatch(updateResource(updatedResource));
  };

  return (
    <>
      {resourceList.length !== 0 ?
        <>
          {(jmxResourceId === 0) && <Message
            header='There is no JMX file!'
            size="mini"
            warning
            style={{ textAlign: 'center' }}
          />}
          <Table basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Type</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Has Header</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Needs Split</Table.HeaderCell>
                <Table.HeaderCell textAlign="right"></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {resourceList.map(resource => (
                <Table.Row>
                  <Table.Cell>{resource.resourceFileName}</Table.Cell>
                  <Table.Cell textAlign="center">{resource.resourceType === 1 && <Label color="blue">JMX</Label>} {resource.resourceType === 2 && <Label color="red">CSV</Label>}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {resource.resourceType === 2 && <Checkbox onClick={handleCheckbox} name="csvHasHeader" value={resource} checked={resource.csvHasHeader} />}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {resource.resourceType === 2 && <Checkbox onClick={handleCheckbox} name="needsSplit" value={resource} checked={resource.needsSplit} />}
                  </Table.Cell>
                  <Table.Cell textAlign="right"><Button onClick={() => handleRemove(resource)} circular size="tiny" icon="remove" /></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
        :
        <Message
          header='There is no resource here!'
          size="mini"
          warning
          style={{ textAlign: 'center' }}
        />
      }
    </>
  );
};

export default ScenarioResource;