import React, { useEffect, useState } from 'react';
import { Divider, Form, Grid, Header, Icon, Table, TextArea } from 'semantic-ui-react';
import ReportStats from './ReportStats';
import ResponseTimeChart from './ResponseTimeChart';
import ThroughputLineChart from './ThroughputLineChart';

const ScenarioResults = (props) => {

  const { data, labelsDate } = props;
  const { scenarioName, requests } = data;

  const [openedRequests, setIsOpenedRequests] = useState([]);

  const openCollapse = (reqName) => {
    if (openedRequests.includes(reqName)) {
      setIsOpenedRequests(prev => prev.filter(item => item !== reqName));
    } else {
      setIsOpenedRequests(prev => [...prev, reqName]);
    }
  };

  useEffect(() => {
    if(requests){
      setIsOpenedRequests(requests.map(req => req?.requestName));
    }
   }, [requests]);

  return (
    <div style={{ marginTop: '3em' }}>
      <Header>Results for {scenarioName}</Header>
      <Divider />
      {requests && requests.map(request => (
        <div style={{ marginTop: '2em' }}>
          <span onClick={() => openCollapse(request.requestName)}>
            <Header disabled size="small"><Icon name={openedRequests.includes(request.requestName) ? "minus" : "plus"} /> Show {request.requestName} Results</Header>
          </span>
          {openedRequests.includes(request.requestName) &&
            <div style={{ marginTop: '2em' }}>
              <ReportStats
                noOfTotalRequests={request.noOfTotalRequests}
                noOfErroredRequests={request.noOfErroredRequests}
                rpmInterval={request.rpmInterval}
                averageResponseTime={request.avgResponseTime}
              />
              <Grid divided >
                <Grid.Row>
                  <Grid.Column computer={13}>
                    <ThroughputLineChart
                      requestsByInterval={request.requestsByInterval}
                      virtualUsersByInterval={request.virtualUsersByInterval}
                      labelsDate={labelsDate}
                    />
                  </Grid.Column>
                  <Grid.Column computer={3}>
                    <strong>Throughput Chart Description</strong>
                    <Form>
                      <TextArea />
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column computer={13}>
                    <ResponseTimeChart
                      meanResponsesByInterval={request.meanResponsesByInterval}
                      maxResponsesByInterval={request.maxResponsesByInterval}
                      labelsDate={labelsDate}
                    />
                  </Grid.Column>
                  <Grid.Column computer={3}>
                    <strong>Mean - Max Chart Description</strong>
                    <Form>
                      <TextArea />
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                <Table basic='very' celled>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>Error Code</Table.HeaderCell>
										<Table.HeaderCell>Count</Table.HeaderCell>
										<Table.HeaderCell>Error Details</Table.HeaderCell>
									</Table.Row>
								</Table.Header>

								<Table.Body>
									{request?.responseCodes.length ?
										<>
											{request?.responseCodes.map((item, index) => {
												return (
													<Table.Row key={item.id}>
														<Table.Cell>{item}</Table.Cell>
														<Table.Cell>{request?.sumByResponseCode[index] || 'N/A'}</Table.Cell>
														<Table.Cell>{request?.errorSamples[index] || 'N/A'}</Table.Cell>
													</Table.Row>
												)
											})}
										</>
										:
										<Table.Row>
											<Table.Cell textAlign="center" singleLine={true} colSpan='3' warning>No errors occured on this request!</Table.Cell>
										</Table.Row>
									}

								</Table.Body>
							</Table>

                </Grid.Row>
              </Grid>
            </div>
          }
        </div>
      ))}
    </div>
  );
};

export default ScenarioResults;