import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { setAuthorizationHeader } from './api/apiCalls';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import SideMenu from './layout/SideMenu';
import './styles.css';
import ClusterListPage from './pages/cluster-manage/ClusterListPage';
import CloudAccountsPage from './pages/cloud-accounts/CloudAccountsPage';
import ClientSecretModal from './components/ClientSecretModal';
import ConfirmModal from './components/ConfirmModal';
import TestSuiteListPage from './pages/test-suite/TestSuiteListPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TestSuiteCrudPage from './pages/test-suite/TestSuiteCrudPage';
import TestSuiteDetailPage from './pages/test-suite/TestSuiteDetailPage';
import TestRunPage from './pages/test-run/TestRunPage';
import TestReportPage from './pages/test-report/TestReportPage';
import TenantListPage from './admin/tenants/TenantListPage';
import UserListPage from './admin/users/UserListPage';
import { useDispatch } from 'react-redux';
import { setUserRole, fetchCurrentLoginInfo } from './redux/actions/LoginActions';
import UserCrudPage from './admin/users/UserCrudPage';
import TenantCrudPage from './admin/tenants/TenantCrudPage';
import TenantDetailsPage from './admin/tenants/TenantDetailsPage';
import MonitoringPage from './pages/monitoring/MonitoringPage';
import CreditReportPage from './pages/credit-report/CreditReportPage';
import TestRunListPage from './pages/test-runs/TestRunListPage';
import EditProfilePage from './pages/profile/EditProfilePage';
import ResetPasswordPage from './pages/profile/ResetPasswordPage';
import ForgotPasswordPage from './pages/profile/ForgotPasswordPage';

const jwtDecode = require('jwt-decode');

const token = localStorage.getItem('token');
const canAccessCreditPage = JSON.parse(localStorage.getItem('canAccessCreditPage'));
let role = null;
if (token) {
  setAuthorizationHeader(token);
  const decoded = jwtDecode(token);
  role = decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] || null;
};

const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserRole(role));
    dispatch(fetchCurrentLoginInfo());
  }, []);

  if (role && role === "SystemAdmin") {
    return (
      <Router>
        <SideMenu role={role} />
        <Container fluid style={{ marginTop: '1em', paddingLeft: 76, paddingRight: 15 }}  >
          <Switch>
            <Route exact path="/tenants" component={TenantListPage} />
            <Route exact path="/edit-tenant/:id" component={TenantCrudPage} />
            <Route exact path="/create-tenant" component={TenantCrudPage} />
            <Route exact path="/tenant-detail/:id" component={TenantDetailsPage} />
            <Route path="/users" component={UserListPage} />
            <Route path="/edit-user/:id" component={UserCrudPage} />
            <Route path="/create-user" component={UserCrudPage} />
            <Redirect to="/tenants" />
          </Switch>
        </Container>
        <ClientSecretModal />
        <ConfirmModal />
        <ToastContainer />
      </Router>
    );
  }

  if (token) {
    return (
      <Router>
        <Switch>
          <Route path="/test-run/:id/summary" component={TestReportPage} />
          <Route path="/*">
            <SideMenu role={role} canAccessCreditPage={canAccessCreditPage} />
            <Container fluid style={{ marginTop: '1em', paddingLeft: 76, paddingRight: 15 }}  >
              <Switch>
                <Route exact path="/" component={MainPage} />
                <Route path="/clusters" component={ClusterListPage} />
                <Route path="/cloud-accounts" component={CloudAccountsPage} />
                <Route path="/test-suites" component={TestSuiteListPage} />
                <Route path="/edit-suite/:id" component={TestSuiteCrudPage} />
                <Route path="/suite-details/:id" component={TestSuiteDetailPage} />
                <Route path="/create-suite" component={TestSuiteCrudPage} />
                <Route path="/test-suite/:id" component={TestRunPage} />
                <Route path="/test-run/:id/monitoring" component={MonitoringPage} />
                {(canAccessCreditPage) &&
                  <Route path="/credit-report" component={CreditReportPage} />
                }
                <Route path="/test-runs" component={TestRunListPage} />
                <Route path="/edit-profile" component={EditProfilePage} />
                {(role && role === "Admin") &&
                  <Switch>
                    <Route path="/users" component={UserListPage} />
                    <Route path="/edit-user/:id" component={UserCrudPage} />
                    <Route path="/create-user" component={UserCrudPage} />
                    <Redirect to="/" />
                  </Switch>
                }
                <Redirect to="/" />
              </Switch>
            </Container>
            <ClientSecretModal />
            <ConfirmModal />
            <ToastContainer />
          </Route>
        </Switch>
      </Router>
    )
  }

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/reset-password" component={ResetPasswordPage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Redirect to="/login" />
        </Switch>
      </Router>
    </div>
  );

}

export default App;
