import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

const defaultAws = {
  awsAccessKeyId: '',
}

const AwsForm = (props) => {

  const [aws, setAws] = useState(defaultAws);
  const { handleProviderDataChange, awsAccount } = props;

  useEffect(() => {
    handleProviderDataChange('aws', aws);
  }, [aws]);

  useEffect(() => {
    if (awsAccount) {
      setAws(awsAccount);
    } else {
      setAws(defaultAws);
    }
  }, [awsAccount]);

  const handleChange = (e, { name, value }) => {
    setAws(prevCloud => ({
      ...prevCloud,
      [name]: value
    }));
  }

  const { awsAccessKeyId } = aws;

  return (
    <Form>
      <Form.Field>
        <label>Aws Access Key Id: </label>
        <Form.Input name="awsAccessKeyId" value={awsAccessKeyId} onChange={handleChange} placeholder='Aws Access Key Id' />
      </Form.Field>
    </Form>
  );
};

export default AwsForm;