import React from 'react';
import './Sidebar.css'

const Sidebar = (props) => {

  const { visible, children } = props;

  let drawerClasses = 'side-drawer';
  if (visible) {
    drawerClasses = 'side-drawer open';
  }

  return (
    <div className={drawerClasses} {...props}>
      {children}
    </div>
  )
};

export default Sidebar;