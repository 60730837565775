import React from 'react';
import { Grid } from 'semantic-ui-react';

const MainPage = () => {

  return (
    <Grid.Row className='page-content'>
      <Grid.Column >
        <div class='segment-shadow'>
          MAIN PAGE
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};

export default MainPage;