import React from 'react';
import { Statistic, Icon } from 'semantic-ui-react';

const ReportStats = ({ noOfTotalRequests, noOfErroredRequests, rpmInterval, averageResponseTime }) => {

  const passRate = (100 / noOfTotalRequests * (noOfTotalRequests - noOfErroredRequests)).toFixed(2);
  const fixedAvg = ( averageResponseTime * 1.00 ).toFixed(2).split('.00')[0];
  return (
    <div>
      <Statistic.Group widths="4" size="tiny" >
        <Statistic>
          <Statistic.Value>
            <Icon name="tachometer alternate" circular={true} color="brown" size="small" />{rpmInterval}
          </Statistic.Value>
          <Statistic.Label>RPM INTERVAL</Statistic.Label>
        </Statistic>

        <Statistic>
          <Statistic.Value>
            <Icon name="time" circular={true} color="blue" size="small" />{fixedAvg} ms
          </Statistic.Value>
          <Statistic.Label>Avg. Response Time</Statistic.Label>
        </Statistic>

        <Statistic>
          <Statistic.Value>
            <Icon name="checkmark" circular={true} color="green" size="small" />
            {passRate} %
          </Statistic.Value>
          <Statistic.Label>Success Rate</Statistic.Label>
        </Statistic>

        <Statistic>
          <Statistic.Value>
            <Icon name="close" circular={true} color="red" size="small" />
            {(100 - passRate).toFixed(2)} %
          </Statistic.Value>
          <Statistic.Label>Error Rate</Statistic.Label>
        </Statistic>
      </Statistic.Group>
    </div>
  );
};

export default ReportStats;