import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Dropdown } from 'semantic-ui-react';
import { getErroredRequest, getErrorDetail, getErroredRequestSummary } from '../../../api/apiCalls';
import { useApiProgress } from '../../../api/ApiProgressHook';
import LoadingComponent from '../../../components/LoadingComponent';
import NoDataFound from '../../../components/NoDataFound';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import moment from 'moment';
import _ from 'lodash';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/shell/shell';
import 'codemirror/mode/yaml/yaml';
import 'codemirror/mode/javascript/javascript';
import '../../cluster-manage/components/VmInfraRunLog.css';

const codeMirrorOptions = {
  lineNumbers: true,
};

const ErroredRequests = ({ testRunId, handleChangeFilterDropdown, filterParams }) => {

  const [erroredRequestList, setErroredRequestList] = useState([]);
  const [selectedErrorRequest, setSelectedErrorRequest] = useState('');
  const [pendingApiCall, setPendingApiCall] = useState(false);
  const [error, setError] = useState();
  const [scenarioOptionList, setScenarioOptionList] = useState([]);
  const [requestOptionList, setRequestOptionList] = useState([]);
  const [ResponseCodeOptionList, setResponseCodeOptionList] = useState([]);

  useEffect(() => {
    fetchErroredRequestSummary();
  }, [testRunId]);

  useEffect(() => {
    fetchErroredRequestList();
  }, [filterParams, testRunId]);

  const fetchErroredRequestList = async () => {
    setPendingApiCall(true);
    try {
      const response = await getErroredRequest(testRunId, filterParams);
      setErroredRequestList(response.data.result);
    } catch (error) {
      setError(error.response && error.response.data.result);
    }
    setPendingApiCall(false);
  };

  const fetchErroredRequestSummary = async () => {
    try {
      const response = await getErroredRequestSummary(testRunId);
      const scenarios = _.uniqBy(response.data.result, e => e.scenarioName);
      const requests = _.uniqBy(response.data.result, e => e.requestName);
      const responseCodes = _.uniqBy(response.data.result, e => e.responseCode);
      setScenarioOptionList(scenarios.map((item, index) => ({ key: index, text: item.scenarioName, value: item.scenarioName })));
      setRequestOptionList(requests.map((item, index) => ({ key: index, text: item.requestName, value: item.requestName })));
      setResponseCodeOptionList(responseCodes.map((item, index) => ({ key: index, text: item.responseCode, value: item.responseCode })));
    } catch (error) {
      setError(error.response && error.response.data.result);
    }
  };

  const getErrorData = async (time, vmInfraRunId) => {
    try {
      const response = await getErrorDetail(testRunId, time, vmInfraRunId);
      let reqRespContent = '';

      reqRespContent += '****************************\n';
      reqRespContent += '****** Request Headers *****\n';
      reqRespContent += '****************************\n\n';
      reqRespContent += response.data.result.requestHeaders + '\n\n';
      reqRespContent += '****************************\n';
      reqRespContent += '******* Request Data *******\n';
      reqRespContent += '****************************\n\n';
      reqRespContent += response.data.result.requestData + '\n\n';
      reqRespContent += '****************************\n';
      reqRespContent += '***** Response Headers *****\n';
      reqRespContent += '****************************\n\n';
      reqRespContent += response.data.result.responseHeaders + '\n\n';
      reqRespContent += '****************************\n';
      reqRespContent += '****** Response Data *******\n';
      reqRespContent += '****************************\n\n';
      reqRespContent += response.data.result.responseData + '\n\n';
      setSelectedErrorRequest(reqRespContent);
    } catch (error) {

    }
  };

  const { scenarioName, requestName, responseCode } = filterParams;

  return (
    <div>
      <div>
        <Dropdown
          placeholder='Scenario'
          icon='filter'
          floating
          labeled
          button
          clearable
          className='icon'
          name="scenarioName"
          options={scenarioOptionList}
          onChange={handleChangeFilterDropdown}
          value={scenarioName}
        />
        <Dropdown
          placeholder='Request'
          icon='filter'
          floating
          labeled
          button
          clearable
          className='icon'
          name="requestName"
          options={requestOptionList}
          onChange={handleChangeFilterDropdown}
          value={requestName}
        />
        <Dropdown
          placeholder='Response Code'
          icon='filter'
          floating
          labeled
          button
          clearable
          className='icon'
          name="responseCode"
          options={ResponseCodeOptionList}
          onChange={handleChangeFilterDropdown}
          value={responseCode}
        />
        <span style={{ float: 'right' }}>
          Total Error: <strong>{erroredRequestList.length}</strong>
        </span>
      </div>
      {(pendingApiCall) ?
        <LoadingComponent msg="Test Errors Loading" />
        :
        <>
          {error || !erroredRequestList.length ?
            <NoDataFound msg={error ? error.message : 'There is no error here!'} />
            :
            <>
              <Table celled size="small" selectable sortable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Scenario
                    </Table.HeaderCell>
                    <Table.HeaderCell> Request
                    </Table.HeaderCell>
                    <Table.HeaderCell>Time</Table.HeaderCell>
                    <Table.HeaderCell>Response Code</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {erroredRequestList.map((error) => (
                    <Table.Row>
                      <Table.Cell>{error.scenarioName}</Table.Cell>
                      <Table.Cell>{error.requestName}</Table.Cell>
                      <Table.Cell>{moment(error.time).fromNow()}</Table.Cell>
                      <Table.Cell>{error.responseCode}</Table.Cell>
                      <Table.Cell textAlign='center' width={1} >
                        <Modal onUnmount={() => setSelectedErrorRequest('')}
                          onMount={() => getErrorData(error.time, error.vmInfraRunId)} closeIcon size="large"
                          trigger={<Button color='blue' icon='file' />}>
                          <Modal.Header content="Request/Response for Errored Sample" />
                          <Modal.Content>
                            <CodeMirror
                              value={selectedErrorRequest}
                              options={codeMirrorOptions}
                            />
                          </Modal.Content>
                        </Modal>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          }
        </>
      }
    </div >
  );
};

export default ErroredRequests;