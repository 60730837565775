import React, { useState, useEffect } from 'react';
import { Form, Button, Loader, Message, Header, Segment, Input, Icon } from 'semantic-ui-react';
import { resetPassword } from '../../api/apiCalls';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import LoginLayout from '../../layout/LoginLayout';

const ResetPasswordPage = (props) => {

  const { location, history } = props;
  const params = queryString.parse(location.search);
  const { userId, token } = params;

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [successed, setSuccessed] = useState(false);
  const [error, setError] = useState();
  const [repeatPassErr, setRepeatPassErr] = useState();

  useEffect(() => {
    if (!token || !userId) {
      history.push('/login');
    }

    return function unmount() {
      setSuccessed(false);
    };
  }, [userId, token]);

  useEffect(() => {
    setError();
    setRepeatPassErr();
  }, [password, passwordRepeat]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordRepeat) {
      setRepeatPassErr("Passwords don't match");
    } else {
      setError();
      setInProgress(true);
      setRepeatPassErr();
      try {
        await resetPassword(userId, { token, newPassword: password });
        setSuccessed(true);
      } catch (err) {
        setError(err.response.data.error || error);
      }
      setInProgress(false);
    }
  };

  return (

    <LoginLayout>
      <Form size='large' error>
        <Segment style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Header as='h3' style={{ color: '#172B4D' }} textAlign='center'>
            Reset Password
        </Header>
          {successed ?
            <Message>Password  successfully changed.</Message>
            :
            <>
              <Form.Field>
                <Input fluid size='big'
                  icon='lock'
                  iconPosition='left' name="password" type="password" placeholder='New Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Field>
              <Form.Field error={repeatPassErr}>
                <Input fluid size='big'
                  icon='lock'
                  iconPosition='left' name="repeatPassword" type="password" placeholder='Repeat New Password'
                  value={passwordRepeat }
                  onChange={(e) => setPasswordRepeat(e.target.value)}
                />
                {repeatPassErr && <label color='red'>{repeatPassErr}</label>}
              </Form.Field>
              <Message negative hidden={!error}>
                <Message.Header>{error?.message}</Message.Header>
                <p>{error?.details}</p>
              </Message>
              <Button primary fluid size='big' onClick={handleSubmit} type='submit' disabled={inProgress || !passwordRepeat || !password} >
                <Loader inline size="mini" active={inProgress} /> Submit
              </Button>
            </>
          }
        </Segment>
      </Form>
      <Message warning><Link to='/login'><Icon name="sign-in" /> Login Page</Link></Message>
    </LoginLayout>
  );
};

export default ResetPasswordPage;