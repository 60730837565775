import React, { useEffect, useState, useRef } from 'react';
import { Grid, Header, Icon, Button, Table, Dropdown, Popup, Input, Label } from 'semantic-ui-react';
import { getTenants, deleteTenant } from '../../api/apiCalls';
import { useApiProgress } from '../../api/ApiProgressHook';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { openConfirmModal, closeConfirmModal } from '../../redux/actions/ModalActions';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';

const TenantListPage = (props) => {

  const [tenantList, setTenantList] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [error, setError] = useState();
  const [selectedTenantId, setSelectedTenantId] = useState();
  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const searchRef = useRef();

  const dispatch = useDispatch();
  const pendingApiCall = useApiProgress('get', '/api/Tenants');

  useEffect(() => {
    fetchTenantList();
  }, []);

  const fetchTenantList = async () => {
    try {
      const response = await getTenants();
      setTenantList(response.data.result.items);
    } catch (err) {
      setError(err.response && err.response.data.error);
    }
  };

  const handleDeleteButton = () => {
    dispatch(openConfirmModal(deleteTenantFunc, "Delete Tenant", `Are you sure you want to delete tenant?`));
  };

  const deleteTenantFunc = async () => {
    try {
      await deleteTenant(selectedTenantId);
      fetchTenantList();
    } catch (err) {
      toast.error(err.response && err.response.data.error.message);
    }
    dispatch(closeConfirmModal());
  };

  useEffect(() => {
    setTimeout(() => {
      if (!search || (search && search.length < 1)) {
        setSearchedList([]);
        setIsLoading(false);
      } else {
        const re = new RegExp(_.escapeRegExp(search), 'i');
        const isMatch = (result) => re.test(result.name);

        setIsLoading(false);
        setSearchedList(_.filter(tenantList, isMatch));
      }
    }, 300);
  }, [search]);

  const handleSearchChange = (e) => {
    setIsLoading(true);
    setSearch(e.target.value);
  };

  const tenants = searchedList.length ? searchedList : tenantList;

  return (
    <Grid >
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name='users' />
              </div>
              <Header.Content>
                Tenants
                <Header.Subheader>Manage tenants</Header.Subheader>
              </Header.Content>
            </Header>
          </div>

          <div className="page-actions">
            <Button onClick={() => props.history.push('/create-tenant')}>
              New Tenant
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <div className="shadow" style={{ padding: 22 }}>
            <Table celled size="small" selectable sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name
                    <Popup on="click" onUnmount={() => setSearch()} onMount={() => searchRef.current.focus()} trigger={<span style={{ float: "right" }}><Icon name='search' /></span>}>
                      <Input loading={isLoading} ref={searchRef} focus icon='search' placeholder='Search...' onChange={_.debounce(handleSearchChange, 500, { leading: true })} />
                    </Popup>
                  </Table.HeaderCell>
                  <Table.HeaderCell>Tenancy Name</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Tenant Status</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {tenants.map((tenant) => (
                  <Table.Row>
                    <Table.Cell>
                      <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[search]}
                        autoEscape
                        textToHighlight={tenant.name}
                      />
                    </Table.Cell>
                    <Table.Cell>{tenant.tenancyName}</Table.Cell>
                    <Table.Cell textAlign="center">{tenant.isActive ? <Label color='green' content='Active' /> : <Label color='red' content='Inactive' />}</Table.Cell>
                    <Table.Cell textAlign='center' width={1} >
                      <Button.Group color='teal'>
                        <Button icon="arrow right" onClick={() => props.history.push(`/tenant-detail/${tenant.id}`)} />
                        <Dropdown
                          className='button icon'
                          floating
                          onClick={() => setSelectedTenantId(tenant.id)}
                        >
                          <Dropdown.Menu direction='left'>
                            <Dropdown.Item onClick={() => props.history.push(`/edit-tenant/${tenant.id}`)}><Icon name="edit" /> Edit Tenant</Dropdown.Item>
                            <Dropdown.Item onClick={handleDeleteButton}>
                              <Icon name="trash alternate outline" /> Delete Tenant
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default TenantListPage;