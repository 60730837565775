import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { fetchSpentReport } from '../../../api/apiCalls';
import { Table, Header, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import NoDataFound from '../../../components/NoDataFound';
import LoadingComponent from '../../../components/LoadingComponent';
import { useApiProgress } from '../../../api/ApiProgressHook';
import _ from 'lodash';

const creditSpentType = ['Other', 'Usage', 'Expirity', 'Other'];

const SpentListComponent = ({ startDate, endDate, tenantId }) => {

  const [creditSpents, setCreditSpents] = useState({});
  const [pendingApiCall, setPendingApiCall] = useState(false);

  useEffect(() => {
    fetchSpentHistory();
  }, [startDate, endDate, tenantId]);


  const fetchSpentHistory = async () => {
    const start = moment(startDate).format('MM/DD/YYYY HH:mm:ss Z');
    const end = moment(endDate.setHours(23, 59, 59, 0)).format('MM/DD/YYYY HH:mm:ss Z');
    if (tenantId) {
      setPendingApiCall(true);
      try {
        const response = await fetchSpentReport(tenantId, start, end);
        let sortedResult = _.orderBy(response.data.result.details, ['creationTime'], ['desc']);
        setCreditSpents({ ...response.data.result, details: sortedResult });
      } catch (err) { }
      setPendingApiCall(false);
    }
  };

  const spentList = creditSpents.details || [];
  const { sum } = creditSpents;

  return (
    <div>
      <Header as='h3'>
        <Icon name='shopping basket' />
        <Header.Content>
          Credit Spents
          <Header.Subheader>Total spent: {sum || 'N/A'} </Header.Subheader>
        </Header.Content>
      </Header>

      {pendingApiCall ?
        <LoadingComponent msg="Loading" />
        :
        <>
          {!spentList.length ?
            <NoDataFound msg="There is no spent here!" />
            :
            <>
              <Table basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Spent Type</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Spent Time</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Amount</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Description</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right"></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {spentList.map(load => (
                    <Table.Row>
                      <Table.Cell>{load.creditSpentTypeId ? creditSpentType[load.creditSpentTypeId] : 'Other'}</Table.Cell>
                      <Table.Cell textAlign="center">{moment(load.creationTime).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                      <Table.Cell textAlign="center">{load.amount}</Table.Cell>
                      <Table.Cell textAlign="center">{load.description}</Table.Cell>
                      <Table.Cell textAlign="right"><Button as={Link} to={`/test-run/${load.testRunId}/monitoring`}>Result</Button></Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          }
        </>
      }
    </div>
  );
};

export default SpentListComponent;