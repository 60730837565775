import React, { useEffect, useState } from 'react';
import { Grid, Header, Icon, Button, Form, Popup, ListItem, Input, Loader, List, Card, Label } from 'semantic-ui-react';
import { getTestSuite, createTestScenario, deleteTestScenario, duplicateTestScenario, updateTestSuite } from '../../api/apiCalls';
import { useApiProgress } from '../../api/ApiProgressHook';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { openConfirmModal, closeConfirmModal } from '../../redux/actions/ModalActions';
import { toast } from 'react-toastify';
import _ from 'lodash';
import ScenarioCard from './components/ScenarioCard';
import { fetchTestResources } from '../../redux/actions/ResourceActions';
import { resetTestRunVariation, updateTestSuiteVariation } from '../../redux/actions/TestRunActions';
import TestRunSideMenu from './components/TestRunSideMenu';
import EditInformationModal from './components/EditInformationModal';
import SummaryAndTestRunModal from './components/SummaryAndTestRunModal';
import { creditCalculation } from '../../shared/Utils';

const TestRunPage = (props) => {

  const suiteId = props.match.params.id;

  const [suite, setSuite] = useState({});
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [summaryModalVisible, setSummaryModalVisible] = useState(false);
  const [error, setError] = useState();
  const [suiteValuesError, setSuiteValuesError] = useState({ testDurationInSeconds: null, noOfTotalUsers: null });

  const scenarioProgress = useApiProgress('post', '/api/TestScenarios');
  const suiteProgress = useApiProgress('put', '/api/TestSuites/');

  const dispatch = useDispatch();

  useEffect(() => {
    fetchTestSuite();
    dispatch(fetchTestResources());

    return function unmount() {
      dispatch(resetTestRunVariation());
    };
  }, []);

  useEffect(() => {
    dispatch(updateTestSuiteVariation(suite));
  }, [suite]);

  const fetchTestSuite = async () => {
    if (suiteId) {
      try {
        const response = await getTestSuite(suiteId);
        const sortedScenarios = _.orderBy(response.data.result.scenarios, ['lastModificationTime'], ['desc']);
        setSuite({ ...response.data.result, scenarios: sortedScenarios });
      } catch (err) { }
    }
  };

  const { id, name, description, noOfTotalUsers, testDurationInSeconds, lastModificationTime, scenarios } = suite;

  const updateInProgress = useApiProgress('put', '/api/TestSuites/' + id);

  const createScenario = async () => {
    const body = {
      name: `Scenario_${new Date().valueOf()}`,
      testSuiteId: id,
      jmxResourceId: 0,
      noOfLoops: 0,
      rampUpTimeInSec: 0,
      noOfMaxUsers: 200,
      weight: 100
    };
    try {
      const response = await createTestScenario(body);
      const newScenarios = [...suite.scenarios];
      newScenarios.unshift(response.data.result);
      setSuite(prev => ({
        ...prev,
        scenarios: newScenarios
      }));
    } catch (err) {
      toast.error(err.response && err.response.data.error.message)
    }
  };

  const duplicateScenario = async (id) => {
    try {
      const response = await duplicateTestScenario(id);
      const newScenarios = [...suite.scenarios];
      newScenarios.unshift(response.data.result);
      setSuite(prev => ({
        ...prev,
        scenarios: newScenarios
      }));
    } catch (err) {
      toast.error(err.response && err.response.data.error.message)
    }
  };


  const handleDeleteButton = (id) => {

    const deleteScenario = async () => {
      try {
        await deleteTestScenario(id);
        fetchTestSuite();
      } catch (err) {
        toast.error(err.response && err.response.data.error.message);
      }
      dispatch(closeConfirmModal());
    };

    dispatch(openConfirmModal(deleteScenario, 'Delete Scenario', 'Are you sure you want to delete scenario?'));
  };

  const updateSuite = async (newSuite) => {
    try {
      const response = await updateTestSuite(id, newSuite);
      fetchTestSuite();
      return response;
    } catch (err) {
      setError(err.response && err.response.data.error);
      throw err;
    }
  };

  const handleNumberChange = _.debounce((e, { name, value, min, max }) => {
    let error = null;
    if (value || value !== '') {
      let newValue = parseInt(value, 10);
      if (newValue < min) {
        error = `Min value ${min}`;
      } else if (max && newValue > max) {
        error = `Max value ${max}`;
      }
    } else {
      error = `Can not be empty`;
    }
    setSuiteValuesError(prev => ({ ...prev, [name]: error }));
    if (!error) {
      const newSuite = {
        ...suite,
        [name]: parseInt(value, 10)
      };
      updateSuite(newSuite);
    }
  }, 1000);

  const { testRunButtonEnabled, testRunVariationError } = useSelector(store => store.TestRunReducer);

  return (
    <Grid >
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name='list' />
              </div>
              <Header.Content>
                {name} <ListItem as='a' onClick={() => setEditModalOpened(true)}><Icon name="pencil alternate" /></ListItem>
                <Header.Subheader>{description || 'No description'}</Header.Subheader>
              </Header.Content>
            </Header>
          </div>

          <div className="page-actions">
            {!testRunButtonEnabled && <Popup trigger={<Icon size="large" color="red" name="warning circle" />}>
              <List style={{ color: 'red' }}>
                {Object.entries(testRunVariationError).map(([key, value]) => {
                  if (value) {
                    return (
                      <List.Item
                        icon='warning circle'
                        content={value}
                      />
                    );
                  }
                })}
              </List>
            </Popup>}
            <Button icon disabled={!testRunButtonEnabled} onClick={() => setSummaryModalVisible(true)}>
              <Icon name="play" />  NEXT
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width='10'>
                <Card className="shadow" fluid style={{ padding: 16 }}>
                  <Header as='h3'>
                    Suite Configuration
                    <Header.Subheader>
                      {updateInProgress && <Loader active inline size="mini" />}
                      Updated {moment(lastModificationTime).fromNow()}
                    </Header.Subheader>
                  </Header>
                  <Form>
                    <Form.Group widths='equal'>
                      <Form.Field error={suiteValuesError.noOfTotalUsers}>
                        <label>Number of Users</label>
                        <Input icon="user" iconPosition="left" name="noOfTotalUsers" min={1} defaultValue={noOfTotalUsers} onChange={handleNumberChange} type="number" />
                      </Form.Field>
                      <Form.Field error={suiteValuesError.testDurationInSeconds}>
                        <label>Test Duration in Seconds (0 to run until tests stop themselves)</label>
                        <Input icon="time" iconPosition="left" name="testDurationInSeconds" min={0} defaultValue={testDurationInSeconds} onChange={handleNumberChange} type="number" />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                  <Label attached="top right">{`Approximately costs ${creditCalculation(testDurationInSeconds, noOfTotalUsers)} credit(s).`}</Label>
                </Card>
                <div className="shadow" style={{ padding: 16, marginTop: 16 }}>
                  <div style={{ marginBottom: 40 }}>
                    <span style={{ float: "left" }}>
                      <h3>Scenarios</h3>
                    </span>
                    <span style={{ float: "right" }}>
                      <Button disabled={scenarioProgress} loading={scenarioProgress} onClick={createScenario} color='blue'  size="tiny"><Icon name="plus" />New Scenario</Button>
                    </span>
                  </div>

                  {scenarios && scenarios.map(scenario => (
                    <ScenarioCard testDurationInSeconds={testDurationInSeconds} key={scenario.id} data={scenario} handleDelete={handleDeleteButton} duplicateScenario={duplicateScenario} />
                  ))}
                </div>
              </Grid.Column>
              <Grid.Column width='6'>
                <TestRunSideMenu />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <EditInformationModal open={editModalOpened} handleClose={() => setEditModalOpened(false)}
        data={suite}
        saveFunction={updateSuite}
        txt="Suite"
        inProgress={suiteProgress}
      />
      <SummaryAndTestRunModal visible={summaryModalVisible} handleClose={() => setSummaryModalVisible(false)} />
    </Grid>
  );
};

export default TestRunPage;