import React, { useState, useEffect } from 'react';
import { Segment, Button, Header, Icon, Form, Input, Radio, Divider, Grid, Message, Dimmer, Loader, Checkbox } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { getTenant, updateTenant, createTenant } from '../../api/apiCalls';
import { useApiProgress } from '../../api/ApiProgressHook';

const defaultTenant = {
  name: '',
  tenancyName: '',
  AdminEmailAddress: '',
  canAccessNewUi: false,
  canAccessOldUi: false,
  isActive: true
};

const TenantCrudPage = (props) => {

  const tenantId = props.match.params.id;

  const [tenant, setTenant] = useState(defaultTenant);
  const [error, setError] = useState();
  const [inProgress, setInProgress] = useState(false);

  const pendingApiCall = useApiProgress('get', '/api/Tenants/' + tenantId);

  useEffect(() => {
    fetchTenant();
  }, []);

  useEffect(() => {
    setError();
  }, [tenant]);

  const fetchTenant = async () => {
    if (tenantId) {
      try {
        const response = await getTenant(tenantId);
        setTenant(response.data.result);
      } catch (err) { }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTenant(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCheckBoxChange = (e, { name }) => {
    setTenant(prev => ({
      ...prev,
      [name]: !prev[name]
    }));
  };

  const { AdminEmailAddress, isActive, name, tenancyName, canAccessNewUi, canAccessOldUi } = tenant;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError();
    setInProgress(true);
    try {
      let response;
      if (tenantId) {
        let body = { name, tenancyName, isActive, canAccessNewUi, canAccessOldUi };
        if (!tenantId) {
          body['AdminEmailAddress'] = AdminEmailAddress;
        }
        response = await updateTenant(tenantId, body);
      } else {
        response = await createTenant(tenant);
      }
      props.history.push(`/tenants`);
    } catch (err) {
      setError(err.response.data.error);
    }
    setInProgress(false);
  };

  const title = `${tenantId ? 'Edit ' : ' Create new'} Tenant`;

  return (
    <Grid >
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <div className="page-icon">
                <Icon name={tenantId ? 'edit' : 'plus'} />
              </div>
              <Header.Content>
                {title}
                <Header.Subheader>{title} and manage credits</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div className="page-actions">
            <Button as={Link} to="/tenants">Back </Button>
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>

          <Segment className="shadow">
            {pendingApiCall &&
              <Dimmer active inverted>
                <Loader size='large'>Loading</Loader>
              </Dimmer>
            }
            <Form onSubmit={handleSubmit} required>
              <Form.Field>
                <label>Name</label>
                <Input name="name" onChange={handleChange} placeholder='Name' defaultValue={name} />
              </Form.Field>

              <Form.Field required>
                <label>Tenancy Name</label>
                <Input name="tenancyName" onChange={handleChange} placeholder='Tenanncy Name' defaultValue={tenancyName} />
              </Form.Field>

              {!tenantId &&
                <Form.Field required>
                  <label>Email</label>
                  <Input type="email" name="AdminEmailAddress" onChange={handleChange} placeholder='Admin email adress' defaultValue={AdminEmailAddress} />
                </Form.Field>
              }

              <Form.Field>
                <label>Tenant Acccesses</label>
                <Form.Group>
                  <Form.Field>
                    <Checkbox checked={canAccessNewUi} label="Access New Features" name="canAccessNewUi" onClick={handleCheckBoxChange} />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox checked={canAccessOldUi} label="Access Old Features" name="canAccessOldUi" onClick={handleCheckBoxChange} />
                  </Form.Field>
                </Form.Group>
              </Form.Field>

              <Form.Field>
                <label>Tenant Status</label>
                <Radio checked={isActive} toggle
                  label={`Tenant is ${isActive ? 'active' : 'inactive'}`}
                  onClick={handleCheckBoxChange}
                  name="isActive"
                />
              </Form.Field>

              <Divider />
              {error &&
                <Message negative>
                  <Message.Header>{error.message}</Message.Header>
                  <p>{error.details}</p>
                </Message>
              }
              <Grid.Row>
                <Grid.Column>
                  <Button disabled={inProgress} loading={inProgress} fluid size="large" type="submit" primary >
                    {tenantId ? 'Update Tenant' : 'Create Tenant'}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
};

export default TenantCrudPage;