import * as actions from '../consts';

const defaultState = {
  tenant: null,
  user: null,
  userRole: null
};

const LoginReducer = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case actions.SET_USER_ROLE:
      return {
        ...state,
        userRole: action.role
      };

    case actions.SET_CURRENT_LOGIN_INFO:
      return {
        ...state,
        tenant: action.info.tenant,
        user: action.info.user
      };

    case actions.RESET_LOGIN_REDUCER:
      return { ...defaultState };

    default:
      return state;
  }
};

export default LoginReducer;