import React from 'react'

const NoDataFound = (props) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 30 }}>
      <div>
        <h3 style={{ color: '#dedede' }}>{props.msg}</h3>
      </div>
      {props.children &&
        <div style={{ marginTop: 10 }}>
          {props.children}
        </div>
      }
      <div>
        <img src='/images/searching.png' alt="Not Found" style={{ height: 200 }} />
      </div>
    </div>)
}

export default NoDataFound;