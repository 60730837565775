import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSecretModal } from '../redux/actions/CloudActions';

const ClientSecretModal = () => {

  const [secret, setSecret] = useState('');
  const [destroyWithIp, setDestroyWithIp] = useState(true);

  const dispatch = useDispatch();
  const { visible, submitFunc, btnTxt, headerTxt, isDestroyAction } = useSelector(store => store.CloudReducer.clientSecretModal);

  const handleClose = () => {
    dispatch(closeSecretModal());
  };

  useEffect(() => {
    return function unmount() {
      setSecret('');
      setDestroyWithIp(false)
    };
  }, [visible]);

  const handleSecretChange = (e, { value }) => {
    setSecret(value);
  };

  return (
    <Modal open={visible} onClose={handleClose}>
      <Modal.Header>{headerTxt}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input onChange={handleSecretChange} label='Client Secret' type="password" />
          {isDestroyAction && <Form.Checkbox value={destroyWithIp} onChange={() => setDestroyWithIp(prev => !prev)} label='Destroy IP Prefix' />}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          positive
          icon='checkmark'
          labelPosition='right'
          content={btnTxt}
          disabled={secret === ''}
          onClick={() => { submitFunc(secret, destroyWithIp); handleClose() }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ClientSecretModal;