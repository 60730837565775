import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Grid, Loader, Message, Icon, Label, Segment, Header, Image, Input } from 'semantic-ui-react';
import { login, setAuthorizationHeader } from '../api/apiCalls';
import LoginLayout from '../layout/LoginLayout';
import { Link } from 'react-router-dom';

const LoginPage = (props) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState();
  const { push } = props.history;

  useEffect(() => {
    setError();
  }, [username, password]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    setError();
    setInProgress(true);
    try {
      const response = await login({
        UsernameOrEmailAddress: username,
        Password: password
      });
      setAuthorizationHeader(response.data.result.accessToken);
      localStorage.setItem('token', response.data.result.accessToken);
      window.location.reload();
    } catch (err) {
      setError(err.response?.data?.error || error);
    }
    setInProgress(false);
  }

  return (
    <LoginLayout>
      <Form size='large' error>
        <Segment style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Header as='h3' style={{ color: '#172B4D' }} textAlign='center'>
            Log-in to your account
              </Header>

          <Form.Field>
            <Input size='big' fluid name="email" icon='user' iconPosition='left'
              value={username}
              placeholder='Username'
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Field>

          <Form.Field>
            <Input fluid size='big'
              icon='lock'
              iconPosition='left' name="password" type="password" placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Field>
          <Message negative hidden={!error}>
            <Message.Header>{error?.message}</Message.Header>
            <p>{error?.details}</p>
          </Message>
          <Button primary fluid size='big' onClick={handleSubmit} type='submit' disabled={inProgress || !password || !username} >
            <Loader inline size="mini" active={inProgress} /> Login
             </Button>
        </Segment>
      </Form>
      <Message warning>Did you forget password? <Link to='/forgot-password'> Click Here </Link></Message>
    </LoginLayout>
  );
};

export default LoginPage;