import React from 'react';
import ChartJsGraph from '../../../components/ChartJsGraph';
import moment from 'moment';

const ResponseTimeChart = (props) => {

  const meanMaxResponseTimesLineChart = {
    type: 'line',
    data: {
      labels: props.labelsDate,
      datasets: [
        {
          label: 'Mean Response Times',
          yAxisID: 'mean-response-times-axis',
          fillColor: 'rgba(220,220,220,0.2)',
          strokeColor: 'rgba(220,220,220,1)',
          pointColor: 'rgba(220,220,220,1)',
          pointStrokeColor: '#fff',
          pointHighlightFill: '#fff',
          pointHighlightStroke: 'rgba(220,220,220,1)',
          data: props.meanResponsesByInterval,
          backgroundColor: ['rgba(105, 112, 213, 0.1)'],
        },
        {
          label: 'Max Response Times',
          yAxisID: 'max-response-times-axis',
          illColor: 'rgba(151,187,205,0.2)',
          strokeColor: 'rgba(151,187,205,1)',
          pointColor: 'rgba(151,187,205,1)',
          pointStrokeColor: '#fff',
          pointHighlightFill: '#fff',
          pointHighlightStroke: 'rgba(151,187,205,1)',
          data: props.maxResponsesByInterval,
          backgroundColor: ['rgba(105, 112, 213, 0.05)'],
        },
      ],
    },
    options: {
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                millisecond: 'HH:mm:ss',
                second: 'HH:mm:ss',
                minute: 'HH:mm:ss',
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
            },
            id: 'mean-response-times-axis',
            type: 'linear',
            position: 'left',
            offset: true,
            scaleLabel: {
              display: true,
              labelString: 'Mean (ms)',
            },
          },
          {
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
            },
            id: 'max-response-times-axis',
            type: 'linear',
            position: 'right',
            offset: true,
            scaleLabel: {
              display: true,
              labelString: 'Max (ms)',
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return moment(tooltipItem[0].xLabel).format('HH:mm:ss.SSS');
          },
          label: function (tooltipItem, data) {
            return tooltipItem.yLabel;
          },
        },
      },
    },
  };

  return (
    <ChartJsGraph details={meanMaxResponseTimesLineChart} />
  );
};

export default ResponseTimeChart;