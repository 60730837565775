import React, { useState, useEffect } from 'react';
import { Form, Button, Icon, Message } from 'semantic-ui-react';
import AzureForm from './providers/AzureForm';
import AwsForm from './providers/AwsForm';
import GoogleCloudForm from './providers/GoogleCloudForm';

const defaultCloudAccount = {
  name: '',
  description: '',
  providerId: null,
  azure: null,
  aws: null,
  digitalOcean: null,
  google: null,
  onPrem: null
};

const CloudAccountCrudForm = (props) => {

  const [cloudAccount, setCloudAccount] = useState(defaultCloudAccount);
  const [err, setErr] = useState();
  const { cloudAccount: cloudPropData, saveAction, inProgress, error, providers } = props;

  useEffect(() => {
    if (cloudPropData) {
      setCloudAccount(cloudPropData);
    } else {
      setCloudAccount(defaultCloudAccount)
    }
  }, [cloudPropData]);

  useEffect(() => {
    setErr(error)
  }, [error]);

  useEffect(() => {
    setErr()
  }, [cloudAccount]);

  const handleChange = (e, { name, value }) => {
    setCloudAccount(prevCloud => ({
      ...prevCloud,
      [name]: value
    }));
  };

  const handleProviderSelectChange = (e, { value }) => {
    const newCloudAcc = {
      ...cloudAccount,
      providerId: value,
      azure: null,
      aws: null,
      digitalOcean: null,
      google: null,
      onPrem: null
    }

    setCloudAccount(newCloudAcc);
  };

  const handleProviderDataChange = (provider, data) => {
    setCloudAccount(prevCloud => ({
      ...prevCloud,
      [provider]: data
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveAction(cloudAccount);
  };

  const { name, description, providerId, azureAccount, awsAccount, googleAccount } = cloudAccount;

  return (
    <Form>
      <Form.Field>
        <label>Name: </label>
        <Form.Input name="name" value={name} onChange={handleChange} placeholder='Name' />
      </Form.Field>
      <Form.Field>
        <label>Description: </label>
        <Form.Input name="description" value={description} onChange={handleChange} placeholder='Description' />
      </Form.Field>

      <Form.Field>
        <label>Select Cloud Provider </label>
        <Form.Dropdown
          placeholder='Select cloud provider'
          fluid
          selection
          className='icon'
          name="providerId"
          value={providerId}
          options={providers}
          onChange={handleProviderSelectChange}
        />
      </Form.Field>

      {providerId === 1 &&
        <AzureForm handleProviderDataChange={handleProviderDataChange} azureAccount={azureAccount} />
      }
      {providerId === 2 &&
        <AwsForm handleProviderDataChange={handleProviderDataChange} awsAccount={awsAccount} />
      }
      {providerId === 3 &&
        <DigitalOceanForm handleProviderDataChange={handleProviderDataChange} />
      }
      {providerId === 4 &&
        <GoogleCloudForm handleProviderDataChange={handleProviderDataChange} googleAccount={googleAccount} />
      }
      {providerId === 5 &&
        <OnPremForm handleProviderDataChange={handleProviderDataChange} />
      }
      {err &&
        <Message negative>
          <Message.Header>{err.message}</Message.Header>
          <p>{err.details}</p>
        </Message>
      }
      <div style={{ marginTop: 22, marginBottom: 22, float: "right" }} >
        <Button color='teal' onClick={handleSubmit} disabled={inProgress} loading={inProgress} >
          <Icon name='checkmark' /> Save
        </Button>
      </div>
    </Form>
  );
};

const OnPremForm = (props) => {
  const { handleProviderDataChange } = props;

  useEffect(() => {
    handleProviderDataChange('onPrem', {});
  }, []);

  return <></>
};

const DigitalOceanForm = (props) => {
  const { handleProviderDataChange } = props;

  useEffect(() => {
    handleProviderDataChange('digitalOcean', {});
  }, []);

  return <></>
};

export default CloudAccountCrudForm;