import { fetchCloudInventories } from '../../api/apiCalls';
import * as actions from '../consts';

export const getCloudInventoriesSuccess = (data) => ({
  type: actions.FETCH_CLOUD_INVENTORIES_SUCCESS,
  data: data
});

export const getCloudInventories = () => {
  return async function (dispatch) {
    const response = await fetchCloudInventories();

    dispatch(getCloudInventoriesSuccess(response.data.result));
    return response;
  }
};

export const fetchClusterListAgain = (value = false) => ({
  type: actions.FETCH_CLUSTER_LIST_AGAIN,
  data: value
});

export const openSecretModal = (submitFunc, btnTxt, headerTxt, isDestroyAction = false) => ({
  type: actions.OPEN_CLIENT_SECRET_MODAL,
  data: { submitFunc, btnTxt, headerTxt, isDestroyAction }
});

export const closeSecretModal = () => ({
  type: actions.CLOSE_CLIENT_SECRET_MODAL
});