import React, { useState, useEffect } from 'react';
import { Label, Table, Header, Button } from 'semantic-ui-react';
import { creditLoadTypeList } from './LoadCreditForm';
import LoadCreditForm from './LoadCreditForm';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { fetchLoadReport, loadCredit } from '../../../api/apiCalls';

const LoadDetails = (props) => {

  const [creditLoads, setCreditLoads] = useState({});
  const [loadFormVisible, setLoadFormVisible] = useState(false);

  const date = new Date();

  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(date);

  const { tenantId } = props;

  useEffect(() => {
    fetchLoadHistory();
  }, [startDate, endDate]);


  const fetchLoadHistory = async () => {
    const start = moment(startDate).format('MM/DD/YYYY HH:mm:ss Z');
    const end = moment(endDate.setHours(23, 59, 59, 0)).format('MM/DD/YYYY HH:mm:ss Z');
    if (tenantId) {
      try {
        const response = await fetchLoadReport(tenantId, start, end);
        setCreditLoads(response.data.result);
      } catch (err) { }
    }
  };

  const handleLoadSubmit = async (loadData) => {
    const body = { ...loadData, tenantId }
    try {
      const response = await loadCredit(tenantId, body);
      fetchLoadHistory();
      return response;
    } catch (err) {
      throw err;
    }
  };

  const ButtonDatePicker = ({ value, onClick, label }) => (
    <Button as='div' labelPosition='left' onClick={onClick}>
      <Label as='a' basic >
        {label}
      </Label>
      <Button icon color="teal" onClick={onClick}>
        {value}
      </Button>
    </Button>
  );

  const loadList = creditLoads.details || [];
  const { sum } = creditLoads;

  return (
    <>
      <Header block>
        {!loadFormVisible && <div className='space-center'>
          Credit Load History
          <Button primary onClick={() => setLoadFormVisible(true)} >
            Load Credit
          </Button>
        </div>}
        {loadFormVisible &&
          <LoadCreditForm handleCancel={() => setLoadFormVisible(false)} handleOk={handleLoadSubmit} />
        }
      </Header>
      <div>
        <strong>Total Loaded: </strong> {sum}
        <div style={{ float: 'right' }}>
          <Button.Group>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              customInput={<ButtonDatePicker label="Start" />}
            />
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              customInput={<ButtonDatePicker label="End" />}
            />
          </Button.Group>
        </div>
      </div>
      {
        !loadList.length ?
          <div style={{ textAlign: 'center' }}><strong>Thre is no load here!</strong></div>
          :
          <>
            <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Credit Load Type</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Load Time</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Amount</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Has Expired</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Description</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {loadList.map(load => (
                  <Table.Row>
                    <Table.Cell>{load.creditLoadTypeId ? creditLoadTypeList.find(item => item.key === load.creditLoadTypeId).text : 'Other'}</Table.Cell>
                    <Table.Cell textAlign="center">{moment(load.creationTime).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                    <Table.Cell textAlign="center">{load.amount}</Table.Cell>
                    <Table.Cell textAlign="center">{load.hasExpired ? 'Expired' : 'Not Expired'}</Table.Cell>
                    <Table.Cell textAlign="right">{load.description}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
      }
    </>
  );
};

export default LoadDetails;