import React, { useEffect, useState } from 'react';
import { Grid, Header, Dropdown, Label, Menu, Icon, Button, Checkbox, Loader, Form } from 'semantic-ui-react';
import { getTestRun, stopTest } from '../../api/apiCalls';
import { useApiProgress } from '../../api/ApiProgressHook';
import NoDataFound from '../../components/NoDataFound';
import LoadingComponent from '../../components/LoadingComponent';
import { toast } from 'react-toastify';
import { refreshOptions, aggregationOptions, quickRangeOptions } from './consts';
import { Link } from 'react-router-dom';
import PlotlyGraph from './components/PlotlyGraph';
import TestLiveStats from './components/TestLiveStats';
import TopNResponses from '../test-report/components/TopNResponses';
import ErroredRequests from './components/ErroredRequests';
import ErroredRequestSummary from './components/ErroredRequestSummary';

const signalR = require("@aspnet/signalr");

const defaultTestResult = {
  overallTestResults: null,
  testDetails: null,
  testEnded: null,
  testError: null,
  totalTime: null,
  testStartActualEpochMs: null,
  testStartAttemptEpochMs: null,
  testEndEpochMs: null,
  perRequestTestResults: false,
  timeSpanStart: -1,
  timeSpanEnd: -1
};

const defaultWsConfig = {
  aggregationInterval: 2,
  refreshInterval: 2,
  showAllStats: true,
  quickRange: 5
};

const MonitoringPage = (props) => {

  const testRunId = props.match.params.id;

  const [testRun, setTestRun] = useState({});
  const [error, setError] = useState();
  const [activeItem, setActiveItem] = useState('overall');

  const [hubCon, setHubCon] = useState();

  const [testResult, setTestResult] = useState({ ...defaultTestResult });
  const [wsConfig, setWsConfig] = useState({ ...defaultWsConfig });

  const [overallTestResultProgress, setOverallTestResultProgress] = useState(false);
  const [perRequestProgress, setPerRequestProgress] = useState(false);
  const testStopProgress = useApiProgress('post', `/api/TestRuns/${testRunId}/stop`);

  const [scenario, setScenario] = useState();
  const [request, setRequest] = useState();
  const [requestOptions, setRequestOptions] = useState([]);

  const [filterParams, setFilterParams] = useState({ scenarioName: null, requestName: null, responseCode: null });

  useEffect(() => {
    fetchTestRun();
    openSignalRConnForTestRun();
  }, [testRunId]);

  useEffect(() => {
    return function unmount() {
      if (hubCon) {
        hubCon.stop();
      }
    };
  }, [hubCon]);

  useEffect(() => {
    if (scenario) {
      hubCon.invoke('updateScenario', scenario)
        .then((response) => {
          console.log("WS Scenario Updated");
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      setTestResult(prev => ({ ...prev, perRequestTestResults: null }))
    }
  }, [scenario]);

  useEffect(() => {
    if (request) {
      setPerRequestProgress(true);
      hubCon.invoke('updateRequest', request)
        .then((response) => {
          setPerRequestProgress(false);
        })
        .catch(error => {
          setPerRequestProgress(false);
        });
    } else {
      setTestResult(prev => ({ ...prev, perRequestTestResults: null }))
    }
  }, [request]);

  const fetchTestRun = async () => {
    try {
      const response = await getTestRun(testRunId);
      setTestRun(response.data.result);
    } catch (error) {

    }
  };

  const openSignalRConnForTestRun = () => {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/signalr/test-run")
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    hubConnection.start()
      .then(
        () => {
          setHubCon(hubConnection);
          setOverallTestResultProgress(true);
          setupSignalRForTestRun(hubConnection);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const updateWsConfig = (e, { name, value, invokeName }) => {
    if (hubCon) {
      hubCon.invoke(invokeName, value)
        .then((response) => {
          setWsConfig(prev => ({ ...prev, [name]: value }));
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const { aggregationInterval, refreshInterval, quickRange, showAllStats } = wsConfig;
  const { testError, testStartActualEpochMs, testStartAttemptEpochMs, testEndEpochMs,
    testEnded, overallTestResults, perRequestTestResults, totalTime, timeSpanEnd, timeSpanStart, testDetails } = testResult || {};
  const scenarioList = testDetails ?
    testDetails.scenarios.map((item, index) => ({ key: index, text: item.name, value: item.name, requestList: item.requestNames })) : [];

  const setupSignalRForTestRun = (hubConnection) => {
    const stream = hubConnection.stream('SetupTestResults2', testRunId, aggregationInterval, refreshInterval, -1, -1, quickRange, showAllStats);

    stream.subscribe({
      next(testResults) {
        setTestResult(testResults);
        setOverallTestResultProgress(false);
      }
    });
  };

  const testStatus = () => {
    if (overallTestResultProgress || perRequestProgress) {
      return <Label color="olive"><Icon name='circle notched' loading />Loading ...</Label>
    } else if (!testStartActualEpochMs && !testEnded) {
      return <Label color="olive"><Icon name='circle notched' loading />Deploying Test to Machines</Label>
    } else if (testStopProgress && !testEnded) {
      return <Label color="olive"><Icon name='circle notched' loading />Stopping Tests ...</Label>
    } else if (testEnded) {
      return <Label color="green"><Icon name='info circle' />Test Stopped</Label>
    } else {
      return <Label color="green"><Icon name='circle notched' loading />Test Running</Label>
    }
  }

  const handleStopTesButton = async () => {
    try {
      await stopTest(testRunId);
    } catch (error) {
      return toast.error('Test stop failed');
    }
  };

  const handleScenarioChange = (e, { value }) => {
    const selectedScenario = scenarioList.find(item => item.value === value);
    let requestList = [];
    if (selectedScenario) {
      requestList = selectedScenario.requestList;
    }
    setScenario(value);
    setRequestOptions(requestList.map((item, index) => ({ key: index, text: item, value: item })));
  };

  const updateTimeSpanStartAndEnd = (start, end) => {
    if (hubCon) {
      hubCon.invoke('updateTimeSpanStartAndEnd', start, end)
        .then((response) => {
          console.log("Time Span Updated");
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (hubCon) {
      hubCon.invoke('UpdateShowAllStats', showAllStats)
        .then((response) => {
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [showAllStats]);

  const handleErrorSummaryDetail = (params) => {
    setFilterParams(params);
    setActiveItem('errors');
  };

  const handleChangeFilterDropdown = (e, { name, value }) => {
    const newValue = value === '' ? null : value;
    setFilterParams(prev => ({ ...prev, [name]: newValue }));
  };

  const handleItemClick = (e, { name }) => setActiveItem(name);

  const { name, description, testSuiteVariation } = testRun || {};
  const { noOfTotalUsers, testSuiteId } = testSuiteVariation || {};

  let labelsDate = [];

  let testStartMs = parseInt(timeSpanStart || -1, 10);
  let testEndMs = parseInt(timeSpanEnd || -1, 10);

  if (testStartMs !== -1) {
    testStartMs -= 2000;
    testEndMs += 2000;

    const timeDiff = testEndMs - testStartMs;
    const aggregationIntervalInMs = parseInt(aggregationInterval, 10) * 1000;
    const noOfIntervals = Math.floor(timeDiff / aggregationIntervalInMs) + 1;

    var current = testStartMs;
    for (var i = 0; i <= noOfIntervals; ++i) {
      labelsDate.push(current);
      current += aggregationIntervalInMs;
    }
  }

  return (
    <Grid >
      <Grid.Row columns={1} className='page-header'>
        <Grid.Column>
          <div >
            <Header as='h3' className="page-title">
              <Header.Content>
                {name}
                <Dropdown>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to={`/test-suite/${testSuiteId}`} text='Go Suite' />
                    <Dropdown.Item as={Link} to={`/test-run/${testRunId}/summary`} text='Go Summary' />
                  </Dropdown.Menu>
                </Dropdown>
                <Header.Subheader>Test note: {description}</Header.Subheader>
              </Header.Content>
            </Header>
          </div>

          <div className="page-actions">
            {testStatus()}
            <Button size="mini" color="red" disabled={testEnded || testStopProgress || overallTestResultProgress || perRequestProgress} onClick={handleStopTesButton}>
              Stop Test
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <div className="shadow" style={{ minHeight: "90vh", padding: 18, backgroundColor: 'white' }}>
            {(overallTestResultProgress) ?
              <LoadingComponent msg="Test Result Loading" />
              :
              <>
                {testError || !overallTestResults ?
                  <NoDataFound msg={testError} />
                  :
                  <>
                    <Menu fluid pointing secondary size="large" >
                      <Menu.Item
                        name='overall'
                        active={activeItem === 'overall'}
                        onClick={handleItemClick}
                      >
                        Overall Result
                  </Menu.Item>

                      <Menu.Item
                        name='byRequest'
                        active={activeItem === 'byRequest'}
                        onClick={handleItemClick}
                      >
                        Result For a Request
                  </Menu.Item>

                      <Menu.Item
                        name='topNResponses'
                        active={activeItem === 'topNResponses'}
                        onClick={handleItemClick}
                      >
                        Top N Responses
                  </Menu.Item>

                      <Menu.Item
                        name='errorsSummary'
                        active={activeItem === 'errorsSummary'}
                        onClick={handleItemClick}
                      >
                        Errors Summary
                      </Menu.Item>

                      <Menu.Item
                        name='errors'
                        active={activeItem === 'errors'}
                        onClick={handleItemClick}
                      >
                        Error Details
                      </Menu.Item>

                      <Menu.Menu position='right'>
                        <Dropdown item
                          text={'Aggregation: ' + aggregationOptions.find(item => item.value === aggregationInterval).text}
                          options={aggregationOptions} value={aggregationInterval} onChange={updateWsConfig} name="aggregationInterval" invokeName="updateAggregationInterval" />
                        <Dropdown item
                          text={'Refresh: ' + refreshOptions.find(item => item.value === refreshInterval).text}
                          options={refreshOptions} value={refreshInterval} onChange={updateWsConfig} name="refreshInterval" invokeName="updateRefreshInterval" />
                        <Dropdown item
                          text={'Quick Range: ' + quickRangeOptions.find(item => item.value === quickRange).key}
                          options={quickRangeOptions} value={quickRange} onChange={updateWsConfig} name="quickRange" invokeName="updateTimeSpan" />
                      </Menu.Menu>
                    </Menu>
                    <div style={{ marginTop: '20px' }}>
                      {activeItem === 'overall' &&
                        <>
                          {overallTestResultProgress && <Loader active />}
                          <TestLiveStats testResults={overallTestResults} totalTime={totalTime} totalUser={noOfTotalUsers} loading={overallTestResultProgress} />
                          <div style={{ float: 'right', marginTop: '1em' }}>
                            <Checkbox label="Show stats for all test"
                              onClick={() => setWsConfig(prev => ({ ...prev, showAllStats: !prev.showAllStats }))}
                              checked={showAllStats}
                            />
                          </div>
                          <PlotlyGraph data={overallTestResults} time={labelsDate} isResponseChart={false}
                            start={testStartAttemptEpochMs} end={testEndEpochMs}
                            timeSpanStart={testStartMs} timeSpanEnd={testEndMs}
                            updateTimeSpanStartAndEnd={updateTimeSpanStartAndEnd}
                          />
                        </>
                      }
                      {activeItem === 'byRequest' &&
                        <>
                          <Form>
                            <Form.Group widths='equal'>
                              <Form.Field>
                                <label>Scenario</label>
                                <Form.Dropdown
                                  placeholder='Select Scenario'
                                  fluid
                                  selection
                                  options={scenarioList}
                                  clearable
                                  onChange={handleScenarioChange}
                                  value={scenario}
                                />
                              </Form.Field>
                              <Form.Field >
                                <label>Request</label>
                                <Form.Dropdown
                                  placeholder='Select Request'
                                  fluid
                                  selection
                                  options={requestOptions}
                                  clearable
                                  onChange={(e, { value }) => setRequest(value)}
                                  value={request}
                                />
                              </Form.Field>
                            </Form.Group>
                          </Form>
                          {perRequestProgress && <Loader active />}
                          {(scenario && request) &&
                            <>
                              <TestLiveStats testResults={perRequestTestResults} totalTime={totalTime} totalUser={noOfTotalUsers} loading={perRequestProgress} />
                              <div style={{ float: 'right', marginTop: '1em' }}>
                                <Checkbox label="Show stats for all test"
                                  onClick={() => setWsConfig(prev => ({ ...prev, showAllStats: !prev.showAllStats }))}
                                  checked={showAllStats}
                                />
                              </div>
                              <PlotlyGraph data={perRequestTestResults} time={labelsDate} isResponseChart={false}
                                start={testStartAttemptEpochMs} end={testEndEpochMs}
                                timeSpanStart={testStartMs} timeSpanEnd={testEndMs}
                                updateTimeSpanStartAndEnd={updateTimeSpanStartAndEnd}
                              />
                              <PlotlyGraph data={perRequestTestResults} time={labelsDate} isResponseChart={true}
                                start={testStartAttemptEpochMs} end={testEndEpochMs}
                                timeSpanStart={testStartMs} timeSpanEnd={testEndMs}
                                updateTimeSpanStartAndEnd={updateTimeSpanStartAndEnd}
                              />
                            </>
                          }
                        </>
                      }
                      {activeItem === 'topNResponses' &&
                        <TopNResponses data={overallTestResults ? overallTestResults.topNResponses : []} />
                      }
                      {activeItem === 'errors' &&
                        <ErroredRequests handleChangeFilterDropdown={handleChangeFilterDropdown} filterParams={filterParams} testRunId={testRunId} />
                      }
                      {activeItem === 'errorsSummary' &&
                        <ErroredRequestSummary handleErrorSummaryDetail={handleErrorSummaryDetail} testRunId={testRunId} />
                      }
                    </div>
                  </>
                }
              </>
            }
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default MonitoringPage;