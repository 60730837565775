import * as actions from '../consts';

const defaultState = {
  resourceList: [],
  pluginList: [],
  error: null,
  inProgress: false,
  pluginInProgress: false
};

const CloudReducer = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case actions.FETCH_TEST_RESOURCE_LIST_BEGIN:
      return {
        ...state,
        error: null,
        inProgress: true
      };

    case actions.FETCH_TEST_RESOURCE_LIST_SUCCESS:
      return {
        ...state,
        resourceList: action.resources,
        inProgress: false
      };

    case actions.FETCH_TEST_RESOURCE_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false
      };

    case actions.FETCH_PLUGIN_LIST_BEGIN:
      return {
        ...state,
        pluginInProgress: true,
      };

    case actions.FETCH_PLUGIN_LIST_SUCCESS:
      return {
        ...state,
        pluginInProgress: true,
        pluginList: action.plugins
      };

    case actions.FETCH_PLUGIN_LIST_FAILURE:
      return {
        ...state,
        pluginInProgress: false,
      };

    case actions.UPDATE_TEST_RESOURCE_SUCCESS: {
      const newResourceList = state.resourceList.map((resource) => {
        if (resource.id !== action.resource.id) {
          return resource;
        }
        return {
          ...resource,
          ...action.resource
        };
      });

      return {
        ...state,
        resourceList: newResourceList,
      };
    }

    case actions.ADD_TEST_RESOURCE_SUCCESS:
      return {
        ...state,
        resourceList: [action.resource, ...state.resourceList]
      };

    case actions.DELETE_TEST_RESOURCE_SUCCESS:
      return {
        ...state,
        resourceList: state.resourceList.filter(resource => resource.id !== action.resourceId)
      };

    case actions.ADD_PLUGIN_SUCCESS: {
      let newPluginList = state.pluginList.filter(item => item.id !== action.plugin.id);
      newPluginList = [...newPluginList, action.plugin]
      return {
        ...state,
        pluginList: newPluginList
      };
    }

    case actions.DELETE_PLUGIN_SUCCESS:
      return {
        ...state,
        pluginList: state.pluginList.filter(plugin => plugin.id !== action.pluginId)
      };

    default:
      return state;
  }
};

export default CloudReducer;